import { useState, useEffect } from 'react';
import { useParams, useNavigate  } from 'react-router-dom';
import { useQuery, gql, useMutation } from '@apollo/client';


import {toast} from 'react-toastify';

const GET_PARTICIPANT_BY_ODR_NUMBER = gql`
  query GetParticipantByODRNumber($odrnumber: Integer!) {
    getParticipantByODRNumber(odrnumber: $odrnumber) {
      id
      ssn
      fin
      cid
      cii
      cin
      mrun
      wpc
      mainNumber
      ecm{
        court_consent
        court_consent_date
        dhs_empaneled
        dhs_empaneled_date
        dhs_empaneled_requested_date
        dhs_empaneled_effective_date
        ecm_optin
        ecm_optin_date
        ecm_optin_referral_date
        ecm_optin_effective_date
        ecm_optin_exit_date
        ecm_deactivation_reason
        ecm_deactivation_date
        ecm_deactivation_status      
      }
      healthplan{        
        healthplanprovider
        lobname
        lobid
        clinicempaneled
        pcpname
        ecmmanager
        ecmprovider
      }
    }
  }
`;

const UPDATE_PARTICIPANT_BY_ODR_NUMBER = gql`
  mutation UpdateParticipantByODRNumber($ODRNumber: Integer!, $updates: updateParticipantInput!) {
    updateParticipantByODRNumber(ODRNumber: $ODRNumber, updates: $updates) {     
      message     
  }}
`;



export const CalAIM = (params: any)=>{
    const { participant: participantReactParams, submit } = params;
    
    const { participant: participantURLParams } :any = useParams();
    const participant = participantReactParams || participantURLParams;


    const [state, setState] = useState<any>({});
    const [ecmState, setEcmState] = useState<any>({});
    const [healthPlanState, setHealthPlanState] = useState<any>({});
    

    const { loading, error, data, refetch } = useQuery(GET_PARTICIPANT_BY_ODR_NUMBER, {
        variables: { odrnumber: participant },
        onCompleted: (result) => {        
            const { ecm, healthplan, ...p } = result.getParticipantByODRNumber;
            
            
            setState( p)
            setEcmState (ecm || {})
            setHealthPlanState (healthplan||{} );
        },
    });

    

    const [updateParticipant] = useMutation(UPDATE_PARTICIPANT_BY_ODR_NUMBER);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    const participantData = data.getParticipantByODRNumber;

    //map over names and programs
    const names = participantData.names;
    const programs = participantData.programs;


    const onSubmit = async (e: any) => {
        const data = {
            ssn : state.ssn,
            cin : state.cin,
            mrun : state.mrun,
            fin : state.fin,
            cii : state.cii,
            wpc : state.wpc,
            court_consent : ecmState.court_consent,
            court_consent_date : ecmState.court_consent_date,
            dhs_empaneled : ecmState.dhs_empaneled,
            dhs_empaneled_date : ecmState.dhs_empaneled_date,
            dhs_empaneled_requested_date : ecmState.dhs_empaneled_requested_date,
            dhs_empaneled_effective_date : ecmState.dhs_empaneled_effective_date,
            ecm_optin : ecmState.ecm_optin,
            ecm_optin_date : ecmState.ecm_optin_date,
            ecm_optin_referral_date : ecmState.ecm_optin_referral_date,
            ecm_optin_effective_date : ecmState.ecm_optin_effective_date,
            ecm_optin_exit_date : ecmState.ecm_optin_exit_date,
            ecm_deactivation_reason : ecmState.ecm_deactivation_reason,
            ecm_deactivation_date : ecmState.ecm_deactivation_date,
            ecm_deactivation_status : ecmState.ecm_deactivation_status,  
            healthplanprovider : healthPlanState.healthplanprovider,
            lobname : healthPlanState.lobname,
            lobid : healthPlanState.lobid,
            clinicempaneled : healthPlanState.clinicempaneled,      
            pcpname : healthPlanState.pcpname,
            ecmmanager : healthPlanState.ecmmanager,
            ecmprovider : healthPlanState.ecmprovider,                          
            
        }as any;

        const dataWithNullsRemoved = Object.keys(data).reduce((acc, key) => { if (data[key] !== null) { acc[key] = data[key];  }  return acc;  }, {} as any);
        
        const { data: updateData } = await updateParticipant({
            variables: {
              ODRNumber: participant,
              updates: dataWithNullsRemoved,
            },
          });
        refetch();
        toast.success("Participant Updated");

        
    }
    /*{
      "type": "textInput",
      "required": false,
      "name": "ssn",
       "label": "SSN",
        "key": "ssn"
    },
    {
      "type": "textInput",
      "required": false,
        "name": "cin",
            "label": "MediCal-CIN",
            "key": "cin"
    },
     {
      "type": "textInput",
      "required": false,
        "name": "mrun",
            "label": "MRUN",
            "key": "mrun"
    },
    {
        "type": "textInput",
        "required": false,
          "name": "fin",
              "label": "FIN",
              "key": "fin"
      },
      {
        "type": "textInput",
        "required": false,
          "name": "cii",
              "label": "CII",
              "key": "cii"
      }

             
         {
          "type": "dateInput",
          "required": false,
            "name": "ecm_optin_effective_date",
                "label": "ECM Effective Date",
                "key": "ecm_optin_effective_date"
        },
         {
          "type": "dateInput",
          "required": false,
            "name": "ecm_optin_exit_date",
                "label": "ECM Exit Date",
                "key": "ecm_optin_exit_date"
        }  

         {
          "type": "listInputDowndown",
          "required": false,
            "name": "healthlplanprovider",
                "label": "Health Plan Provider",
                "key": "healthplanprovider",
                "options":[
                  {"label": "AARP", "value": "AARP"},
                  {"label": "AIDS Healthcare Foundation", "value": "AIDS Healthcare Foundation"},
                  {"label": "Emergency Medi-Cal", "value": "Emergency Medi-Cal"},
                  {"label": "Fee Service Medi-Cal", "value": "Fee Service Medi-Cal"},
                  {"label": "Health Net/CCI", "value": "Health Net/CCI"},
                  {"label": "Health Net/Health Plan", "value": "Health Net/Health Plan"},
                  {"label": "Health Net/Molina", "value": "Health Net/Molina"},
                  {"label": "LA Care/Anthem", "value": "LA Care/Anthem"},
                  {"label": "LA Care/Blue Shield", "value": "LA Care/Blue Shield"},
                  {"label": "LA Care/CCI", "value": "LA Care/CCI"},
                  {"label": "LA Care/Health Plan", "value": "LA Care/Health Plan"},
                  {"label": "LA Care/Kaiser Permanente", "value": "LA Care/Kaiser Permanente"},
                  {"label": "My Health LA Program", "value": "My Health LA Program"}
                ]
        },
          {            
            "type":  "listInputDowndown",
            "options": [
              
                {"label": "Los Angeles County Department of Health Services", "value": "Los Angeles County Department of Health Services"},
                {"label": "Adventist", "value": "Adventist"},
                {"label": "Alcoholism Center For Women, Inc.", "value": "Alcoholism Center For Women, Inc. "},
                {"label": "Alcott Center for Mental Health Services", "value": "Alcott Center for Mental Health Services"},
                {"label": "All for Health, Health for all", "value": "All for Health, Health for all"},
                {"label": "AltaMed Health Services", "value": "AltaMed Health Services"},
                {"label": "Arroyo Vista Family Health Center", "value": "Arroyo Vista Family Health Center"},
                {"label": "Ascencia", "value": "Ascencia"},
                {"label": "Asian Pacific Health Care Venture, Inc.", "value": "Asian Pacific Health Care Venture, Inc."},
                {"label": "Bartz-Altadonna Community Health Center", "value": "Bartz-Altadonna Community Health Center"},
                {"label": "Central Neighborhood Health Foundation", "value": "Central Neighborhood Health Foundation"},
                {"label": "ChapCare", "value": "ChapCare"},
                {"label": "Charter Healthcare Group", "value": "Charter Healthcare Group"},
                {"label": "Children’s Institute, Inc. (CII)", "value": "Children’s Institute, Inc. (CII)"},
                {"label": "Chinatown Service Center", "value": "Chinatown Service Center"},
                {"label": "Comprehensive Community Health Centers", "value": "Comprehensive Community Health Centers"},
                {"label": "Concise Care Group", "value": "Concise Care Group"},
                {"label": "Department of Mental Health - LA - DMH", "value": "Department of Mental Health - LA - DMH"},
                {"label": "Didi Hirsch Mental Health Services", "value": "Didi Hirsch Mental Health Services"},
                {"label": "Dignity Adult Day Health Care Center", "value": "Dignity Adult Day Health Care Center"},
                {"label": "East Valley Community Health Center", "value": "East Valley Community Health Center"},
                {"label": "EISNER Health", "value": "EISNER Health"},
                {"label": "Exodus Recovery", "value": "Exodus Recovery"},
                {"label": "Fred Brown Recovery Services", "value": "Fred Brown Recovery Services"},
                {"label": "Garfield Health Center", "value": "Garfield Health Center"},
                {"label": "Harbor Community Clinic", "value": "Harbor Community Clinic"},
                {"label": "Healthcare in Action", "value": "Healthcare in Action"},
                {"label": "Helpline Youth Counseling, Inc.", "value": "Helpline Youth Counseling, Inc."},
                {"label": "Heritage Clinic and the Community Assistance Program for Seniors", "value": "Heritage Clinic and the Community Assistance Program for Seniors"},
                {"label": "Home Avenue ADHC", "value": "Home Avenue ADHC"},
                {"label": "Illumination Foundation", "value": "Illumination Foundation"},
                {"label": "Independent Living Systems LLC", "value": "Independent Living Systems LLC"},
                {"label": "JWCH Institute", "value": "JWCH Institute"},
                {"label": "Kedren Care Community Center", "value": "Kedren Care Community Center"},
                {"label": "Libertana Home Health", "value": "Libertana Home Health"},
                {"label": "LifeSTEPS", "value": "LifeSTEPS"},
                {"label": "Los Angeles Christian Health Centers", "value": "Los Angeles Christian Health Centers"},
                {"label": "Martin Luther King Jr. Community Healthcare", "value": "Martin Luther King Jr. Community Healthcare"},
                {"label": "MedZed", "value": "MedZed"},
                {"label": "Northeast Valley Health Corporation (NEVHC)", "value": "Northeast Valley Health Corporation (NEVHC)"},
                {"label": "Partners in Care Foundation", "value": "Partners in Care Foundation"},
                {"label": "PATH", "value": "PATH"},
                {"label": "Prospect Medical Group", "value": "Prospect Medical Group"},
                {"label": "QueensCare Health Centers", "value": "QueensCare Health Centers"},
                {"label": "San Fernando Community Health Center", "value": "San Fernando Community Health Center"},
                {"label": "San Fernando Valley Community Mental Health Center", "value": "San Fernando Valley Community Mental Health Center"},
                {"label": "Serene Health IPA", "value": "Serene Health IPA"},
                {"label": "Serra Community Medical Clinic DBA Serra Medical Group", "value": "Serra Community Medical Clinic DBA Serra Medical Group"},
                {"label": "St. John's Well Child & Family Center", "value": "St. John's Well Child & Family Center"},
                {"label": "Tarzana Treatment Centers, Inc", "value": "Tarzana Treatment Centers, Inc"},
                {"label": "TCC Family Health", "value": "TCC Family Health"},
                {"label": "The Catalyst Foundation", "value": "The Catalyst Foundation"},
                {"label": "Titanium", "value": "Titanium"},
                {"label": "Uplift", "value": "Uplift"},
                {"label": "Valley Community Healthcare", "value": "Valley Community Healthcare"},
                {"label": "Venice Family Clinic", "value": "Venice Family Clinic"},
                {"label": "Via Care Community Health Center", "value": "Via Care Community Health Center"},
                {"label": "Volunteers of America Los Angeles", "value": "Volunteers of America Los Angeles"},
                {"label": "Watts Healthcare Corporation", "value": "Watts Healthcare Corporation"}
                
              ],
            "required": false,
              "name": "ecmprovider",
                  "label": "ECM Provider",
                  "key": "ecmprovider"            
          },
          
        {
            "type": "textInput",
            "required": false,
              "name": "ecmmanager",
                  "label": "ECM Care Manager",
                  "key": "ecmmanager"
          },          
         {
          "type": "textInput",
          "required": false,
            "name": "pcpname",
                "label": "PCP Name",
                "key": "pcpname"
        }
   
      
      */
      

    return (
        <div>
            <h3>CalAIM</h3>
            <div className='columns3 rowsSpaced'>

             <div >
                <div>SSN</div>
                <input value={state.ssn} onChange={(e) => setState({ ...state, ssn: e.target.value })} />
              </div>

              <div>
                <div>ECM Provider</div>
                <select value={healthPlanState.ecmprovider} onChange={(e) => setHealthPlanState({ ...healthPlanState, ecmprovider: e.target.value })}>
                  <option value="">Select...</option>
                  <option value="Los Angeles County Department of Health Services">Los Angeles County Department of Health Services</option>
                  <option value="Adventist">Adventist</option>
                  <option value="Alcoholism Center For Women, Inc.">Alcoholism Center For Women, Inc</option>
                  <option value="Alcott Center for Mental Health Services">Alcott Center for Mental Health Services</option>
                  <option value="All for Health, Health for all">All for Health, Health for all</option>
                  <option value="AltaMed Health Services">AltaMed Health Services</option>
                  <option value="Arroyo Vista Family Health Center">Arroyo Vista Family Health Center</option>
                  <option value="Ascencia">Ascencia</option>
                  <option value="Asian Pacific Health Care Venture, Inc.">Asian Pacific Health Care Venture, Inc.</option>
                  <option value="Bartz-Altadonna Community Health Center">Bartz-Altadonna Community Health Center</option>
                  <option value="Central Neighborhood Health Foundation">Central Neighborhood Health Foundation</option>
                  <option value="ChapCare">ChapCare</option>
                  <option value="Charter Healthcare Group">Charter Healthcare Group</option>
                  <option value="Children’s Institute, Inc. (CII)">Children’s Institute, Inc. (CII)</option>
                  <option value="Chinatown Service Center">Chinatown Service Center</option>
                  <option value="Comprehensive Community Health Centers">Comprehensive Community Health Centers</option>
                  <option value="Concise Care Group">Concise Care Group</option>
                  <option value="Department of Mental Health - LA - DMH">Department of Mental Health - LA - DMH</option>
                  <option value="Didi Hirsch Mental Health Services">Didi Hirsch Mental Health Services</option>
                  <option value="Dignity Adult Day Health Care Center">Dignity Adult Day Health Care Center</option>
                  <option value="East Valley Community Health Center">East Valley Community Health Center</option>
                  <option value="EISNER Health">EISNER Health</option>
                  <option value="Exodus Recovery">Exodus Recovery</option>
                  <option value="Fred Brown Recovery Services">Fred Brown Recovery Services</option>
                  <option value="Garfield Health Center">Garfield Health Center</option>
                  <option value="Harbor Community Clinic">Harbor Community Clinic</option>
                  <option value="Healthcare in Action">Healthcare in Action</option>
                  <option value="Helpline Youth Counseling, Inc.">Helpline Youth Counseling, Inc.</option>
                  <option value="Heritage Clinic and the Community Assistance Program for Seniors">Heritage Clinic and the Community Assistance Program for Seniors</option>
                  <option value="Home Avenue ADHC">Home Avenue ADHC</option>
                  <option value="Illumination Foundation">Illumination Foundation</option>
                  <option value="Independent Living Systems LLC">Independent Living Systems LLC</option>
                  <option value="JWCH Institute">JWCH Institute</option>
                  <option value="Kedren Care Community Center">Kedren Care Community Center</option>
                  <option value="Libertana Home Health">Libertana Home Health</option>
                  <option value="LifeSTEPS">LifeSTEPS</option>
                  <option value="Los Angeles Christian Health Centers">Los Angeles Christian Health Centers</option>
                  <option value="Martin Luther King Jr. Community Healthcare">Martin Luther King Jr. Community Healthcare</option>
                  <option value="MedZed">MedZed</option>
                  <option value="Northeast Valley Health Corporation (NEVHC)">Northeast Valley Health Corporation (NEVHC)</option>
                  <option value="Partners in Care Foundation">Partners in Care Foundation</option>
                  <option value="PATH">PATH</option>
                  <option value="Prospect Medical Group">Prospect Medical Group</option>
                  <option value="QueensCare Health Centers">QueensCare Health Centers</option>
                  <option value="San Fernando Community Health Center">San Fernando Community Health Center</option>
                  <option value="San Fernando Valley Community Mental Health Center">San Fernando Valley Community Mental Health Center</option>
                  <option value="Serene Health IPA">Serene Health IPA</option>
                  <option value="Serra Community Medical Clinic DBA Serra Medical Group">Serra Community Medical Clinic DBA Serra Medical Group</option>
                  <option value="St. John's Well Child & Family Center">St. John's Well Child & Family Center</option>
                  <option value="Tarzana Treatment Centers, Inc">Tarzana Treatment Centers, Inc</option>
                  <option value="TCC Family Health">TCC Family Health</option>
                  <option value="The Catalyst Foundation">The Catalyst Foundation</option>
                  <option value="Titanium">Titanium</option>
                  <option value="Uplift">Uplift</option>
                  <option value="Valley Community Healthcare">Valley Community Healthcare</option>
                  <option value="Venice Family Clinic">Venice Family Clinic</option>
                  <option value="Via Care Community Health Center">Via Care Community Health Center</option>
                  <option value="Volunteers of America Los Angeles">Volunteers of America Los Angeles</option>
                  <option value="Watts Healthcare Corporation">Watts Healthcare Corporation</option>
                </select>
              </div>

              <div>
                <div>Health Plan Provider</div>
                <select value={healthPlanState.healthplanprovider} onChange={(e) => setHealthPlanState({ ...healthPlanState, healthplanprovider: e.target.value })}>
                  <option value="">Select...</option>
                  <option value="AARP">AARP</option>
                  <option value="AIDS Healthcare Foundation">AIDS Healthcare Foundation</option>
                  <option value="Emergency Medi-Cal">Emergency Medi-Cal</option>
                  <option value="Fee Service Medi-Cal">Fee Service Medi-Cal</option>
                  <option value="Health Net/CCI">Health Net/CCI</option>
                  <option value="Health Net/Health Plan">Health Net/Health Plan</option>
                  <option value="Health Net/Molina">Health Net/Molina</option>
                  <option value="LA Care/Anthem">LA Care/Anthem</option>
                  <option value="LA Care/Blue Shield">LA Care/Blue Shield</option>
                  <option value="LA Care/CCI">LA Care/CCI</option>
                  <option value="LA Care/Health Plan">LA Care/Health Plan</option>
                  <option value="LA Care/Kaiser Permanente">LA Care/Kaiser Permanente</option>
                  <option value="My Health LA Program">My Health LA Program</option>
                </select>
              </div>

              <div >
                <div>MediCal-CIN</div>
                <input value={state.cin} onChange={(e) => setState({ ...state, cin: e.target.value })} /> 
              </div>

              <div>
                <div>ECM Effective Date</div>
                <input value={ecmState.ecm_optin_effective_date} onChange={(e) => setEcmState({ ...ecmState, ecm_optin_effective_date: e.target.value })} />
              </div>


              <div>
                <div>PCP Name</div>
                <input value={healthPlanState.pcpname} onChange={(e) => setHealthPlanState({ ...healthPlanState, pcpname: e.target.value })} /> 
              </div>

              <div >
                <div>MRUN</div>
                <input value={state.mrun} onChange={(e) => setState({ ...state, mrun: e.target.value })} />
              </div>


              <div>
                <div>ECM Exit Date</div>
                <input value={ecmState.ecm_optin_exit_date} onChange={(e) => setEcmState({ ...ecmState, ecm_optin_exit_date: e.target.value })} />
              </div>

              <div >
                <div>FIN</div>
                <input value={state.fin} onChange={(e) => setState({ ...state, fin: e.target.value })} />

              </div>

              <div >
                <div>CII</div>
                <input value={state.cii} onChange={(e) => setState({ ...state, cii: e.target.value })} />
              </div>

        


              <div>
                <div>Health Plan Provider</div>
                <input value={healthPlanState.healthplanprovider} onChange={(e) => setHealthPlanState({ ...healthPlanState, healthplanprovider: e.target.value })} />
              </div>

   


            

              <div>
                <div>ECM Care Manager</div>
                <input value={healthPlanState.ecmmanager} onChange={(e) => setHealthPlanState({ ...healthPlanState, ecmmanager: e.target.value })} />
              </div>

              

            
        
            </div>

            <button onClick={onSubmit}>Submit</button>           
        
        </div>
    )
} 