import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import { useCurrentHelpTopic } from '../../store/useHelpTopics';
import { gql, useMutation } from '@apollo/client';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';


const CREATE_SUPPORT_TICKET = gql`
  mutation CreateSupportTicket($message: String!) {
    createSupportTicket(message: $message) {
      id
      message
    }
  }
`;

const HelpModal = ({onClose}: {onClose: () => void}) => {  
  const [message, setMessage] = useState('');
  const [createSupportTicket] = useMutation(CREATE_SUPPORT_TICKET);

  const handleSubmit = () => {
    createSupportTicket({variables: {message}});
    onClose();
    toast.success('Support ticket created. Someone will contact you shortly.');
  }

  return (    
      <div>
        <div style={{display: 'grid', gridTemplateColumns: '95% 1fr'}}>
          <div></div>
          <div>
            <button onClick={onClose}>Close</button>
          </div>
        </div>
        <h1>Contact Support</h1>        
        <div>
          <div><label>Message</label></div>
          <textarea value={message} onChange={(e) => setMessage(e.target.value)} />
          <div style={{paddingTop: '10px'}}><button onClick={handleSubmit}>Submit</button></div>
        </div>
      </div>
    
  );
};

export const GET_HELP_TOPIC = gql`
  query GetHelpTopic($name: String!) {
    getHelpTopic(name: $name) {
      id
      name
      content
    }
  }
`;

export const Help = () => {
  const currentHelpTopic = useCurrentHelpTopic();


  const [isModalOpen, setIsModalOpen] = useState(false);
  const { loading, error, data } = useQuery(GET_HELP_TOPIC, {
    variables: { name: currentHelpTopic() },
    skip: !currentHelpTopic(),
  });


  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!data || !data.getHelpTopic) return <div>No help topic found</div>;

  return (    
    <div style ={{borderLeft: '1px solid #ccc', paddingLeft: '10px'}}>
      <ReactModal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)}>
        <HelpModal onClose={() => setIsModalOpen(false)}/>
      </ReactModal>
      <div style ={{borderBottom: '1px solid #ccc', paddingBottom: '10px', marginBottom: '10px'}}>
      <h1>Contact Support</h1>
      <div>If you have encountered an issue or have a question, please contact support by using the button below.</div>
      <div style ={{paddingTop: '10px'}}>
        <button onClick={() => setIsModalOpen(true)}>Contact Support</button>
      </div>
    </div>
    <div  dangerouslySetInnerHTML={{ __html: data.getHelpTopic.content }} />
  </div>)
};