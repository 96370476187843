import React, { useState } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import { Demographics } from './components/minidemographics';
import { useUser } from '../../store/useUser';
import { toast } from 'react-toastify';
import { DiscountModal } from '../auth/AutoLogoutComponents/discountModal';

const boxed = {
  marginBottom: "1rem",
  border: "1px solid #ccc",
  borderRadius: "1px",
  padding: "0.5rem",
};

const MERGE_PARTICIPANTS = gql`
  mutation MergeParticipants($participantToKeep: String!, $participantsToMerge: [String]!) {
    mergeParticipantsbyODRNumber(participantToKeep: $participantToKeep, participantsToMerge: $participantsToMerge)
    {
      message
      extra
    }
  }
`;

const GET_DUPLICATE_CLIENTS_REPORT = gql`
  query DuplicateClientsReport {
    duplicateClientsReport {
      entries {                       
        participants     
        mrun   
        cin   
        ssn   
        cii   
        fin   
        cid   
      }
      totalCount
    }
  }
`;

const matchingFields = ['mrun', 'cin', 'ssn', 'cii', 'fin', 'cid'];

interface Entry {
  type: string;
  value: string;
  odrparticipants: string[];
  participants: string[];
  firstName: string;
  lastName: string;
}

interface DuplicateClientsReportData {
  entries: Entry[];
  totalCount: number;
}

export const DuplicateClientsReport: React.FC = () => {
  const [duplicateClientsReport, setDuplicateClientsReport] = useState<DuplicateClientsReportData>({ entries: [], totalCount: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage] = useState(20);
  const [working, setWorking] = useState(false);

  const { user, isUserLoading } = useUser();

  const { loading, error, refetch } = useQuery<{ duplicateClientsReport: DuplicateClientsReportData }>(GET_DUPLICATE_CLIENTS_REPORT, {
    onCompleted: (data) => {
      setDuplicateClientsReport(data.duplicateClientsReport);
    }
  });

  const [mergeParticipants] = useMutation(MERGE_PARTICIPANTS);

  if (loading || isUserLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = duplicateClientsReport.entries.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(duplicateClientsReport.entries.length / entriesPerPage);

  const changePage = (newPage: number) => {
    setCurrentPage(Math.max(1, Math.min(newPage, totalPages)));
  };

  const merge = async (entry: Entry, index: number) => {
    setWorking(true);
    if (!window.confirm('Are you sure you want to merge these participants?')) {
      setWorking(false);
      return;
    }
    const { data } = await mergeParticipants({ 
      variables: { 
        participantToKeep: entry.participants[0], 
        participantsToMerge: entry.participants.slice(1) 
      } 
    });

    if (!data) {
      console.error('Error merging participants:', error);
      setWorking(false);
    } else {
      toast.success(`Participants merged successfully ${data.mergeParticipantsbyODRNumber.extra}`);

      const updatedEntries = [...duplicateClientsReport.entries];    
      updatedEntries.splice(indexOfFirstEntry + index, 1);

      setDuplicateClientsReport({ ...duplicateClientsReport, entries: updatedEntries });
      setWorking(false);
    }
  };

  return (
    <div>
      <h2>Duplicate Clients Report</h2>
      <p>Total Count: {duplicateClientsReport.totalCount}</p>
      <div>
        {currentEntries.map((entry: any, index: number) => {
          const key = entry.participants.join('');

          return (
            <div key={index} className='boxed'>              
              <div style={{display: 'grid', gridTemplateColumns: '15rem 1fr 20rem', gap: '10px'}}>
                <div>
                  <div style={{display: 'grid', gridTemplateColumns: '10rem 1fr 1fr', gap: '10px'}}>
                    <div style={{fontWeight: 'bold', marginBottom: '5px'}}>ODR Numbers</div>                    
                  </div>              

                  <div>
                    {entry.participants.map((odrnumber: any) => (
                      <div key={odrnumber}>{odrnumber}</div>
                    ))}
                  </div>  
                </div>  

                <Demographics 
                  odrnumbers={entry.participants} 
                  refetch={refetch} 
                  onMerge={() => {}}                      
                />    

                <div style={boxed}>
                  <div style={{fontWeight: 'bold', marginBottom: '5px'}}>Matched Fields</div>
                  {matchingFields.map((field: any) => (
                    <>
                      {entry[field] && <div key={field+entry[field]} style={{display: 'grid', gridTemplateColumns: '5rem 1fr'}}>
                        <div>{field}</div>
                        <div>{entry[field]}</div>
                      </div>}
                    </>
                  ))}
                </div>                  
              </div>  
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <button onClick={() => {merge(entry, index)}}>Merge</button>
              </div>
            </div>
          );          
        })}
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={changePage}
      />
      {working && <DiscountModal isOpen={true}><div>Working...</div></DiscountModal>}
    </div>
  );
};

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = [];
  const maxPageButtons = 5;

  let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
  let endPage = Math.min(totalPages, startPage + maxPageButtons - 1);

  if (endPage - startPage + 1 < maxPageButtons) {
    startPage = Math.max(1, endPage - maxPageButtons + 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  const buttonStyle = {
    padding: '0.5rem 1rem',
    margin: '0 0.25rem',
    border: '1px solid #ccc',
    borderRadius: '4px',
    background: 'white',
    cursor: 'pointer',
  };

  const activeButtonStyle = {
    ...buttonStyle,
    background: '#007bff',
    color: 'white',
    borderColor: '#007bff',
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1rem' }}>
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        style={buttonStyle}
      >
        Previous
      </button>
      {pageNumbers.map(number => (
        <button
          key={number}
          onClick={() => onPageChange(number)}
          style={currentPage === number ? activeButtonStyle : buttonStyle}
        >
          {number}
        </button>
      ))}
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        style={buttonStyle}
      >
        Next
      </button>
    </div>
  );
};