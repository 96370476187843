import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import * as XLSX from 'xlsx';


const isOdd = (i:number) => i % 2 === 1;

const CustomDate = (props:any) => {
    const { value } = props;
    const date = new window.Date(value);
    return <div>{date.toLocaleDateString()}</div>;
}


export const ExportAllClientsReport = () => {
  const [selectedPrograms, setSelectedPrograms] = useState([]) as any;
  const [reportData, setReportData] = useState([]);
  const [skip, setSkip] = useState(0);
    const [loading, setLoading] = useState(false);

  const EXPORT_ALL_CLIENTS_REPORT = gql`
    query exportAllClientsReport($selectedPrograms: [String]!) {
      exportAllClientsReport(programs: $selectedPrograms) {entries{
        ODRNumber
        ParticipantID
        CID        
        FirstName
        LastName
        CIN
        Disposition
        MedcallStatus
        Program
        ProfileCreationDate
        ProfileLastUpdatedDate
      }}
    }
  `;

  const { data } = useQuery(EXPORT_ALL_CLIENTS_REPORT, {
    variables: { selectedPrograms },
    skip: skip === 0, // Skip the query if no programs are selected
  });

  useEffect(() => {
    if (data) {
      setReportData(data.exportAllClientsReport.entries);
      setSkip(0);
      setLoading(false);
    }
  }, [data]);

  const handleProgramCheckboxChange = (program: string) => {
    const isSelected = selectedPrograms.includes(program);

    if (isSelected) {
      setSelectedPrograms((prevSelected :any) => prevSelected.filter((item:any) => item !== program));
    } else {
      setSelectedPrograms((prevSelected :any) => [...prevSelected, program]);
    }
  };

  const handleGenerateReport = () => {
    setLoading(true);
    setReportData([]);
    setSkip(1);
    
    // Apollo Client will automatically execute the query when the component mounts or when selectedPrograms changes
  };

  const handleDownloadExcel = () => {
    // Logic to convert reportData to Excel format and initiate download
    const csvData = createCSVString(reportData);
    const ws = XLSX.read(csvData, { type: 'binary' }).Sheets.Sheet1;
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
    XLSX.writeFile(wb, 'exported_report.xlsx');  // Use .xlsx extension for Excel files
  };


  const handleDownloadCSV = () => {
    // Logic to convert reportData to CSV format and initiate download
    const csvData = createCSVString(reportData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'exported_report.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const createCSVString = (data: any[]) => {
    if (data.length === 0) {
      return ''; // Return an empty string for no data
    }
  
    // Extract keys from the first item (assuming all items have the same structure)
    const keys = Object.keys(data[0]);
  
    // Exclude __typename from keys
    const filteredKeys = keys.filter((key) => key !== '__typename');
  
    // Create the header row
    const headerRow = filteredKeys.join(',');
  
    // Create rows for each item
    const dataRows = data.map((item) =>
      filteredKeys.map((key) => {
        // Format dates if the value is a Date object
        if (key === 'ProfileCreationDate' || key === 'ProfileLastUpdatedDate')
        {
          const date = new Date(item[key]);
          const value = date.toLocaleDateString() 
          return value;
        }

        return item[key];
        
      }).join(',')
    );
  
    // Combine header and data rows
    const csvString = [headerRow, ...dataRows].join('\n');
  
    return csvString;
  };
  

  return (
    <div>
      <h1>Program Report</h1>

      {/* Program Selection Checkboxes */}
      <label>
        <input type="checkbox" checked={selectedPrograms.includes('mist')} onChange={() => handleProgramCheckboxChange('mist')} />
        MIST
      </label>
      <label>
        <input type="checkbox" checked={selectedPrograms.includes('fist')} onChange={() => handleProgramCheckboxChange('fist')} />
        FIST
      </label>
      <label>
        <input type="checkbox" checked={selectedPrograms.includes('dsh')} onChange={() => handleProgramCheckboxChange('dsh')} />
        DSH
      </label>
      <label>
        <input type="checkbox" checked={selectedPrograms.includes('odr')} onChange={() => handleProgramCheckboxChange('odr')} />
        ODR
      </label>
      <label>
        <input type="checkbox" checked={selectedPrograms.includes('prgmwn')} onChange={() => handleProgramCheckboxChange('prgmwn')} />
        PRGMWN
      </label>
      <label>
        <input type="checkbox" checked={selectedPrograms.includes('lead')} onChange={() => handleProgramCheckboxChange('lead')} />
        LEAD
      </label>

      {/* Generate Report Button */}
      <button onClick={handleGenerateReport}>Generate Report</button>

      {/* Download CSV Button */}
      <button onClick={handleDownloadCSV}>Download CSV</button>

      {/* Download CSV Button */}
      <button onClick={handleDownloadExcel}>Download XLS</button>

      {/* Display Report Data */}
      {loading && <p>Loading...</p>}
      {reportData.length > 0 && (
        
        <>
            <div className='columns11'>
              <div>ODRNumber</div>
              <div>Participant ID</div>
              <div>CID</div>              
              <div>First Name</div>
              <div>Last Name</div>
              <div>CIN</div>
              <div>Disposition</div>
              <div>Medcall Status</div>
              <div>Program</div>
              <div>Profile Creation Date</div>
              <div>Profile Last Updated Date</div>
            </div>
        
        
            
            {reportData.map((item:any, i:number) => (
              <div className={`columns11 ${isOdd(i) ? '': 'alt'}`} key={item.CID}>
                <div>{item.ODRNumber}</div>
                <div>{item.ParticipantID}</div>
                <div>{item.CID}</div>                
                <div>{item.FirstName}</div>
                <div>{item.LastName}</div>
                <div>{item.CIN}</div>
                <div>{item.Disposition}</div>
                <div>{item.MedcallStatus}</div>
                <div>{item.Program}</div>
                <CustomDate value={item.ProfileCreationDate}></CustomDate>
                <CustomDate value={item.ProfileLastUpdatedDate}></CustomDate>
              </div>
            ))}
        </>        
      )}
    </div>
  );
};