import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import ReactQuill from 'react-quill';
import  ReactModal from 'react-modal';

import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';


const GET_SERVER_CONFIG = gql`
    query GetServerConfig {
        getServerConfig {
            inactiveUserEmailWarning
            inactiveUserEmailDisabled
            passwordExpiringEmailWarning
            inactiveUserSystemEnabled
            passwordExpiringSystemEnabled
            twoFactorEmailMessage
            emailSupportAddress
            emailSupportName
            emailSenderAddress
            emailSenderName
        }
    }
`;

const UPDATE_SERVER_CONFIG = gql`
    mutation UpdateServerConfig($updates: ConfigSettingsInput) {
        updateServerConfig(updates: $updates) {
            message
            responseCode
        }
    }
`;
const ModelHeader = ({paricipant, title, onClose}:any)=>{
    return (
        <div className='modalHeader'>
           
            <div className='modalBanner'>
            <h2>{title}</h2>             
              <button className='modalClose' onClick={onClose}>X</button>
            </div>

            < div className='modalHeaderName'></div>
        </div>
    )
}


const HTMLInput = (params:any) => {
    const {name, value, onChange} = params
    const [html, setHtml] = useState('');
    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
        setHtml(value);
    }, [value]);


    if (!enabled) {
        return (
            <div>
                <button onClick={() => setEnabled(true)}>Edit</button>     
            </div>
        );
    }

    return (
        <div>
            <ReactModal isOpen={enabled} onRequestClose={()=>setEnabled(false)} >
            <ModelHeader {...params} onClose={()=>setEnabled(false)}/>
            <ReactQuill value={html} onChange={setHtml} />
            <button onClick={() => {
                setEnabled(false);
                onChange({ target: { name: name, value: html } });
            }}>Save</button>
            </ReactModal>
        </div>
    );


}




export const ServerConfig = () => {
    const { loading, error, data } = useQuery(GET_SERVER_CONFIG);
    const [updateServerConfig] = useMutation(UPDATE_SERVER_CONFIG);
    const [config, setConfig] = useState({
        inactiveUserEmailWarning: '',
        inactiveUserEmailDisabled: '',
        passwordExpiringEmailWarning: '',
        inactiveUserSystemEnabled: false,
        passwordExpiringSystemEnabled: false,
        emailSupportAddress: '',
        emailSupportName: '',
        emailSenderAddress: '',
        emailSenderName: '',
        twoFactorEmailMessage: '',
    }) as any



    useEffect(() => {
        if (data) {
            setConfig(data.getServerConfig);
        }
    }, [data]);

    const handleInputChange = (event:any) => {
        //handle checkboxes
        if (event.target.type === 'checkbox') {
            setConfig({
                ...config,
                [event.target.name]: event.target.checked
            });
            return;
        }
        setConfig({
            ...config,
            [event.target.name]: event.target.value
        });
    };

    const updateConfig = async (config: any) => {        
        const { __typename, ...cleanConfig } = config;
        //handle checkboxes
        cleanConfig.inactiveUserSystemEnabled = cleanConfig.inactiveUserSystemEnabled ? true : false;
        cleanConfig.passwordExpiringSystemEnabled = cleanConfig.passwordExpiringSystemEnabled ? true : false;

        const {data} = await updateServerConfig({ variables: { updates: cleanConfig } });
        
        if (data.updateServerConfig.responseCode === 0) {
            toast.success('Server Configuration Updated Successfully');
        }
    };

    const handleSubmit = (event:any) => {
        event.preventDefault();
        updateConfig (config)
    };


    const handleInputChangeNow = (event:any) => {
        handleInputChange(event);
        updateConfig ({
                ...config,
                [event.target.name]: event.target.value
            });
    }
        

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <div>
            <h1>Server Config</h1>

            <form onSubmit={handleSubmit}>
                
                <div className='boxed'>
                <h2>Emails Sent to Users about Security Matters</h2>
                <label>
                    Inactive User System Enabled:
                    <input
                        type="checkbox"
                        title="Inactive User System Enabled"
                        name="inactiveUserSystemEnabled"
                        checked={config.inactiveUserSystemEnabled}
                        onChange={handleInputChange}
                    />
                </label><br></br>
                
                <label>
                    Email sent to users who have been inactive for X days:
                    <HTMLInput
                        type="text"
                        title='Inactive User Email Warning'
                        name="inactiveUserEmailWarning"
                        value={config.inactiveUserEmailWarning || ''}
                        onChange={handleInputChangeNow}
                    />
                </label>
                <label>
                    Email sent to users who have been disabled due to inactivity:
                    <HTMLInput
                        type="text"
                        title="Inactive User Email Disabled"
                        name="inactiveUserEmailDisabled"
                        value={config.inactiveUserEmailDisabled || ''}
                        onChange={handleInputChangeNow}
                    />
                </label>

                <br></br>
                <label>
                    Password Expiring System Enabled:
                    <input
                        type="checkbox"
                        title="Password Expiring System Enabled"
                        name="passwordExpiringSystemEnabled"
                        checked={config.passwordExpiringSystemEnabled}
                        onChange={handleInputChange}
                    />
                </label><br></br>

                <label>
                    Email Sent to Users stating they password will expire in X days:
                    <HTMLInput
                        type="text"
                        title="Expired password warning email"
                        name="passwordExpiringEmailWarning"
                        value={config.inactiveUserEmailDisabled || ''}
                        onChange={handleInputChangeNow}
                    />
                </label>


                <label>
                    Email Sent to Users when they log in with two factor authentication:
                    <HTMLInput
                        type="text"
                        title="Two Factor Auth Code"
                        name="twoFactorEmailMessage"
                        value={config.twoFactorEmailMessage || ''}
                        onChange={handleInputChangeNow}
                    />
                </label>


                </div>

                <div className='boxed'>
                <h2>Email Sender Information</h2>
                <label>
                    Email Sender Address:
                    <input
                        type="text"
                        title="Email Sender Address"
                        name="emailSenderAddress"
                        value={config.emailSenderAddress}
                        onChange={handleInputChange}
                    />
                </label><br></br>
                <label>
                    Email Sender Name:
                    <input
                        type="text"
                        title="Email Sender Name"
                        name="emailSenderName"
                        value={config.emailSenderName}
                        onChange={handleInputChange}
                    />
                </label><br></br>

                <label>
                    Contact Support Email Address:
                    <input
                        type="text"
                        title="Contact Support Email Address"
                        name="emailSupportAddress"
                        value={config.emailSupportAddress}
                        onChange={handleInputChange}
                    />
                </label><br></br>
                <label>
                    Contact Support Name:
                    <input
                        type="text"
                        title="Contact Support Name"
                        name="emailSupportName"
                        value={config.emailSupportName}
                        onChange={handleInputChange}
                    />
                </label><br></br>

                </div>
                
                <br></br>
                
          

            
                <button type="submit">Update Config</button>
            </form>
        </div>
    );
};
