import React from 'react';


  
  export const PublicRoute = (params:any ) => {
    const { element, settings, children } = params;
  
    return (
      <React.Fragment>      
        {children}
      </React.Fragment>
    )     
  }
    