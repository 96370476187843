import React, { useState } from "react";
import { EditScans } from "./modals/EditScans";
import { ScanPreview } from "./modals/PreviewScan";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { isoDateToMDY } from "../../utils/common";

const GET_PARTICIPANT_BY_ODR_NUMBER = gql`
  query GetParticipantByODRNumber($odrnumber: Integer!) {
    getParticipantByODRNumber(odrnumber: $odrnumber) {
      scans {
        id
        filename
        filetype
        filelabel
        filedate
      }
    }
  }
`;

export const Scans = ({
  odrnumber,
  selectedProgram,
}: {
  odrnumber: number;
  selectedProgram: number;
}) => {
  const [editScans, setEditScans] = useState(false);
  const [selectedScan, setSelectedScan] = useState<any>(null);

  const { loading, error, data } = useQuery(GET_PARTICIPANT_BY_ODR_NUMBER, {
    variables: { odrnumber },
    skip: !odrnumber,
  });

  if (loading) return <div>Loading scans...</div>;
  if (error) return <div>Error loading scans :(</div>;

  const scans = data?.getParticipantByODRNumber?.scans || [];

  return (
    <div>
      <div
        style={{
          marginTop: "1rem",
          display: "grid",
          gridTemplateColumns: "15rem repeat(4,1fr)",
          gridRowGap: "1.5rem",
        }}
      >
        <div style={{ display: "flex" }}>
          <b style={{ marginRight: "1rem" }}>Scans</b>
          <div>
            <button onClick={() => setEditScans(true)}>
              <div style={{ fontSize: "smaller" }}>Edit Scans</div>
            </button>
          </div>
        </div>
        <div>
          <div>Court File</div>
          <select onChange={(e) => setSelectedScan(e.target.value)}>
            <option value="">Select a scan</option>
            {scans.map(
              (scan: any) =>
                scan.filetype.indexOf("courtFile") > -1 && (
                  <option
                    key={scan.id}
                    value={scan.id}
                  >{`Court File ${scan.filelabel}`}</option>
                )
            )}
          </select>
        </div>
        <div>
          <div>Rap Sheet</div>
          <select onChange={(e) => setSelectedScan(e.target.value)}>
            <option value="">Select a scan</option>
            {scans.map(
              (scan: any) =>
                scan.filetype.indexOf("rapSheet") > -1 && (
                  <option
                    key={scan.id}
                    value={scan.id}
                  >{`Rapsheet ${isoDateToMDY(scan.filedate)}`}</option>
                )
            )}
          </select>
        </div>
        <div>
          <div>Other</div>
          <select onChange={(e) => setSelectedScan(e.target.value)}>
            <option value="">Select a scan</option>
            {scans.map(
              (scan: any) =>
                scan.filetype.indexOf("other") > -1 && (
                  <option
                    key={scan.id}
                    value={scan.id}
                  >{`Other ${scan.filelabel}`}</option>
                )
            )}
          </select>
        </div>
      </div>

      <EditScans
        isOpen={editScans}
        onClose={() => setEditScans(false)}
        scans={scans}
        odrNumber={odrnumber}
        programNumber={selectedProgram}
      />

      {selectedScan && (
        <ScanPreview
          odrnumber={odrnumber}
          scan={selectedScan}
          close={() => setSelectedScan(null)}
        />
      )}
    </div>
  );
};
