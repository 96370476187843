import React, { useState, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { FormRenderer } from './lib/formRender';
import { useParams, useNavigate  } from 'react-router-dom';
import { toast } from 'react-toastify';


import ecmForm from './forms/ecmForm.json';
import initalassessmentForm from './forms/initialassessment.json';
import lacareForm from "./forms/LACareForm3.json"
import screeningForm from "./forms/screeningChecklist.json"

import { usePrintView } from '../../store/usePrintView';

const formTypes = {
  ecmForm : { form: ecmForm, name: "ECM Form"},
  initialassessmentForm : { form: initalassessmentForm, name: "Initial Assessment Form"},
  LACAREREF : { form: initalassessmentForm, name: "LACare Form"},
  screeningForm : { form: screeningForm, name: "Screening Checklist"}  
} as any


const GET_PARTICIPANT_DOCUMENT = gql`
  query GetParticipantDocument($documentID: Integer!) {
    getParticipantDocument(documentID: $documentID) {
      id
      odrparticipant
      contents
      published
      tags
      cin
      name
      type
      metadata
    }
  }
`;



const UPDATE_DOCUMENT = gql`
  mutation updateCalAIMECMForm(
    $id: Int
    $contents: String!
  ) {
    updateCalAIMECMForm(id: $id, contents: $contents) {
      id
      
    }
  }
`;

const CREATE_DOCUMENT = gql`
  mutation createCalAIMECMForm(
    $odrparticipant: Int
    $contents: String!
  ) {
    createCalAIMECMForm(odrparticipant:$odrparticipant, contents: $contents) {
      id      
    }
  }
`;


const GET_DOCUMENT = gql`
  query getCalAIMECMForm($id: Int!) {
    getCalAIMECMForm(id: $id) {
      id
      odrparticipant {id}     
      contents
      metadata
    }
  }
`;


export const ParticipantDocument = () => {
  const { documentID } = useParams() as any;
  const { loading, error, data } = useQuery(GET_PARTICIPANT_DOCUMENT, {
    variables: { documentID: parseInt(documentID) },
    onCompleted: (data) => {
      setDocument(data.getParticipantDocument);
      setContents(JSON.parse(data.getParticipantDocument.contents));
    }
  });

  
  const [document, setDocument] = useState<any>({}); 
  const [contents, setContents] = useState<any>({});

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;


  if (!formTypes [document.type])
    return <div>Form type not found</div>;

  const form = formTypes [document.type].form as any; 

  return (
    <div>
    
      <div>
        <FormRenderer
          readOnly={false}
          formTemplate={form}
          formState={contents}
          setFormState={setContents}
          onSubmit={null}
        />
      </div>
    </div>
  );
};




