import { gql } from '@apollo/client';

export const GET_PARTICIPANT = gql`
  query getParticipant($odrnumber: Integer!) {
    getParticipantByODRNumber(odrnumber: $odrnumber) {
        outreach {
            id
            contactMethod
            contactDate
            results
            qaStaff
            address
            city
            zip
            email
            phoneNumber
            nameOfContact
            notes
        }
    }
  }
`;

export const CREATE_PARTICIPANT_OUTREACH = gql`
  mutation createParticipantOutreach(
    $odrnumber: Integer!
    $participant: Integer!
    $input: OutReachInput!    
  ) {
    createParticipantOutreach(
        odrnumber: $odrnumber
        participant: $participant
        input: $input
    ) {      
      message
    }
  }
`;
 

export const DELETE_PARTICIPANT_OUTREACH = gql`
  mutation deleteParticipantOutreach(
    $outreachid: Integer!    
  ) {
    deleteParticipantOutreach(
        outreachid: $outreachid
    ) {      
      message
    }
  }
`;
 
