 
import { Login as LoginComponent } from './LoginComponent'

export const Login = () => {
    
    const onSuccess = () => {
        window.location.href = '/search'
    }

    return (
        <LoginComponent onSuccess={onSuccess}/>
    )
}