import React from 'react';

export const FileNotFound404 = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <h1>Welcome to the Diversion Database</h1>
      <img alt="LA County" src="/images/lacountyseal.png" />
      <h2>Page Not Found</h2>
    </div>
  );
};
