import React, { useState, useEffect, useRef } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useAuthenticationState } from '../../../store/useAuthenticationState';

const LOGIN_PASSWORD = gql`
    mutation ValidatePassword($username: String!, $password: String!) {
        validatePassword(username: $username, password: $password)
    }
`;



export const LockScreen = ({onSuccess} :any) => {
    const { authenticationState, setAuthenticationState } = useAuthenticationState();

    const [state, setState] = useState({
        username: '',
        password: '',
        error: '',        
        loading: false,
        
    })

    const [loginPassword, { data: loginPasswordData, error: loginPasswordError }] = useMutation(LOGIN_PASSWORD);


    const handleUsername = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        const loginPasswordResult = await loginPassword({ variables: { username: state.username, password: state.password } });
        if (loginPasswordResult?.data?.validatePassword) {
            onSuccess();
        }
    }


    return (
        <div>
            <img src={`${process.env.PUBLIC_URL}/logoODR.png`} alt="logo" style={{width: '200px', margin: '0 auto'}}/>
            
            <div style={{textAlign: 'center'}}>
            <h1>Unlock Session</h1>            
            <div>For security reasons, your session has been locked.</div>
            <div>Please enter your username and password to unlock.</div>
            
             <form onSubmit={handleUsername}>
                <div>
                    <div style={{marginTop: '2rem'}}>
                        <div><label>Username </label></div>
                        <input type="text" value={state.username} onChange={(e) => setState({ ...state, username: e.target.value })} />
                    </div>

                    <div style={{marginTop: '2rem'}}>
                        <div><label>Password </label></div>
                        <input type="password" value={state.password} onChange={(e) => setState({ ...state, password: e.target.value })} />
                    </div>

                    </div>
                    
                    
                    <div style={{marginTop: '2rem'}}>
                        <button type="submit">Login</button>
                    </div>
                
                
            </form>
            </div>
        
        </div>
    )
}