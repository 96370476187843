import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";

import { useMutation, gql } from "@apollo/client";


const UPDATE_PARTICIPANT_PROGRAM_DATA = gql`
  mutation updateParticipantProgramDataByProgramNumber($programNumber: Integer!, $updates: participantProgramDataInput) {
    updateParticipantProgramDataByProgramNumber(programNumber: $programNumber, updates: $updates) {
      responseCode
      message
    }
  }
`;

export const EditLegalGeneral = ({
  isOpen,
  onClose,
  programNumber,
  legalData,
  refetch,
}: {
  isOpen: boolean;
  onClose: () => void;
  programNumber: number;
  legalData: any;
  refetch: () => void;
}) => {
  const [formData, setFormData] = useState({
    bookingNumber: "",
    arrestDate: "",
    ist: { status: "", statusDate: "" },
    maxDate: "",
    rso: false,
    mainfour: "",
    commitmentdate: "",
    diversionDate: "",
    releaseDate: "",
  });

  const [updateParticipantProgramData] = useMutation(UPDATE_PARTICIPANT_PROGRAM_DATA);
  

  useEffect(() => {
    if (legalData) {
      setFormData({
        bookingNumber: legalData.bookingNumber || "",
        arrestDate: legalData.arrestDate || "",
        ist: {
          status: legalData.ist?.status || "",
          statusDate: legalData.ist?.statusDate || "",
        },
        maxDate: legalData.maxDate || "",
        rso: legalData.rso || false,
        mainfour: legalData.mainfour || "",
        commitmentdate: legalData.commitmentdate || "",
        diversionDate: legalData.diversionDate || "",
        releaseDate: legalData.releaseDate || "",
      });
    }
  }, [legalData]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (name === "istStatus" || name === "istStatusDate") {
      setFormData((prev) => ({
        ...prev,
        ist: { ...prev.ist, [name.replace("ist", "").toLowerCase()]: value },
      }));
    } else if (name === "rso") {
      setFormData((prev) => ({ ...prev, [name]: value === "true" }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Here you would typically send the updated data to your backend
    console.log("Updated form data:", formData);
    updateParticipantProgramData({
      variables: {
        programNumber,
        updates: {
          bookingNumber: formData.bookingNumber,          
          mainfour: parseInt(formData.mainfour),
          arrestDate: formData.arrestDate,
          ist: {
            status: formData.ist.status,
            statusDate: formData.ist.statusDate,
          },
        },
      },
    });
    refetch();
    onClose();
  };

  return (
    <ReactModal
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        content: {
          position: "relative",
          background: "white",
          padding: "20px",
          maxHeight: "80vh",
          overflowY: "scroll",
          width: "100%",
          maxWidth: "600px",
          inset: 0,
        },
      }}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <div style={{ marginBottom: "1.5rem" }}>
        <div
          style={{ display: "flex", justifyContent: "end", alignItems: "end" }}
        >
          <button type="button" onClick={onClose}>
            Close
          </button>
        </div>
        <h2
          style={{
            fontSize: "1.875rem",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Edit Legal Information
        </h2>
        <form
          onSubmit={handleSubmit}
          style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "0.5rem" }}>
            <label>Booking Number:</label>
            <input
              type="text"
              name="bookingNumber"
              value={formData.bookingNumber}
              onChange={handleInputChange}
              maxLength={11}
            />
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "0.5rem" }}>
            <label>MAIN Last 4:</label>
            <input
              type="text"
              name="mainfour"
              value={formData.mainfour}
              onChange={handleInputChange}
              minLength={4}
              maxLength={4}
            />
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "0.5rem" }}>
            <label>Arrest Date:</label>
            <input
              type="date"
              name="arrestDate"
              value={formData.arrestDate}
              onChange={handleInputChange}
            />
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "0.5rem" }}>
            <label>IST Status:</label>
            <select
              name="istStatus"
              value={formData.ist.status}
              onChange={handleInputChange}
            >
              <option value="">--</option>
              <option value="0">Competent to stand trial</option>
              <option value="1">Incompetent to stand trial</option>
            </select>
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "0.5rem" }}>
            <label>IST Status Date:</label>
            <input
              type="date"
              name="istStatusDate"
              value={formData.ist.statusDate}
              onChange={handleInputChange}
            />
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "0.5rem" }}>
            <label>Max Date:</label>
            <input
              type="date"
              name="maxDate"
              value={formData.maxDate}
              onChange={handleInputChange}
            />
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "0.5rem" }}>
            <label>RSO Status:</label>
            <select
              name="rso"
              value={formData.rso ? "true" : "false"}
              onChange={handleInputChange}
            >
              <option value="false">Not RSO</option>
              <option value="true">RSO</option>
            </select>
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "0.5rem" }}>
            <label>Commitment Date:</label>
            <input
              type="date"
              name="commitmentdate"
              value={formData.commitmentdate}
              onChange={handleInputChange}
            />
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "0.5rem" }}>
            <label>Diversion Date:</label>
            <input
              type="date"
              name="diversionDate"
              value={formData.diversionDate}
              onChange={handleInputChange}
            />
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "0.5rem" }}>
            <label>Release Date (projected):</label>
            <input
              type="date"
              name="releaseDate"
              value={formData.releaseDate}
              onChange={handleInputChange}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}
          >
            <button type="submit" onClick={handleSubmit}>Add/Update</button>
          </div>
        </form>
      </div>
    </ReactModal>
  );
};
