 
// queries.ts

import { gql } from '@apollo/client';

// Query to get health plan billing info by ID
export const GET_HEALTH_PLAN_BILLING_INFO_BY_ID = gql`
  query GetHealthPlanBillingInfoByID($id: String!) {
    getHealthPlanBillingInfoByID(id: $id) {
      id
      healthPlanName
      isaAuthorizationInformationQualifier_1
      isaAuthorizationInformation_2
      isaInterchangeSenderID_6
      isaReceiverIDQualifier_7
      isaInterchangeReceiverID_8
      isaInterchangeControlStandardsIdentifier_11
      isaInterchangeControlVersionNumber_12
      isaInterchangeControlNumber_13
      isaAcknowledgementRequested_14
      isaUsageIndicator_15
      gsCodeIdentifyingInformationType_1
      gsSenderIDCode_2
      gsReceiverIDCode_3
      gsTransactionTypeCode_7
      bhtSubmitterTransactionIdentifier_03
      nm1_SubmitterName_EntityIdentifierCode_01
      nm1_SubmitterName_ResponseContactLastorOrganizationName_03
      nm1_SubmitterName_IdentificationCodeQualifier_08
      nm1_SubmitterName_ResponseContactIdentifier_09
      perSubmitterEDIContact_ContactFunctionCode_01
      perSubmitterEDIContact_ResponseContactName_02
      perSubmitterEDIContact_CommunicationNumberQualifier_03
      perSubmitterEDIContact_ResponseContactCommunicationNumber_04
      perSubmitterEDIContact_CommunicationNumberQualifier_05
      perSubmitterEDIContact_ResponseContactCommunicationNumber_06
      nm1ReceiverName_EntityIdentifierCode_01
      nm1ReceiverName_EntityTypeQualifier_02
      nm1ReceiverName_ResponseContactLastorOrganizationName_03
      nm1ReceiverName_IdentificationCodeQualifier_08
      nm1ReceiverName_ResponseContactIdentifier_09
      prv_BillingProviderSpecialty_ProviderCode_01
      prv_BillingProviderSpecialty_ReferenceIdentificationQualifier_02
      prv_BillingProviderSpecialty_ProviderTaxonomyCode_03
      billingProviderName_EntityIdentifierCode_01
      billingProviderName_EntityTypeQualifier_02
      billingProviderName_ResponseContactLastorOrganizationName_03
      billingProviderName_IdentificationCodeQualifier_08
      billingProviderName_ResponseContactIdentifier_09
      billingProviderAddress_ResponseContactAddressLine_01
      billingProviderAddressInformation_ContactCityName_01
      billingProviderAddressInformation_ContactStateCode_02
      billingProviderAddressInformation_ContactPostalZoneorZIPCode_03
      ref_BillingProviderTaxId_ReferenceIdentificationQualifier_01
      ref_BillingProviderTaxId_MemberGrouporPolicyNumber_02
      payToAddressName_EntityIdentifierCode_01
      payToAddressName_ResponseContactLastorOrganizationName_03
      payToAddress_ADDRESS_ResponseContactAddressLine_01
      payToAddressCity_State_ZIPCode_AdditionalContactCityName_01
      payToAddressCity_State_ZIPCode_AdditionalContactStateCode_02
      payToAddressCity_State_ZIPCode_AdditionalContactZIPCode_03
    }
  }
`;

// Query to list health plans billing info
export const LIST_HEALTH_PLANS_BILLING_INFO = gql`
  query ListHealthPlansBillingInfo {
    listHealthPlansBillingInfo {
      id
      healthPlanName
    }
  }
`;

// Mutation to update health plan billing info by ID
export const UPDATE_HEALTH_PLAN_BILLING_INFO_BY_ID = gql`
  mutation UpdateHealthPlanBillingInfoByID($id: Integer!, $updates: updateHealthPlansBillingInfo!) {
    updateHealthPlanBillingInfoByID(id: $id, updates: $updates) {
      message
      responseCode
    }
  }
`;