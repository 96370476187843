import React, { useState } from 'react';
import {HealthPlanCommunicationUpload} from './HealthPlanCommunicationUpload';
import {ViewHealthPlanCommunication} from './ViewHealthPlanCommunication';


import './style.css'; 
export function HealthPlanCommunicationManager() {
  //each time setID is called i need to increment id by one
  const [id, setID] = useState(0);

  return (
    <div>
      <h1>LA Care SFTP Manager</h1>
      <div className="top-bar">
      <HealthPlanCommunicationUpload onUpload={() => setID(id => id + 1)}/>
      </div>
      <div className="stored-communications">
        <h2>Stored Health Plan Communications</h2>
        <ViewHealthPlanCommunication id={id}/> 
      </div>
    </div>
  );
}





