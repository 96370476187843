import React, { useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import { programNameFromID, isoDateToMDY } from "../../utils/common";

const GET_PARTICIPANT_BY_ODR_NUMBER = gql`
  query GetParticipantByODRNumber($odrnumber: Integer!) {
    getParticipantByODRNumber(odrnumber: $odrnumber) {
      nameHistory {
        lastName
        firstName
      }
      dob
      programs
      mrun
      cid
    }
  }
`;

export const ParticipantDataSection = ({
  odrnumber,
  selectedProgram,
  setSelectedProgram,
}: {
  odrnumber: number;
  selectedProgram: string;
  setSelectedProgram: (program: string) => void;
}) => {
  const { loading, error, data } = useQuery(GET_PARTICIPANT_BY_ODR_NUMBER, {
    variables: { odrnumber },
    skip: !odrnumber,
  });

  useEffect(() => {
    if (data?.getParticipantByODRNumber?.programs.length === 1) {
      setSelectedProgram(data?.getParticipantByODRNumber?.programs[0]);
    }
  }, [data, odrnumber, setSelectedProgram]);

  if (loading)
    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(7,1fr)",
          gridRowGap: "1.5rem",
        }}
      >
        Loading participant data...
      </div>
    );
  if (error) return <p>Error loading participant data :(</p>;

  const participantData = data.getParticipantByODRNumber;

  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(7,1fr)",
          gridRowGap: "1.5rem",
        }}
      >
        <div>
          <div>
            <select
              value={selectedProgram}
              onChange={(e) => setSelectedProgram(e.target.value)}
            >
              {participantData.programs.map((programid: string) => (
                <option key={programid} value={programid}>
                  {programid} - {programNameFromID(programid)}
                </option>
              ))}
            </select>
          </div>
          <b>{odrnumber}</b>
          {participantData.programs.map((programid: string) => (
            <div key={programid}>
              {programid} - {programNameFromID(programid)}
            </div>
          ))}
        </div>
        <div>
          {participantData.nameHistory.map((name: any, index: number) => (
            <div key={index}>
              {name.lastName}, {name.firstName}
            </div>
          ))}
        </div>

        <div>
          <b>DOB</b> : <div>{isoDateToMDY(participantData.dob)}</div>
        </div>
        <div>
          <b>ChampID</b> : <div>{participantData.cid}</div>
        </div>
        <div>
          <b>MRUN</b> : <div>{participantData.mrun}</div>
        </div>
      </div>
    </div>
  );
};
