import React from 'react';



const initialState = {
  isLocked: false,
  user: { realname: 'Not Logged in', groups: [], access: [], loggedIn: false },
  isUserLoading: true,
  isPrintView: false,
  helpTopics: ['default'],
  helpVars: ['default'],
  isHelpSidebarVisible: false,
};



export const StoreContext = React.createContext<any>(undefined);

export const StoreProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, setState] = React.useState(initialState);

  const store= {
    ...state,
    lock: () => setState((prev:any) => ({ ...prev, isLocked: true })),
    unlock: () => setState((prev:any) => ({ ...prev, isLocked: false })),
    logout: () => setState((prev:any) => ({ ...prev, user: initialState.user })),
    setUser: (user: any) => setState((prev:any) => ({ ...prev, user, isUserLoading: false })),
    setIsUserLoading: (loading: boolean) => setState((prev:any) => ({ ...prev, isUserLoading: loading })),
    setPrintView: (printView: boolean) => setState((prev:any) => ({ ...prev, isPrintView: printView })),
    currentHelpEnv: () => ({
      topic: state.helpTopics[state.helpTopics.length - 1] || 'default',
      vars: state.helpVars[state.helpVars.length - 1] || null
    }),
    currentHelpTopic: () => state.helpTopics[state.helpTopics.length - 1] || 'default',
    pushHelpTopic: (topic: string, vars: any = null) => setState((prev:any) => ({
      ...prev,
      helpTopics: [...prev.helpTopics, topic],
      helpVars: [...prev.helpVars, vars]
    })),
    popHelpTopic: () => setState((prev:any) => ({
      ...prev,
      helpTopics: prev.helpTopics.slice(0, -1),
      helpVars: prev.helpVars.slice(0, -1)
    })),
    showHelpSidebar: () => setState((prev:any) => ({ ...prev, isHelpSidebarVisible: true })),
    hideHelpSidebar: () => setState((prev:any) => ({ ...prev, isHelpSidebarVisible: false })),
  } as any;

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};