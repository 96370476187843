 
// Install dependencies:
// npm install @apollo/client graphql

// Import required modules
import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';


// Define the mutation add password
const UPDATE_DEV_DATABASE = gql`
  mutation AdminSecureDevDatabase($password: String!) {
    adminSecureDevDatabase(password: $password) {
      message
    }
  }
`;

// React functional component
export const UpdateDatabasePage = () => {
  // State to store the message from the mutation
  const [message, setMessage] = useState('');

  // Mutation hook
  const [adminSecureDevDatabase] = useMutation(UPDATE_DEV_DATABASE);
  const [password, setPassword] = useState('');

  const [loading, setLoading] = useState(false);
  // Function to handle button click
  const handleButtonClick = async () => {
    try {
      // Call the mutation
      setLoading(true);
      const { data } = await adminSecureDevDatabase( {variables: { password }});      
      setLoading(false);

      // Update the message state with the result
      setMessage(data.adminSecureDevDatabase.message);
    } catch (error : any) {
      console.error('Error updating development database:', error.message);
      // Handle errors as needed
    }
  };

  if (loading) {
    return <p>Updating database...</p>;
  }
  return (
    <div>
      <p>This will update the development database.</p>
      <button onClick={handleButtonClick}>Update Development Database</button>
      {message && <p style={{ color: 'red' }}>{message}</p>}

      <p>Enter the password to update the production database.</p>
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
    </div>
  );
};


