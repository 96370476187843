import React, { useState } from 'react'
import { HelpTopicsManager } from './helpTopics'
import Modal from 'react-modal';
import { useQuery, gql } from '@apollo/client';

const GET_LISTSUPPORTTICKETS = gql`
  query GetListSupportTickets {
    listSupportTickets {
      id
      message
      username
      userid
      email
    }
  }
`;

export const Help = () => {
  const [isTopics, setIsTopics] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState<any>(null) ;
  const { loading, error, data } = useQuery(GET_LISTSUPPORTTICKETS);

  const handleMessageClick = (message:any) => {
    setSelectedMessage(message);
  };

  const closeMessageModal = () => {
    setSelectedMessage(null);
  };

  return (
    <div>
      <button onClick={() => setIsTopics(true)}>Manage Help Topics</button>
      <Modal isOpen={isTopics} onRequestClose={() => setIsTopics(false)}>
        <div>
          <div style={{display: 'grid', gridTemplateColumns: '95% 1fr', gap: '10px'}}>
            <div></div>
            <div><button onClick={() => setIsTopics(false)}>Close</button></div>
          </div>
          <HelpTopicsManager />
        </div>
      </Modal>
      <div>
        <div>
          <h1>Help Messages</h1>
          <div style={{display: 'grid', gridTemplateColumns: '5rem 15rem 25rem 1fr'}}>
            <p>ID</p>
            <p>Username</p>
            <p>Email</p>
            <p>Message</p>
          </div>
          {data && data.listSupportTickets.map((message:any, index:number) => (
            <div 
              key={message.id} 
              style={{display: 'grid', gridTemplateColumns: '5rem 15rem 25rem 1fr', cursor: 'pointer'}} 
              className={index % 2 === 0 ? 'alt' : ''}
              onClick={() => handleMessageClick(message)}
            >
              <div>{message.id}</div>
              <div>{message.username}</div>
              <div>{message.email}</div>
              <div>{message.message.substring(0, 50)}...</div>
            </div>
          ))}
        </div>
      </div>
      <Modal isOpen={selectedMessage !== null} onRequestClose={closeMessageModal}>
        {selectedMessage && (
          <div>
            <h2>Support Message Details</h2>
            <p><strong>ID:</strong> {selectedMessage.id}</p>
            <p><strong>Username:</strong> {selectedMessage.username}</p>
            <p><strong>Email:</strong> {selectedMessage.email}</p>
            <p><strong>Message:</strong> {selectedMessage.message}</p>
            <button onClick={closeMessageModal}>Close</button>
          </div>
        )}
      </Modal>
    </div>
  );
};