import React from "react";
import ReactModal from "react-modal";

import { useMutation, gql } from "@apollo/client";

const CREATE_PARTICIPANT_ENROLLMENT = gql`
  mutation createParticipantEnrollment(
    $programNumber: Integer!
    $input: participantProgramDataEnrollmentInput
  ) {
    createParticipantEnrollment(programNumber: $programNumber, input: $input) {
      message
    }
  }
`;

const EnrollmentSites = [
  "SLA/LB",
  "HW",
  "Rampart",
  "ELA",
  "DTLA",
  "Venice",
  "AV",
];

const ReferralTypes = ["Social Contact", "Prebooking", "Community Referral"];

export const CreateEnrollment = ({
  participant,
  onClose,
  data,
}: {
  participant: number;
  onClose: () => void;
  data: any;
}) => {
  const [enrollment, setEnrollment] = React.useState({}) as any;
  const [createParticipantEnrollment] = useMutation(
    CREATE_PARTICIPANT_ENROLLMENT,
  );

  const handleSubmit = () => {
    const variables = {
      programNumber: participant,
      input: {
        enrollmentDate: new Date(enrollment.enrollmentDate),
        exitDate: new Date(enrollment.exitDate),
        exitReason: enrollment.exitReason,
        notes: enrollment.note,
        referralType: enrollment.referralType,
        referringAgency: enrollment.referringAgency,
        referringIndividual: enrollment.referringIndividual,
        site: enrollment.site,
      },
    };
    createParticipantEnrollment({ variables });
  };

  return (
    <ReactModal
      isOpen={true}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        content: {
          position: "relative",
          background: "white",
          padding: "20px",
          maxHeight: "80vh",
          overflowY: "scroll",
          width: "100%",
          maxWidth: "800px",
          inset: 0,
        },
      }}
      onRequestClose={onClose}
    >
      <div
        style={{ display: "flex", justifyContent: "end", alignItems: "end" }}
      >
        <button type="button" onClick={onClose}>
          Close
        </button>
      </div>
      <h2>Create Enrollment</h2>
      <div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <label>Enrollment Date</label>
          <input
            type="date"
            value={enrollment.enrollmentDate}
            onChange={(e) =>
              setEnrollment({ ...enrollment, enrollmentDate: e.target.value })
            }
          />
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <label>Enrollment Site</label>
          <select
            value={enrollment.site}
            onChange={(e) =>
              setEnrollment({ ...enrollment, site: e.target.value })
            }
          >
            {EnrollmentSites.map((site) => (
              <option value={site}>{site}</option>
            ))}
          </select>
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <label>Referring Agency</label>
          <input
            type="text"
            value={enrollment.referringAgency}
            onChange={(e) =>
              setEnrollment({ ...enrollment, referringAgency: e.target.value })
            }
          />
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <label>Referring Individual</label>
          <input
            type="text"
            value={enrollment.referringIndividual}
            onChange={(e) =>
              setEnrollment({
                ...enrollment,
                referringIndividual: e.target.value,
              })
            }
          />
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <label>Referral Type</label>
          <select
            value={enrollment.referralType}
            onChange={(e) =>
              setEnrollment({ ...enrollment, referralType: e.target.value })
            }
          >
            {ReferralTypes.map((type) => (
              <option value={type}>{type}</option>
            ))}
          </select>
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <label>Exit Date</label>
          <input
            type="date"
            value={enrollment.exitDate}
            onChange={(e) =>
              setEnrollment({ ...enrollment, exitDate: e.target.value })
            }
          />
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <label>Exit Reason</label>
          <input
            type="text"
            value={enrollment.exitReason}
            onChange={(e) =>
              setEnrollment({ ...enrollment, exitReason: e.target.value })
            }
          />
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <label>Enrollment Note</label>
          <textarea
            value={enrollment.note}
            onChange={(e) =>
              setEnrollment({ ...enrollment, note: e.target.value })
            }
          />
        </div>
      </div>
      <button onClick={handleSubmit}>Create</button>
    </ReactModal>
  );
};
