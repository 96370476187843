import React from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';

// Define the GraphQL query
const ACTIVE_USER_REPORT_QUERY = gql`
  query ActiveUserReport {
    activeUserReport {
      entries {
        username
        name
        lastlogin
        email
        disabled
        daysSinceLogin
        warningCount
        lastWarningDate
        status
      }
      totalCount
    }
  }
`;

// Define the GraphQL mutation to disable a user
const DISABLE_USER_MUTATION = gql`
  mutation DisableUser($username: String!) {
    disableUser(username: $username) {
      success
      message
    }
  }
`;

const DateDisplay = ({ value }: any) => {
  if (!value) {
    return <React.Fragment>None</React.Fragment>;
  }

  // Convert the provided date string to a Date object
  const date = new Date(value);

  // Extract the individual date components
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-based
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();

  // Create the formatted date string
  const formattedDate = `${month}/${day}/${year}`;

  return <React.Fragment>{formattedDate}</React.Fragment>;
};

// Component to display active user report
export const ActiveUserReport = () => {
  const { loading, error, data } = useQuery(ACTIVE_USER_REPORT_QUERY);
  const [disableUser] = useMutation(DISABLE_USER_MUTATION, {
    refetchQueries: [{ query: ACTIVE_USER_REPORT_QUERY }],
  });

  const handleDisableUser = async (username: string) => {
    try {
      // Call the mutation to disable the user
      await disableUser({
        variables: { username },
      });
    } catch (error: any) {
        toast.error(`Error disabling user: ${error.message}`);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const downloadReport = () => {
    const fileName = 'active_user_report.xlsx';
    const wsData = [
      ['Username', 'Name', 'Last Login', 'Email', 'Disabled'],
      ...data.activeUserReport.entries.map((entry: any) => [
        entry.username,
        entry.name,
        entry.lastlogin,
        entry.email,
        entry.disabled ? 'Yes' : 'No',
        entry.daysSinceLogin,
        entry.warningCount,
        entry.lastWarningDate,
        entry.status,
      ]),
    ];
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Active User Report');
    XLSX.writeFile(wb, fileName);
  };

  const columns='6rem 15rem 20rem 8rem 8rem 8rem 8rem 8rem 15rem 5rem'
  const statusColor = {
    'Active': 'black',    
    'Disabled': 'grey',
    'Inactive': 'orange',
    'Pending Disable': 'red',
    'Warning': 'orange'
  } as any

  return (
    <div>
      <h2>Active User Report</h2>
      <p>Total Count: {data.activeUserReport.totalCount}</p>
      <button onClick={downloadReport}>Download Report</button>
      <ul>
        <div style={{display: 'grid', gridTemplateColumns: columns, gap: '10px', borderBottom: '1px solid #ccc'}}>
          <div>
            <strong>Username</strong>
          </div>
          <div>
            <strong>Name</strong>
          </div>          
          <div>
            <strong>Email</strong>
          </div>
          <div>
            <strong>Disabled</strong>
          </div>
          <div>
            <strong>Last Login</strong>
          </div>
          <div>
            <strong>Days Since Login</strong>
          </div>
          <div>
            <strong>Warning Count</strong>
          </div>
          <div>
            <strong>Last Warning Date</strong>
          </div>
          <div>
            <strong>Status</strong>
          </div>
          <div>
            <strong>Disable</strong>
          </div>
        </div>
        {data.activeUserReport.entries.map((entry: any, index: number) => (
          
          
          <div style={{display: 'grid', gridTemplateColumns: columns, gap: '10px', color: statusColor[entry.status]}} key={index} className={index % 2 === 0 ? 'alt' : ''}>
            <div>
              {entry.username}
            </div>
            <div>
              {entry.name}
            </div>
            <div>
              {entry.email}
            </div>
            <div>
              {entry.disabled ? 'Yes' : 'No'}
            </div>
            <div>
              <DateDisplay value={entry.lastlogin} />
            </div>
            <div>
              {entry.daysSinceLogin}
            </div>
            <div>
              {entry.warningCount}
            </div>
            <div>
              <DateDisplay value={entry.lastWarningDate} />
            </div>
            <div>
              {entry.status}
            </div>
            <div>
              <button onClick={() => handleDisableUser(entry.username)}>Disable</button>
            </div>
          </div>
        ))}
      </ul>
    </div>
  );
};
 