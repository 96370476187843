import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useHelpTopics } from "../../store/useHelpTopics";
import { ParticipantDataSection } from "./ParticipantDataSection";
import { ProgramDataSection } from "./ProgramDataSection";
import { LegalStatus } from "./LegalStatus";
import { MedicalStatus } from "./MedicalStatus";
import { PlanAndLocation } from "./PlanAndLocation";
import { CalAIM } from "./CalAIM";
import { Notes } from "./Notes";
import { Notifications } from "./Notifications";
import { Enrollment } from "./Enrollment";
import { Incidents } from "./Incidents";
import { Scans } from "./Scans";

const defaultSections = {
  enrollment: false,
  legal: false,
  medical: false,
  disposition: false,
  notifications: false,
  calaim: false,
  notes: false,
  incidents: false,
};

export const ParticipantsManager = (params: any) => {
  const { participant: participantReactParams } = params;
  const { participant: participantURLParams }: any = useParams();
  const participant = participantReactParams || participantURLParams;
  const [selectedProgram, setSelectedProgram] = useState("");
  const [isProgramDataLoaded, setIsProgramDataLoaded] = useState(false);
  const { pushHelpTopic, popHelpTopic } = useHelpTopics();
  const [displaySections, setDisplaySections] = useState(defaultSections);

  useEffect(() => {
    pushHelpTopic("Participant Manager");
    return () => {
      popHelpTopic();
    };
  }, []);

  const toggleSection = (section: keyof typeof displaySections) => {
    setDisplaySections((prev) => ({
      ...defaultSections,
      [section]: !prev[section],
    }));
  };

  const handleProgramDataLoaded = () => {
    setIsProgramDataLoaded(true);
  };

  return (
    <div className="boxed">
      <div>
        <ParticipantDataSection
          odrnumber={parseInt(participant)}
          selectedProgram={selectedProgram}
          setSelectedProgram={setSelectedProgram}
        />
        <hr />
        {selectedProgram && (
          <ProgramDataSection
            selectedProgram={selectedProgram}
            onDataLoaded={handleProgramDataLoaded}
          />
        )}{" "}
        <hr />
        <Scans
          odrnumber={parseInt(participant)}
          selectedProgram={parseInt(selectedProgram)}
        />
      </div>
      {isProgramDataLoaded && (
        <>
          <div
            style={{ marginTop: "20px", marginBottom: "20px" }}
            className="boxed"
          >
            {Object.keys(displaySections).map((section) => (
              <button
                key={section}
                onClick={() =>
                  toggleSection(section as keyof typeof displaySections)
                }
                style={{
                  color: displaySections[
                    section as keyof typeof displaySections
                  ]
                    ? "#0066FF"
                    : "#0000FF",
                  margin: "0 10px",
                  padding: "5px 10px",
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                  fontSize: "16px",
                }}
              >
                {section === "disposition"
                  ? "Plan and Location"
                  : section === "calaim"
                  ? "CalAIM"
                  : section.charAt(0).toUpperCase() + section.slice(1)}
              </button>
            ))}
          </div>
          <div className="almostboxed">
            {displaySections.enrollment && (
              <Enrollment programNumber={parseInt(selectedProgram)} />
            )}

            {displaySections.legal && (
              <LegalStatus programNumber={parseInt(selectedProgram)} />
            )}
            {displaySections.medical && (
              <MedicalStatus programNumber={parseInt(selectedProgram)} />
            )}
            {displaySections.disposition && (
              <PlanAndLocation programNumber={parseInt(selectedProgram)} />
            )}
            {displaySections.notes && (
              <Notes programNumber={parseInt(selectedProgram)} />
            )}
            {displaySections.calaim && (
              <CalAIM
                odrNumber={parseInt(participant)}
                programNumber={parseInt(selectedProgram)}
              />
            )}
            {displaySections.notifications && (
              <Notifications programNumber={parseInt(selectedProgram)} />
            )}
          </div>
          {displaySections.incidents && (
            <Incidents programNumber={parseInt(selectedProgram)} />
          )}
        </>
      )}
    </div>
  );
};
