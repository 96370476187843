export const programNameFromID = (idstring: string): string => {
  const id = parseInt(idstring, 10);

  if (id < 20000000) return "MIST";
  if (id < 30000000) return "ODR";
  if (id < 40000000) return "PRG";
  if (id < 60000000) return "FIST";
  if (id < 70000000) return "DSH";
  if (id < 80000000) return "LEAD";

  throw new Error(`Invalid ID ${idstring}`);
};

export function isoDateToMDY(isoDate: string) {
  if (!isoDate) return "N/A";

  const date = new Date(isoDate);
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const day = date.getUTCDate().toString().padStart(2, "0");
  const year = date.getUTCFullYear();

  return `${month}/${day}/${year}`;
}
