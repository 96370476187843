export const ediHash = {
 /*   "isaAuthorizationInformationQualifier_1": {
      shortDescription: "Authorization Information Qualifier",
      longDescription: "Qualifier for the authorization information. Valid value: '00' (No Authorization Information Present)",
      location: "ISA01",
      validOptions: [
        {
          value: "00",
          description: "No Authorization Information Present"
        }
      ]
    },
    "isaAuthorizationInformation_2": {
      shortDescription: "Authorization Information",
      longDescription: "Authorization information. Valid value: '          ' (10 spaces)",
      location: "ISA02",
      validOptions: []
    },*/
    "isaInterchangeSenderID_6": {
      shortDescription: "Interchange Sender ID",
      longDescription: "ID code of the sender. Valid value: Any alphanumeric value (e.g., '1234567890')",
      location: "ISA06",
      validOptions: [] //text
    },
   /* "isaReceiverIDQualifier_7": {
      shortDescription: "Receiver ID Qualifier",
      longDescription: "Qualifier for the receiver ID. Valid value: 'ZZ' (Mutually Defined)",
      location: "ISA07",
      validOptions: [
        {
          value: "ZZ",
          description: "Mutually Defined"
        }
      ]
    },*/
    "isaInterchangeReceiverID_8": {
      shortDescription: "Interchange Receiver ID",
      longDescription: "ID code of the receiver. Valid value: Any alphanumeric value (e.g., '1234567890')",
      location: "ISA08",
      validOptions: [] //text
    },
  /*  "isaInterchangeControlStandardsIdentifier_11": {
      shortDescription: "Interchange Control Standards Identifier",
      longDescription: "Code indicating the agency controlling the specification, maintenance, and publication of the EDI standard. Valid value: 'U' (Accredited Standards Committee X12)",
      location: "ISA11",
      validOptions: [
        {
          value: "U",
          description: "Accredited Standards Committee X12"
        }
      ]
    },*/
   /* "isaInterchangeControlVersionNumber_12": {
      shortDescription: "Interchange Control Version Number",
      longDescription: "Version of the EDI standard being used. Valid value: '00501' (00501 for 837 Health Care Claim: Dental)",
      location: "ISA12",
      validOptions: [
        {
          value: "00501",
          description: "00501 for 837 Health Care Claim: Dental"
        } 
      ]
    },*/
    "isaInterchangeControlNumber_13": {
      shortDescription: "Interchange Control Number",
      longDescription: "Unique control number assigned by the sender to identify the interchange. Valid value: Any alphanumeric value (e.g., '244579')",
      location: "ISA13",
      validOptions: []
    },
   /* "isaAcknowledgementRequested_14": {
      shortDescription: "Acknowledgement Requested",
      longDescription: "Code indicating whether an interchange acknowledgment is requested or not. Valid value: '1' (Interchange Acknowledgment Requested)",
      location: "ISA14",
      validOptions: [
        {
          value: "1",
          description: "Interchange Acknowledgment Requested"
        }
      ]
    },*/
    "isaUsageIndicator_15": {
      shortDescription: "Usage Indicator",
      longDescription: "Code indicating the type of sender or receiver ID code. Valid value: 'T' (Test)",
      location: "ISA15",
      validOptions: [
        {
          value: "T",
          description: "Test"
        }
      ]
    },
  /*  "gsCodeIdentifyingInformationType_1": {
      shortDescription: "Code Identifying Information Type",
      longDescription: "Code identifying the type of information in GS02. Valid value: 'HC' (Health Care Claim)",
      location: "GS02",
      validOptions: [
        {
          value: "HC",
          description: "Health Care Claim"
        }
      ]
    },*/
    "gsSenderIDCode_2": {
      shortDescription: "Sender ID Code",
      longDescription: "ID code of the sender. Valid value: Any alphanumeric value (e.g., '1234567890')",
      location: "GS03"
    },
    "gsReceiverIDCode_3": {
      shortDescription: "Receiver ID Code",
      longDescription: "ID code of the receiver. Valid value: Any alphanumeric value (e.g., '1234567890')",
      location: "GS04"
    },
    "gsTransactionTypeCode_7": {
      shortDescription: "Transaction Type Code",
      longDescription: "Code indicating the type of transaction. Valid value: 'HC' (Health Care Claim)",
      location: "GS08"
    },

    /*"bhtSubmitterTransactionIdentifier_03": {
      shortDescription: "Submitter Transaction Identifier",
      longDescription: "Identifier assigned by the submitter to uniquely identify the transaction. Valid value: Any alphanumeric value (e.g., '244579')",
      location: "BHT03"
    },
    "nm1_SubmitterName_EntityIdentifierCode_01": {
      shortDescription: "Submitter Name Entity Identifier Code",
      longDescription: "Code indicating the type of entity. Valid value: '41' (Dental Pay-to Provider)",
      location: "NM101",
      validOptions: [
        {
          value: "41",
          description: "Dental Pay-to Provider"
        }
      ]
    },*/
    "nm1_SubmitterName_ResponseContactLastorOrganizationName_03": {
      shortDescription: "Submitter Name Response Contact Last or Organization Name",
      longDescription: "Last or organization name of the entity valid values are 'PREMIER BILLING SERVICE' and 'KEY INSURANCE COMPANY'",
      location: "NM103",
      validOptions: []
    },
    /*"nm1_SubmitterName_IdentificationCodeQualifier_08": {
      shortDescription: "Submitter Name Identification Code Qualifier",
      longDescription: "Qualifier for the identification code. Valid value: '46' (Electronic Transmitter Identification Number)",
      location: "NM108",
      validOptions: [
        {
          value: "46",
          description: "Electronic Transmitter Identification Number"
        }
      ]
    },*/
    "nm1_SubmitterName_ResponseContactIdentifier_09": {
      shortDescription: "Submitter Name Response Contact Identifier",
      longDescription: "Identifier associated with the electronic transmitter. This might be an Electronic Transmitter Identification Number (ETIN). Valid values are 'TGJ23' and '66783JJT'",
      location: "NM109",
      validOptions: []
    },
    /*"perSubmitterEDIContact_ContactFunctionCode_01": {
        shortDescription: "Submitter EDI Contact Contact Function Code",
        longDescription: "Code identifying the major duty or responsibility of the person or group named. Valid value: 'IC' (Information Contact)",
        location: "PER01",
        validOptions: [
          {
            value: "IC",
            description: "Information Contact"
          }
        ]
      },*/
      "perSubmitterEDIContact_ResponseContactName_02": {
        shortDescription: "Submitter EDI Contact Response Contact Name",
        longDescription: "Name of the person or group to be contacted. This might be the name of the submitter's EDI department. Valid values are 'JERRY' and 'SUZY'",
        location: "PER02",
        validOptions: []
      },
      /*"perSubmitterEDIContact_CommunicationNumberQualifier_03": {
        shortDescription: "Submitter EDI Contact Communication Number Qualifier",
        longDescription: "Qualifier for the communication number. Valid value: 'TE' (Telephone)",
        location: "PER03",
        validOptions: [ 
          {
            value: "TE",
            description: "Telephone"
          }
        ]
      },*/
      "perSubmitterEDIContact_ResponseContactCommunicationNumber_04": {
        shortDescription: "Submitter EDI Contact Response Contact Communication Number",
        longDescription: "Communication number for the person or group to be contacted. (Telephone)",
        location: "PER04"
      },
     /* "perSubmitterEDIContact_CommunicationNumberQualifier_05": {
        shortDescription: "Submitter EDI Contact Communication Number Qualifier",
        longDescription: "Qualifier for the communication number. Valid value: 'EX' (Telephone Extension)",
        location: "PER05"
      },*/
      "perSubmitterEDIContact_ResponseContactCommunicationNumber_06": {
        shortDescription: "Submitter EDI Contact Response Contact Communication Number",
        longDescription: "Communication number for the person or group to be contacted. Valid values are 'jerry@example' and 'suzy@example'",
        location: "PER06"
      },
   /*   "nm1ReceiverName_EntityIdentifierCode_01": {
        shortDescription: "Receiver Name Entity Identifier Code",
        longDescription: "Code indicating the type of entity. Valid value: '40' (Non-Person Entity)",
        location: "NM101"
      },
      "nm1ReceiverName_EntityTypeQualifier_02": {
        shortDescription: "Receiver Name Entity Type Qualifier",
        longDescription: "Qualifier indicating the category of the entity. Valid value: '2' (Non-Person Entity)",
        location: "NM102"
      },*/
      "nm1ReceiverName_ResponseContactLastorOrganizationName_03": {
        shortDescription: "Receiver Name Response Contact Last or Organization Name",
        longDescription: "Last or organization name of the entity valid values are 'KEY INSURANCE COMPANY' and 'PREMIER BILLING SERVICE'",
        location: "NM103"
      },
     /* "nm1ReceiverName_IdentificationCodeQualifier_08": {
        shortDescription: "Receiver Name Identification Code Qualifier",
        longDescription: "Qualifier for the identification code. Valid value: '46' (Electronic Transmitter Identification Number)",
        location: "NM108",
        validOptions: [
          {
            value: "46",
            description: "Electronic Transmitter Identification Number"
          }
        ]
      },*/
      "nm1ReceiverName_ResponseContactIdentifier_09": {
        shortDescription: "Receiver Name Response Contact Identifier",
        longDescription: "Identifier associated with the electronic transmitter.",
        location: "NM109",
        validOptions: []
      },
      /*"prv_BillingProviderSpecialty_ProviderCode_01": {
        shortDescription: "Billing Provider Specialty Provider Code",
        longDescription: "Code identifying the type of provider. Valid value: 'BI' (Billing)",
        location: "PRV01",
        validOptions: [
          {
            value: "BI",
            description: "Billing"
          }
        ]
      },*/
   /*   "prv_BillingProviderSpecialty_ReferenceIdentificationQualifier_02": {
        shortDescription: "Billing Provider Specialty Reference Identification Qualifier",
        longDescription: "Qualifier for the reference identification. Valid value: 'PXC' (Provider Taxonomy Code)",
        location: "PRV02",
        validOptions: [
          {
            value: "PXC",
            description: "Provider Taxonomy Code"
          }
        ]
      },*/
      "prv_BillingProviderSpecialty_ProviderTaxonomyCode_03": {
        shortDescription: "Billing Provider Specialty Provider Taxonomy Code",
        longDescription: "Taxonomy code for the provider valid values are '203BF0100Y' and '203BF0100X'",
        location: "PRV03"
      },
    /*  "billingProviderName_EntityIdentifierCode_01": {
        shortDescription: "Billing Provider Name Entity Identifier Code",
        longDescription: "Code indicating the type of entity. Valid value: '85' (Billing Provider)",
        location: "NM101",
        validOptions: [
          {
            value: "85",
            description: "Billing Provider"
          }
        ]
      },*/
  /*    "billingProviderName_EntityTypeQualifier_02": {
        shortDescription: "Billing Provider Name Entity Type Qualifier",
        longDescription: "Qualifier indicating the category of the entity. Valid value: '2' (Non-Person Entity)",
        location: "NM102"
      },*/
      "billingProviderName_ResponseContactLastorOrganizationName_03": {
        shortDescription: "Billing Provider Name Response Contact Last or Organization Name",
        longDescription: "Last or organization name of the entity valid values are 'KEY INSURANCE COMPANY' and 'PREMIER BILLING SERVICE'",
        location: "NM103"
      },
     /* "billingProviderName_IdentificationCodeQualifier_08": {
        shortDescription: "Billing Provider Name Identification Code Qualifier",
        longDescription: "Qualifier for the identification code. Valid value: 'XX' (Health Care Financing Administration National Provider Identifier)",
        location: "NM108",
        validOptions: [
          {
            value: "XX",
            description: "Health Care Financing Administration National Provider Identifier"
          } 
        ]
      },*/
      "billingProviderName_ResponseContactIdentifier_09": {
        shortDescription: "Billing Provider Name Response Contact Identifier",
        longDescription: "National Provider Identifier (NPI) for the provider valid values are '9876543210' and '1234567890'",
        location: "NM109",
        validOptions: []
      },

      "billingProviderAddress_ResponseContactAddressLine_01": {
        shortDescription: "Billing Provider Address Response Contact Address Line",
        longDescription: "Address of the provider valid values are '234 SEAWAY ST' and '236 N MAIN ST'",
        location: "N301",
        validOptions: []
      },

      "billingProviderAddressInformation_ContactCityName_01": {
        shortDescription: "Billing Provider Address Information Contact City Name",
        longDescription: "City name of the provider valid values are 'MIAMI' and 'MIAMI'",
        location: "N401",
        validOptions: []
      },
      "billingProviderAddressInformation_ContactStateCode_02": {
        shortDescription: "Billing Provider Address Information Contact State Code",
        longDescription: "State code of the provider valid values are 'FL' and 'FL'",
        location: "N402", 
        validOptions: []
      },
      "billingProviderAddressInformation_ContactPostalZoneorZIPCode_03": {
        shortDescription: "Billing Provider Address Information Contact Postal Zone or ZIP Code",
        longDescription: "ZIP code of the provider valid values are '33111' and '33112'",
        location: "N403",
        validOptions: []
      },
    /*  "ref_BillingProviderTaxId_ReferenceIdentificationQualifier_01": {
        shortDescription: "Billing Provider Tax ID Reference Identification Qualifier",
        longDescription: "Qualifier for the reference identification. Valid value: 'EI' (Employer's Identification Number)",
        location: "REF01"
      },*/
      "ref_BillingProviderTaxId_MemberGrouporPolicyNumber_02": {
        shortDescription: "Billing Provider Tax ID Tax Identification Number",
        longDescription: "Tax identification number for the provider valid values are '123456789' and '987654321'",
        location: "REF02"
      },
   /*   "perBillingProviderEDIContact_ContactFunctionCode_01": {
        shortDescription: "Billing Provider EDI Contact Contact Function Code",
        longDescription: "Code identifying the major duty or responsibility of the person or group named. Valid value: 'IC' (Information Contact)",
        location: "PER01",
        validOptions: [
          {
            value: "IC",
            description: "Information Contact"
          }
        ]
      },*/
      "payToAddressName_ResponseContactLastorOrganizationName_03": {
        shortDescription: "Billing Provider EDI Contact Response Contact Name",
        longDescription: "Name of the person or group to be contacted. This might be the name of the provider's EDI department. Valid values are 'JERRY' and 'SUZY'",
        location: "NM103"
      },
      "payToAddress_ADDRESS_ResponseContactAddressLine_01": {
        shortDescription: 'Address of the pay-to provider ',
        longDescription: "Address of the pay-to provider valid values are '236 N MAIN ST' and '1357 AMBER DR'",
        location: "N301"
      },
      "payToAddressCity_State_ZIPCode_AdditionalContactCityName_01" : {
        shortDescription: "City name of the pay-to provider",
        longDescription: "City name of the pay-to provider valid values are 'MIAMI' and 'MIAMI'",
        location: "N401"
      },
      "payToAddressCity_State_ZIPCode_AdditionalContactStateCode_02": {
        shortDescription: "State code of the pay-to provider",
        longDescription: "State code of the pay-to provider valid values are 'FL' and 'FL'",
        location: "N402"
      },
      payToAddressCity_State_ZIPCode_AdditionalContactZIPCode_03: {
        shortDescription: "ZIP code of the pay-to provider",
        longDescription: "ZIP code of the pay-to provider valid values are '33111' and '33112'",
        location: "N403"
      },

  }; 
