import React, { useState, useEffect } from 'react';

import "./formRender.css"

import { FormValidator } from './validate';
import { renderChildren } from './formRenderReadOnly'

const columnsCSS = {
  "2" : 'columns2',
  "3" : 'columns3',
} as any


const renderChildrenReadOnly = (formTemplate: any, formState: any,  depth = 0 ) => {
  
  
  return formTemplate.map((item: any, index: number) => {
    
    if (item.type === 'section'){
      const parent= (depth) ? '' : 'parent'
      const css= (columnsCSS[item.columns]) || ''

      return (
        <div key={`template${index}`} className={`formBlock ${parent}`}>          
          <h2>{item.text||item.label}</h2>
          <div className={css}>
          {renderChildrenReadOnly(item.children, formState, 1)}
          </div>
        </div>
      )
    }
    
          
    if (item.type === 'text') 
      return <div key={index}>{item.text}</div>

      if (item.type === 'bullet') 
      return <li key={index}>{item.text}</li>

      if (item.type === 'bulletList') {
        return (
          <ul>
            {item.items.map((item:any, index:any)=>{
              return <li key={`items${index}`}>{item.text}</li>      
            })}
          </ul>
        )            
      }

    if (item.type === 'paragraph') {
      return <p key={index}>{item.text}</p>
    }

    if (item.type === 'heading') {
        return <h1 key={index}>{item.text}</h1>
    }


      if (item.type === 'textInput') {
            return (
              <div key={index} className='inputBlock' >
                <label id={`label${item.key}`} className='label' htmlFor={item.key}>{item.label}</label>
                <div>
                  <span className='bold'>{formState[item.key]}</span>                  
                </div>
              </div>
            )
        }

        if (item.type === 'staticInput') {
          return (
            <div key={index} className='inputBlock' >
              <label className='label' htmlFor={item.key}>{item.label}</label>
              <div>
              <span>{formState[item.key]}</span>                  
              </div>
            </div>
          )
      }

        if (item.type === 'textAreaInput') {
          return (
            <div key={index} className='inputBlock'>
              <label className='label' htmlFor={item.key}>{item.label}</label>
              <div>
              <span className='bold' >{formState[item.key]}</span>                  
              </div>
            </div>
          )
      }

        if (item.type === 'yesnoInput') {
            return (
                <div key={index} className='inputBlock'>
                    
                    <label  className='label' htmlFor={item.key}>{item.label}</label>
                    <span  className='bold' >{(formState[item.key])? "YES " :"NO "}</span>                  
                </div>
            )
        }

        if (item.type === 'listInput') {
          return (
            <div key={index} className='inputBlock'>
              <label htmlFor={`label${item.key}`}>{item.label}</label>
           
              {item.options.map((option: any, optionIndex: number) => {
                if (formState[item.key] == option.value) 
                  return (
                    <React.Fragment key={optionIndex}>                    
                      <div className='bold label' >{option.label}</div>
                    </React.Fragment>
                  )
              })}
            </div>
          )

        }


        if (item.type === 'dateInput') {
            return (
                <div key={index} className='inputBlock'>
                    <label htmlFor={item.key}>{item.label}</label>
                    <span className='bold' >{formState[item.key]}</span>                  
                </div>
            )
        }
    
    
    return <React.Fragment key={index}/>;
  })
}




export const FormView = (params: any) => {
  const { formTemplate, formState, print } = params;

  // Style for the overlay when print is true
  const overlayStyle = print
    ? {
        position: 'fixed',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
        backgroundColor: 'transparent', // Transparent background
        zIndex: '9999', // High z-index to cover everything
      }
    : {} as any; 

  // Style for the form container
  const formContainerStyle = {
    ...(print
      ? {
          position: 'fixed',
          top: '0',
          right: '0',
          bottom: '0',
          left: '0',
          backgroundColor: 'white', // Background of the popup
          zIndex: '10000', // One layer above the overlay
        }
      : {} as any),
  };

  return (
    <div style={overlayStyle}>
      <div style={formContainerStyle} className={print ? '' : 'boxed'}>
        {(print ) ? renderChildrenReadOnly(formTemplate, formState) : renderChildren(formTemplate, formState, print,()=>{}, 0)}
      </div>
    </div>
  );
};
  

