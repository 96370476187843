import React, { useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import { useUser } from "../../store/useUser";
import ReactModal from "react-modal";
import CalAimDemographics from "./components/demographics";
import { CalAimForms } from "./components/calAIMForms";
import { EditCalAIM } from "./modals/EditCalAIM";
import { useSetHelpTopic } from "../../store/useHelpTopics";

const GET_CALAIM_DATA = gql`
  query GetCalAIMData($programNumber: Integer!) {
    getParticipantProgramDataByProgramNumber(programNumber: $programNumber) {
      ssn
      cin
      mrun
      fin
      cii
      healthplanProvider
      pcpname
      healthplan {
        clinicempaneled
        dhs_empaneled
        ecm_assignment_date
        ecmmanager
        ecmprovider
        healthplan
        healthplanabbr
        healthplancode
        pcpname
      }
      billingEvents {
        entryBy
        id
        posCode
        procedureCode
        procedureModifier
        procedureModifier1
        procedureModifier2
        procedureType
        serviceStart
        serviceUnits
        timespentNote
      }
      initialAssessmentDate
      carePlanDate
      programCompletionDate
      wpc
      acuity
    }
  }
`;

const UPDATE_PARTICIPANT_PROGRAM_DATA = gql`
  mutation updateParticipantProgramDataByProgramNumber(
    $programNumber: Integer!
    $updates: participantProgramDataInput
  ) {
    updateParticipantProgramDataByProgramNumber(
      programNumber: $programNumber
      updates: $updates
    ) {
      responseCode
      message
    }
  }
`;

const GET_HEALTHPLAN_PROVIDERS = gql`
  query GetHealthPlanProviders {
    getHealthPlanProviders {
      providerId
      providerName
    }
  }
`;

export const CalAIM = ({
  odrNumber,
  programNumber,
}: {
  odrNumber: number;
  programNumber: number;
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [selectedCalAIM, setSelectedCalAIM] = React.useState(null);

  const { data: healthPlanProvidersDataResult } = useQuery(GET_HEALTHPLAN_PROVIDERS);

  const healthPlanProvidersData = healthPlanProvidersDataResult?.getHealthPlanProviders || [];


  const { user } = useUser();
  const isAdmin = user.groups?.includes("admin");

  const { loading, error, data, refetch } = useQuery(GET_CALAIM_DATA, {
    variables: { programNumber },
    skip: !programNumber,
  });

  const [updateParticipantProgramData] = useMutation(
    UPDATE_PARTICIPANT_PROGRAM_DATA
  );

  useSetHelpTopic("ParticipantsCalAIM");

  const [newNoteModalOpen, setNewNoteModalOpen] = React.useState(false);

  const [formData, setFormData] = React.useState({
    calaim_pos: "",
    proceduremod1: "",
    proceduremod2: "",
    proceduremod3: "",
    proceduretype: "",
    procedurelength: "",
    note_entry_date: "",
    procedurenote: "",
    procedureentery: "",
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditClick = (calaimNote: any) => {
    setSelectedCalAIM(calaimNote);
    setIsEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
    setSelectedCalAIM(null);
  };

  const handleUpdateCalAIM = async (formData: any) => {
    try {
      const result = await updateParticipantProgramData({
        variables: {
          programNumber: programNumber,
          updates: {
            billingEvents: [
              {
                posCode: formData.calaim_pos,
                procedureModifier1: formData.proceduremod1,
                procedureModifier2: formData.proceduremod2,
                outreach: formData.proceduremod3,
                procedureType: formData.proceduretype,
                serviceUnits: parseInt(formData.procedurelength),
                serviceStart: formData.note_entry_date,
                timespentNote: formData.procedurenote,
                entryBy: formData.entryBy,
              },
            ],
          },
        },
      });

      const response = result.data.updateParticipantProgramDataByProgramNumber;

      if (response.responseCode === "SUCCESS") {
        console.log("Update successful:", response.message);
        handleCloseModal();
        // Optionally, refetch the data or update the local state
      } else {
        console.error("Update failed:", response.message);
        // Handle the error, maybe show an error message to the user
      }
    } catch (error) {
      console.error("Error updating CalAIM:", error);
      // Handle the error, maybe show an error message to the user
    }
  };

  if (loading) return <p>Loading CalAIM data...</p>;
  if (error) return <p>Error loading CalAIM data :(</p>;
  if (!data) return null;

  const calaimData = data.getParticipantProgramDataByProgramNumber;

  const acuitys = {
    1: "Low",
    2: "Moderate",
    4: "High",
  } as any;

  return (
    <div>
      <hr />
      <div style={{ display: "grid", gridTemplateColumns: "20rem 5rem 1fr" }}>
        <div style={{ marginRight: "10rem" }}>
          <h4>ECM Acuity</h4>
          <div>
            {acuitys[calaimData.acuity] || "Unknown"}
            <hr />
          </div>
          
          <CalAimForms calaimData={calaimData} odrNumber={odrNumber} />
        </div>
        <div></div>
        {calaimData && (
          <CalAimDemographics calaimData={calaimData} programNumber={programNumber} healthPlanProviders={healthPlanProvidersData} />
        )}

        <div></div>
      </div>
      <hr />
      {isAdmin && (
        <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h4>ECM CalAIM Notes</h4>

            <div>
              <button onClick={() => setNewNoteModalOpen(true)}>
                Add Note
              </button>
            </div>
          </div>
          <ReactModal
            isOpen={newNoteModalOpen}
            onRequestClose={() => setNewNoteModalOpen(false)}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div></div>
              <div>
                <button onClick={() => setNewNoteModalOpen(false)}>
                  Close
                </button>
              </div>
            </div>
            <form onSubmit={handleUpdateCalAIM}>
              <input
                type="hidden"
                name="enterer"
                value={user.fullname}
                id="add_calaim_fullname"
                readOnly
              />
              <input
                type="hidden"
                name="fin"
                value={calaimData.fin}
                id="calaim_fin"
              />
              <input
                type="hidden"
                name="lob"
                value={calaimData.lobname}
                id="calaim_fin"
              />
              <input type="hidden" name="odrnumber" value={odrNumber} />

              <div>
                <label htmlFor="calaim_pos">Place of Service</label>
                <select
                  name="calaim_pos"
                  id="calaim_pos"
                  value={formData.calaim_pos}
                  onChange={handleInputChange}
                >
                  <option value="0">-- Select an option --</option>
                  <option value="12">Home</option>
                  <option value="4">Homeless Shelter</option>
                  <option value="10">Telehealth provided in pts home</option>
                  <option value="2">
                    Telehealth provided other than in pts home
                  </option>
                  <option value="99">Other Place of Service</option>
                  <option value="13">Assisted Living Facility</option>
                  <option value="31">Skilled Nursing Facility</option>
                  <option value="23">Emergency Room - Hospital</option>
                  <option value="20">Urgent Care Facility</option>
                  <option value="11">Office</option>
                  <option value="14">Group Home</option>
                  <option value="16">Temporary Lodging</option>
                  <option value="17">Walk-in Retail Health Clinic</option>
                  <option value="18">Place of Employment- Worksite</option>
                  <option value="19">Off Campus-Outpatient Hospital</option>
                  <option value="21">Inpatient Hospital</option>
                  <option value="22">On Campus-Outpatient Hospital</option>
                  <option value="24">Ambulatory Surgical Center</option>
                  <option value="32">Nursing Facility</option>
                  <option value="33">Custodial Care Facility</option>
                  <option value="34">Hospice</option>
                  <option value="49">Independent Clinic</option>
                  <option value="50">Federally Qualified Health Center</option>
                  <option value="51">Inpatient Psychiatric Facility</option>
                  <option value="52">
                    Psychiatric Facility - Partial Hospitalization
                  </option>
                  <option value="53">Community Mental Health Center</option>
                  <option value="55">
                    Residential Substance Abuse Treatment Facility
                  </option>
                  <option value="56">
                    Psychiatric Residential Treatment Center
                  </option>
                  <option value="57">
                    Non-residential Substance Abuse Treatment Facility
                  </option>
                  <option value="71">Public Health Clinic</option>
                </select>
              </div>

              <div>
                <label>Procedure Modifier</label>
                <div>
                  <input
                    type="radio"
                    name="proceduremod1"
                    value="1"
                    checked={formData.proceduremod1 === "1"}
                    onChange={handleInputChange}
                  />
                  <label>In Person</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="proceduremod1"
                    value="0"
                    checked={formData.proceduremod1 === "0"}
                    onChange={handleInputChange}
                  />
                  <label>Telehealth</label>
                </div>

                <div>
                  <input
                    type="radio"
                    name="proceduremod2"
                    value="1"
                    checked={formData.proceduremod2 === "1"}
                    onChange={handleInputChange}
                  />
                  <label>Clinical</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="proceduremod2"
                    value="0"
                    checked={formData.proceduremod2 === "0"}
                    onChange={handleInputChange}
                  />
                  <label>NonClinical</label>
                </div>

                <div>
                  <input
                    type="radio"
                    name="proceduremod3"
                    value="0"
                    checked={formData.proceduremod3 === "0"}
                    onChange={handleInputChange}
                  />
                  <label>Outreach</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="proceduremod3"
                    value="1"
                    checked={formData.proceduremod3 === "1"}
                    onChange={handleInputChange}
                  />
                  <label>Care</label>
                </div>
              </div>

              <div>
                <label htmlFor="proceduretype">Procedure Type</label>
                <select
                  name="proceduretype"
                  id="calaim_careprovider"
                  value={formData.proceduretype}
                  onChange={handleInputChange}
                >
                  <option value="">-- Select an option --</option>
                  <option value="Initial Assessment-first 90 days">
                    Initial Assessment-first 90 days
                  </option>
                  <option value="Care Plan-every quarter or more">
                    Care Plan-every quarter or more
                  </option>
                  <option value="Client Contact">Client Contact</option>
                  <option value="Client Outreach">Client Outreach</option>
                  <option value="Care Coordination">Care Coordination</option>
                </select>
              </div>

              <div>
                <label htmlFor="procedurelength">Procedure Length</label>
                <select
                  name="procedurelength"
                  id="calaim_caremanager"
                  value={formData.procedurelength}
                  onChange={handleInputChange}
                >
                  <option value="15">15 mins</option>
                  <option value="30">30 mins</option>
                  <option value="45">45 mins</option>
                  <option value="60">60 mins</option>
                  <option value="75">1hour 15mins</option>
                  <option value="90">1hour 30mins</option>
                  <option value="105">1hour 45mins</option>
                  <option value="120">2hours</option>
                  <option value="150">2hours 30mins</option>
                  <option value="180">3hours</option>
                  <option value="210">3hours 30mins</option>
                  <option value="240">4hours</option>
                </select>
              </div>

              <div>
                <label htmlFor="note_entry_date">Note Date</label>
                <input
                  type="text"
                  name="note_entry_date"
                  id="calaim_note_entry_date"
                  required
                  value={formData.note_entry_date}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <label htmlFor="procedurenote">Procedure Note:</label>
                <textarea
                  name="procedurenote"
                  value={formData.procedurenote}
                  onChange={handleInputChange}
                ></textarea>
              </div>

              <div>
                <label htmlFor="procedureentery">Entered By:</label>
                <input
                  name="procedureentery"
                  value={formData.procedureentery}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <input type="submit" value="Add" />
              </div>
            </form>
          </ReactModal>

          <table
            border={0}
            align="center"
            cellPadding={5}
            width="100%"
            rules="rows"
          >
            <thead>
              <tr>
                <th>Place of Service</th>
                <th>Date</th>
                <th>Procedure Code</th>
                <th>Procedure Modifier</th>
                <th>Procedure Type</th>
                <th>Procedure Length</th>
                <th>Procedure Notes</th>
                <th>Entered By</th>
              </tr>
            </thead>
            <tbody>
              {calaimData.billingEvents.map((event: any) => (
                <tr key={event.id}>
                  <td>{event.posCode}</td>
                  <td>{new Date(event.serviceStart).toLocaleDateString()}</td>
                  <td>{event.procedureCode}</td>
                  <td>{`${event.procedureModifier}${
                    event.procedureModifier1
                      ? `, ${event.procedureModifier1}`
                      : ""
                  }${
                    event.procedureModifier2
                      ? `, ${event.procedureModifier2}`
                      : ""
                  }`}</td>
                  <td>{event.procedureType}</td>
                  <td>{event.serviceUnits}</td>
                  <td>{event.timespentNote}</td>
                  <td>{event.entryBy}</td>
                  <td>
                    <button onClick={() => handleEditClick(event)}>Edit</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}{" "}
      <EditCalAIM
        isOpen={isEditModalOpen}
        onClose={handleCloseModal}
        calaimData={selectedCalAIM}
        user={user}
        onUpdate={handleUpdateCalAIM}
      />
    </div>
  );
};
