// LacareDashboard.js
import React from 'react';
import { useParams, useNavigate, Navigate  } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { toast } from 'react-toastify';

import './formRender.css'


const DateDisplay =  ({ value }:any) => {
    if (!value) {
        return <React.Fragment>None</React.Fragment>;
    }
    
    // Convert the provided date string to a Date object
    const date = new Date(value);
  
    // Extract the individual date components
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-based
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
  
    // Create the formatted date string
    const formattedDate = `${month}/${day}/${year}`;
  
    return <React.Fragment>{formattedDate}</React.Fragment>;
  };


const GET_PARTICIPANT_BY_ODR_NUMBER = gql`
query GetParticipantByODRNumber($odrnumber: Integer!) {
  getParticipantByODRNumber(odrnumber: $odrnumber) {  
    lastProgramCompletionDate {date id}
    lastCarePlanDate {date id}
    lastInitialAssessmentDate {date id}
    lastLACAREScreeningDate {date id}
    lastLACAREReferralDate {date id}
  }
}
`;

export const LacareDashboard = () => {
    const { odrparticipant } = useParams() as any;      


    if (!odrparticipant) {        
        return <div>No ODR Participant number provided.</div>;
      }

    return <LacareDashboardGewd odrparticipant={odrparticipant}/>
}

export const LacareDashboardGewd = (params:any) => {
  const { odrparticipant } = params
  const navigate = useNavigate();
  

  const { loading, error, data } = useQuery(GET_PARTICIPANT_BY_ODR_NUMBER, {
    variables: { odrnumber: parseInt(odrparticipant), skip: !odrparticipant }, // Assuming odrparticipant is a number
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const participant = data.getParticipantByODRNumber;
  
    const lastProgramCompletionDate = (participant.lastProgramCompletionDate) ? participant.lastProgramCompletionDate : {date: null, id: null};
    const lastCarePlanDate = (participant.lastCarePlanDate) ? participant.lastCarePlanDate : {date: null, id: null};
    const lastInitialAssessmentDate = (participant.lastInitialAssessmentDate) ? participant.lastInitialAssessmentDate : {date: null, id: null};
  const lastLACAREReferralDate = (participant.lastLACAREReferralDate) ? participant.lastLACAREReferralDate : {date: null, id: null};
  const lastLACAREScreeningDate = (participant.lastLACAREScreeningDate) ? participant.lastLACAREScreeningDate : {date: null, id: null};

  return (
    <div>
      <h1>LACARE Participant Dashboard</h1>
      
        <div className='section'>

      <p>Last Program Completion Date:
        <DateDisplay value={lastProgramCompletionDate.date}/>
        </p>
    
      <p>
        Last Care Plan Date:
      <DateDisplay value={lastCarePlanDate.date}/>
      </p>
      
      <p>
         Last Initial Assessment Date:
        <DateDisplay value={lastInitialAssessmentDate.date}/>
      </p>
      
      
      {participant.lastLACAREReferralDate.id && <p>
        Last LACARE Referral Date:
        <span className='link' onClick={()=>{
            navigate(`/CalAIM/forms/ecmform/${participant.lastLACAREReferralDate.id}`)
        }}><DateDisplay value={participant.lastLACAREReferralDate && participant.lastLACAREReferralDate.date} /></span>
        
      </p>}

      <button onClick={()=>{
            navigate(`/CalAIM/forms/ecmform/create/${odrparticipant}`)
        }}>New Referral Form</button>
      
      </div>

    </div>
  );
};
