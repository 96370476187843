// ParticipantSearchPage.tsx
import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client'; // Import useLazyQuery

import { Link } from 'react-router-dom';
import { useParams, useNavigate, Navigate  } from 'react-router-dom';
import { useHelpTopics } from '../../store/useHelpTopics';


import { gql } from '@apollo/client';
//import the css file
import './searchParticipants.css';


const SEARCH_PARTICIPANTS= gql`
        query searchParticipants($search: String! ) {
          searchParticipants(search: $search) {
            id
            nameHistory {
              firstName
              lastName
            }
            programs
            gender 
            mrun 
            fin 
            cid 
            
           
          }
        }
      `



export const settingSearch = {
  withoutSearch: true,
}

const programSquare =(idstring:string)=>{
        
    const id=parseInt(idstring)
    
    if (id < 20000000) //19999999 and below
        return <div className='programSquareContainer'><span className='programSquare mist'>MIST</span><div>{idstring}</div></div>
    if (id < 30000000) //29999999 and below
        return <div className='programSquareContainer'><span className='programSquare zodr'>ODR</span><div>{idstring}</div></div>
    if (id < 40000000) //39999999 and below
        return <div className='programSquareContainer'><span className='programSquare prg_wmn'>MIST</span><div>{idstring}</div></div>
    if (id < 60000000) //59999999 and below
        return <div className='programSquareContainer'><span className='programSquare fist'>FIST</span><div>{idstring}</div></div>
    if (id < 70000000) //69999999 and below
        return <div className='programSquareContainer'><span className='programSquare dsh'>DSH</span><div>{idstring}</div></div>
    if (id < 80000000) //79999999 and below
        return <div className='programSquareContainer'><span className='programSquare lead'>LEAD</span><div>{idstring}</div></div>
    // anyone else is ODR Range we dont do that here

    return <div></div>    
}


/*const selectionOptions = {
  genderChecked: 'Gender',
  mrunChecked: 'MRUN',
  finChecked: 'FIN',
  cinChecked: 'CID',  
}as any*/

export const SearchParticipants = (params:any) => {
    const { onClose , onSelect }    = params;
    
    const {pushHelpTopic, popHelpTopic} = useHelpTopics();
  
  useEffect(() => {
    pushHelpTopic('Search');
    return () => {
      popHelpTopic();
    };
  }, []);

    const [searchInput, setSearchInput] = useState<string>('');
    const [searchResults, setSearchResults] = useState([]) as any;

  
  
    // Load checkbox states from localStorage or use defaults
    const cookieSearchBoxState = JSON.parse(localStorage.getItem('searchBoxState') || '{}');
    
    const defaultCheckboxStates = {
      genderChecked: true,
      mrunChecked: true,
      finChecked: true,
      cinChecked: true,
      icd10Checked: false,
      careplanChecked: false,
      initialAssessmentChecked: false,
      programCompletionChecked: false,
      lastLACAREReferralDateChecked: false,
      lastLACAREScreeningDateChecked: false,
      
    } as any

    Object.keys(defaultCheckboxStates).forEach((checkboxName) => {
      if (cookieSearchBoxState[checkboxName] === undefined) {
        cookieSearchBoxState[checkboxName] = defaultCheckboxStates[checkboxName];
      }
      }
    );
    
    const [checkboxStates, setCheckboxStates] = useState( cookieSearchBoxState );
      const [searchParticipants, { loading, data, error }] = useLazyQuery(SEARCH_PARTICIPANTS );
  
    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchInput(event.target.value);
    };
  
    const handleSearchClick = () => {
      searchParticipants({
        variables: {
          search: searchInput,
          includeGender: checkboxStates.genderChecked,
          includeMrun: checkboxStates.mrunChecked,
          includeFin: checkboxStates.finChecked,
          includeCin: checkboxStates.cinChecked,
          includeIcd10: checkboxStates.icd10Checked,
          includeProgramCompletion: checkboxStates.programCompletionChecked,
          includeCarePlan: checkboxStates.careplanChecked,
          includeInitialAssessment: checkboxStates.initialAssessmentChecked,
          includeLACAREReferralDate: checkboxStates.lastLACAREReferralDateChecked,
          includeLACAREScreeningDate: checkboxStates.lastLACAREScreeningDateChecked,

        },
      });
    };
  
    // When the data is available, update the search results
    useEffect(() => {
      if (data && data.searchParticipants) {
        setSearchResults(data.searchParticipants);
      }
    }, [data]);
  
    const updateCheckboxState = (checkboxName: keyof typeof checkboxStates) => {
      setCheckboxStates((prevState:any) => {
        const updatedState = {
          ...prevState,
          [checkboxName]: !prevState[checkboxName],
        };
  
        // Save the updated state to localStorage
        localStorage.setItem('searchBoxState', JSON.stringify(updatedState));
  
        return updatedState;
      });
    };
  return (
    <div>
      <header className='header'>
        <div>
            <h1>Participant Search</h1>
            <form onSubmit={(e) => { e.preventDefault(); handleSearchClick(); }}>
            <input
            type="text"
            placeholder="Search by name, CID, MRUN, or FIN"
            value={searchInput}
            onChange={handleSearchInputChange}
            />
            <button onClick={handleSearchClick}>Search</button>
          </form>
        </div>
        
      </header>

      <div>
        <h2>Search Results:</h2>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div>
            <div className='columns6 boxed'>
                <div>Names</div>
                <div>Gender</div>
                <div>Programs</div>
                <div>MRUN</div>
                <div>FIN</div>
                <div>ChampID</div>                
            </div>
            {searchResults.map((result:any) =>  {
               const lastProgramCompletionDate = (result.lastProgramCompletionDate) ? result.lastProgramCompletionDate : {date: null, id: null};
               const lastCarePlanDate = (result.lastCarePlanDate) ? result.lastCarePlanDate : {date: null, id: null};
               const lastInitialAssessmentDate = (result.lastInitialAssessmentDate) ? result.lastInitialAssessmentDate : {date: null, id: null};
             const lastLACAREReferralDate = (result.lastLACAREReferralDate) ? result.lastLACAREReferralDate : {date: null, id: null};
             const lastLACAREScreeningDate = (result.lastLACAREScreeningDate) ? result.lastLACAREScreeningDate : {date: null, id: null};

             const odrparticipant = result.id
              
              return (
              <div className='boxed' onClick={()=>{onSelect (result)}}>
              <div className='columns6'  key={result.id}>
                {/*<Link to={`/participants/${result.id}`}>*/}
                {result.nameHistory && result.nameHistory.map((name:any, index:number) => {
                    return (
                        <div key={index}>
                        {name.firstName} {name.lastName}
                        </div>
                    );

                }   
                )}   
                {/*</Link>           */}
                <div>{result.gender}</div>
                <div>{result.programs && result.programs.map((p:any)=>{return programSquare(p)})}</div>
                <div>{result.mrun}</div>
                <div>{result.fin}</div>
                <div>{result.cid}</div>                
                <div>



                </div>
              
           
            </div>



    

            </div>              
            )})}
          </div>
        )}
      </div>


 
    </div>
  );
};