import React from "react";
import Modal from "react-modal";
import { gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import {useUser} from "../../../store/useUser"

const ADD_NOTE = gql`
  mutation AddNote($participantID: Integer!, $input: ProgramDataNotesInput) {
    createProgramNote(participantID: $participantID, input: $input) {
      message
    }
  }
`;

export const CreateNote = ({onClose, programNumber, refetch }:any) => {
    const {user} = useUser()
    console.log(user)
    const [popupState, setPopupState] = React.useState({
        notesBy: user.realname,
        notesDate: new Date().toISOString(),
        note: ""
    })    
    const [addNote] = useMutation(ADD_NOTE);

    const handleAddNote = () => {
        addNote({variables: {participantID: programNumber, input: {notes: popupState.note, notesBy: popupState.notesBy, notesDate: new Date(popupState.notesDate).toISOString()}}})
        refetch()
        onClose()
        toast.success("Note added successfully")
        setPopupState({...popupState, note: "", notesBy: user.realname, notesDate: new Date().toISOString()})
      }

      

    return (<Modal isOpen={true} onRequestClose={() => onClose}>
          <div style={{display: "grid", gridTemplateColumns: "90% 1fr"}}>
            <h2>Add Note</h2>
            <div><button onClick={() => onClose()}>Close</button></div>
          </div>
          <div>
          <div style={{display: "grid", gridTemplateColumns: "10rem 10rem", gap: "5rem"}}>
              <input value={popupState.notesBy} onChange={(e) => setPopupState({...popupState, notesBy: e.target.value})}></input>
              <input type="date" value={popupState.notesDate} onChange={(e) => setPopupState({...popupState, notesDate: e.target.value})} />
             </div>
            <textarea value={popupState.note} onChange={(e) => setPopupState({...popupState, note: e.target.value})}></textarea>
          </div>
          <div>
            <button onClick={() => handleAddNote()}>Add Note</button>
          </div>
        </Modal>) 
  } 
