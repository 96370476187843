import { useContext } from 'react';
import { StoreContext } from '../storeProvider'; 
import { AuthContext } from '../authenticationProvider'; 



export const useUser = () => {
    const {setAuthenticationState } = useContext(AuthContext) as any;
    const {user, setUser, isUserLoading, setIsUserLoading} = useContext(StoreContext)

    const logout = () => {
        setAuthenticationState({});
        setUser({});
    }

    return {user, setUser, isUserLoading, setIsUserLoading, logout};
}