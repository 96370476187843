import { useContext } from 'react';
import { StoreContext } from '../storeProvider';

export const useHelpSidebarVisibility = () => {
    const { isHelpSidebarVisible, showHelpSidebar, hideHelpSidebar } = useContext(StoreContext) as any;

    const toggleHelpSidebar = () => {
        if (isHelpSidebarVisible) {
            hideHelpSidebar();
        } else {
            showHelpSidebar();
        }
    }
    return { isHelpSidebarVisible, toggleHelpSidebar, showHelpSidebar, hideHelpSidebar };
}

