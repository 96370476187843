import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { EditMedical } from "./modals/EditMedical";

const GET_MEDICAL_STATUS_DATA = gql`
  query GetMedicalStatusData($programNumber: Integer!) {
    getParticipantProgramDataByProgramNumber(programNumber: $programNumber) {
      fin
      icd10
      medicalProblems
      psychotropicMeds
      otherMeds
      clinicalPresentation
      psychHistory
      currentMeds
      substanceHistory
      bookingNumber
      mainfour
      dsm5dx
    }
  }
`;

const GET_ICD10_CODES = gql`
  query GetIcd10Codes {
    getICD10Codes {
      icd10
      icd10Description
    }
  }
`;

const style = {
  marginBottom: "1rem",
  border: "1px solid #ccc",
  borderRadius: "1px",
  padding: "0.5rem",
}

export const MedicalStatus = ({ programNumber }: { programNumber: number }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { loading, error, data, refetch } = useQuery(GET_MEDICAL_STATUS_DATA, {
    variables: { programNumber },
    skip: !programNumber,
    fetchPolicy: "network-only",
  });

  const icd10Options = React.useRef([]);
  const icd10OptionsHash = React.useRef({}) as any

  const {
    data: icd10Data,
    loading: icd10Loading,
    error: icd10Error,
  } = useQuery(GET_ICD10_CODES,
    {
      skip: icd10Options.current.length > 0,
      onCompleted: (data) => {
        icd10Options.current = data.getICD10Codes.map((code: any) => {
          icd10OptionsHash.current[code.icd10] = code.icd10Description;
          return {
            value: code.icd10,
            label: `${code.icd10} - ${code.icd10Description}`,
          };
        });
      }
    }
  );
  

  if (icd10Loading) return <p>Loading ICD10 codes...</p>;
  if (icd10Error) return <p>Error loading ICD10 codes: {icd10Error.message}</p>;

  if (loading) return <p>Loading medical status data...</p>;
  if (error) return <p>Error loading medical status data :(</p>;
  if (!data) return null;

  const medicalData = data.getParticipantProgramDataByProgramNumber;

  const handleEditClick = () => {
    setIsEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
  };

  return (
    <div>
      <h3>
        Medical Information{" "}
        <button style={{ marginLeft: "1rem" }} onClick={handleEditClick}>
          <div style={{ fontSize: "smaller" }}>Edit</div>
        </button>
      </h3>

      <div style = {style}>
        <b>CHS FIN #</b>
        <div>
          {medicalData.bookingNumber}
          {medicalData.mainfour}
        </div>
      </div>

      <div style = {style}>
        <b>ICD10 Codes</b>
        {medicalData.icd10 ? (
          medicalData.icd10.map((code: any) => <p>{code} - {icd10OptionsHash.current[code]} </p>)
        ) : (
          <p>No ICD10 Codes added yet</p>
        )}
      </div>

      {medicalData.dsm5dx && (
        <div style = {style}>
          <b>DSH Primary Diagnosis: </b>

          <p>{medicalData.dsm5dx}</p>
        </div>
      )}

      <div style = {style}>
        <h4>Medical problems</h4>
        {medicalData.medicalProblems ? (
          <p>
            {" "}
            <div
              dangerouslySetInnerHTML={{ __html: medicalData.medicalProblems }}
            />
          </p>
        ) : (
          <p>No medical problems recorded for this person yet</p>
        )}
      </div>

      <div style = {style}>
        <h4>Psychotropic medications</h4>
        {medicalData.psychotropicMeds ? (
          <p>
            {" "}
            <div
              dangerouslySetInnerHTML={{ __html: medicalData.psychotropicMeds }}
            />
          </p>
        ) : (
          <p>No psychotropic medications recorded for this person yet</p>
        )}
      </div>

      <div style = {style}>
        <h4>Other medications</h4>
        {medicalData.otherMeds ? (
          <p>{medicalData.otherMeds}</p>
        ) : (
          <p>No other medications recorded for this person yet</p>
        )}
      </div>

      <div style = {style}>
        <h4>Current medications</h4>
        {medicalData.currentMeds ? (
          <p>{medicalData.currentMeds}</p>
        ) : (
          <p>No current medications recorded for this person yet</p>
        )}
      </div>

      <div style = {style}>
        <h4>Clinical presentation and competency</h4>
        {medicalData.clinicalPresentation ? (
          <p>
            {" "}
            <div
              dangerouslySetInnerHTML={{
                __html: medicalData.clinicalPresentation,
              }}
            />
          </p>
        ) : (
          <p>
            No clinical presentation or competency information recorded for this
            person yet
          </p>
        )}
      </div>

      <div style = {style}>
        <h4>Brief psychiatric hx</h4>
        {medicalData.psychHistory ? (
          <p>
            {" "}
            <div
              dangerouslySetInnerHTML={{ __html: medicalData.psychHistory }}
            />
          </p>
        ) : (
          <p>No psychiatric history information recorded for this person yet</p>
        )}
      </div>

      <div style = {style}>
        <h4>Substance hx</h4>
        {medicalData.substanceHistory ? (
          <p>{medicalData.substanceHistory}</p>
        ) : (
          <p>
            No substance use history information recorded for this person yet
          </p>
        )}
      </div>
      <EditMedical
        refetch={refetch}
        isOpen={isEditModalOpen}
        onClose={handleCloseModal}
        programNumber={programNumber}
        medicalData={medicalData}
        icd10Options={icd10Options.current}
      />
    </div>
  );
};
