import React, { useState, useEffect } from 'react';

import "./formRender.css"
import { useMutation, gql } from '@apollo/client';

import { EditorDlg } from './formEditorDlg';
import { set } from 'react-hook-form';

const UPDATE_FORM_TEMPLATE = gql`
  mutation updateFormTemplate($id: Integer!, $updates: FormTemplateInput!) {
    updateFormTemplate(id: $id, updates: $updates) {
      id
      name
      body
    }
  }
`;

const CREATE_FORM_TEMPLATE = gql`
  mutation createFormTemplate($updates: FormTemplateInput!) {
    createFormTemplate(updates: $updates) {
      id
      name
      body
    }
  }
`;



const RenderEditor = ({template, parentIndex, setCurrentNode, createNode, moveNode ,deleteNode} :any) => {
  const handleChange = (e: any) => {}
    
  const newItem = (index: string) => {}

  //get all of the keys from the template items and put them in a hash
  const keys = {} as any;
  template.map((item: any) => {
    const current = keys[item.key] || 0;
    keys[item.key] = current + 1;
    
    /*if (item.type == 'listInput'){
      item.options.map((option: any) => {
        const current = keys[option.key] || 0;
        keys[option.key] = current + 1;
      })
    }*/

    
  })

  return ( 
    
        <div>
          {template.map((item: any, index: number) => {    

            const fullIndex =  (parentIndex=='') ? index.toString() : `${parentIndex}.${index}`;
            let keyColor = (item.key && keys[item.key] > 1) ? 'red' : 'transparent';
            keyColor = (item.key && item.key.includes('*')) ? 'yellow' : keyColor;

            return (
              <div onClick={ ()=>{ setCurrentNode(index) }} key={`${item.text}${index}`}  style={{paddingTop: '0.5rem'}} className='editbox'>

                <div className='' style={{marginTop: '0.5rem'}}>
                  <div   style={ {  display: 'grid',  gridTemplateColumns: '30px 1fr 30px'}}>        
                  <div>
                    <div className='editbutton' onClick={()=>{moveNode (index, 'up')}}>▲</div>
                    <div className='editbutton' onClick={()=>{moveNode (index, 'down')}}>▼</div>                    
                  </div>

                  {(item.type === 'section') &&        
                      <div className={item.outercss||''} >          
                        <h2>{item.text|| item.label}</h2>
                        <div style={(parentIndex!='') ? {}: {paddingLeft: '2rem', paddingBottom: '2rem'}}>
                          <RenderEditor template= {item.children} parentIndex={fullIndex} />
                        </div>
                      </div>            
                  }

                  {(item.type === 'text') && 
                    <div key={index}>          
                      <div>{item.text}</div>        
                    </div>
                  }

                  {(item.type === 'bulletList') &&
                    <ul>
                    {item.items.map((item:any, index:any)=>{
                      return <li key={index}>{item.text}</li>      
                    })}
                  </ul>   
                  }


                {(item.type === 'paragraph') &&<p key={index}>{item.text}</p>} 
                {(item.type === 'heading') &&<h1 key={index}>{item.text}</h1>} 
                {(item.type === 'bullet') &&<p key={index}>{item.text}</p>} 



                {(item.type === 'dateInput') &&
                <div key={index} className='inputBlock'>
                    <label htmlFor={item.key}>{item.label}</label>
                    <input             
                      readOnly={true}
                      name={item.key}
                      id={item.key} 
                      value={`Date: ${item.key}`} 
                      onChange={handleChange}
                    />
                </div>
                }

                {(item.type === 'textInput') &&
                <div key={index} className='inputBlock' >
                <label id={`label${item.key}`} className='label' htmlFor={item.key}>{item.label}</label>       
                  <input 
                    readOnly={true}
                    name={item.key}
                    type='text'
                    id={item.key} 
                    value={`Text Input: ${item.key}`} 
                    onChange={handleChange}
                  />       
                  </div>
                }

                {(item.type == 'staticInput') && 
                  <div key={index} className='inputBlock' >
                  <label className='label' htmlFor={item.key}>{item.label}</label>
                  <div>
                    <input 
                      name={item.key}
                      type='text'
                      id={item.key} 
                      value={`Static Input: ${item.key}`} 
                      readOnly= {true}
                      onChange={()=>{}}
                    />
                  </div>
                </div>
                }

                {(item.type === 'textAreaInput') && 
                  <div key={index} className='inputBlock'>
                  <label className='label' htmlFor={item.key}>{item.label}</label>
                  <div>
                  <textarea 
                    readOnly={true}
                    id={item.key} 
                    name={item.key}
                    value={`Text Area Input: item.key`} 
                    onChange={handleChange}
                  />
                  </div>
                </div>
                }

                

                {item.type == 'yesnoInput' && 
                  <div key={index} style={{display: 'grid', gridTemplateColumns : '2rem 1fr'}}>                          
                    <div><input 
                      type='checkbox' 
                      id={item.key} 
                      name={item.key}
                      checked={false}
                      onChange={handleChange}
                    />
                    </div>
                    <label  className='label' htmlFor={item.key}>{item.label}</label>              
                </div>
              }

                {item.type == 'listInput' &&
                  <div key={index} className='inputBlock'>
                  <label htmlFor={`label${item.key}`}>{item.label}</label>
                    <div>
                    {item.options.map((option: any, optionIndex: number) => {
                      const color = (option.key && keys[option.key]) ? 'red' : 'transparent';

                      return (
                        <React.Fragment key={optionIndex}>
                          <input 
                            type='checkbox' 
                            
                            
                            id={option.key} 
                            name={option.key}
                            //value={option.value}
                            checked={false}
                            onChange={handleChange}
                          />
                          <label className='label' htmlFor={option.id}>{option.label}</label>
                        </React.Fragment>
                      )
                    })}
                  </div>
                </div>}

                <div style={{display:'grid', gridTemplateRows:'1fr 1fr 1fr'}}>
                    {item.type=='section' && <div className='editbutton' onClick={()=>{newItem ( `${fullIndex}.0`)}}>+</div>}                                        
                    
                    <div className='editbutton' onClick={()=>{createNode ( index)}}>+</div>
                    <div className='editbutton' onClick={()=>{deleteNode(index)}}>␡</div>
                  </div>    

                {/* Do some Error Checking*/ }
                  
                  
                </div>

                <div style={{display:'flex', flexDirection: 'row-reverse'}}>
                  
                {item.key && <div style={{ backgroundColor: keyColor }}>{item.key}</div>}                
                {(item.type == 'yesnoInput' || item.type == 'listInput') && (item.key=='' || item.key==undefined) && <div style={{color: 'red'}}>Key is required</div>}
                </div>
                    
                  
                </div>        
                
              </div>        
              
            )})        
          }
        </div>                    
    );  
}







export const FormEditor = (params: any) => {
    const { template, onSave, onClose } = params;

    const [ templateState, setTemplateState ] = React.useState(template) as any;

    const {id, name, body: bodyString} = templateState;
    const body = JSON.parse(bodyString);    

    const [ nameState, setNameState ] = React.useState(name) as any;
    const [jsonView, setJSONView] = React.useState(false) as any;
    const [jsonState, setJsonState] = React.useState(body) as any;

    const [updateFormTemplateMutation] = useMutation(UPDATE_FORM_TEMPLATE);
    const [createFormTemplateMutation] = useMutation(CREATE_FORM_TEMPLATE);

    const [currentNode, setCurrentNode] = React.useState({}) as any;

    React.useEffect(() => {
        // Update the node state whenever the item prop changes
        setTemplateState(template);
        setNameState(template.name);
        setJsonState(JSON.stringify (JSON.parse(template.body) ,null,2));
    }, [template]);
    
    const nameChange = (e:any)=>{      
        updateFormTemplateMutation({variables: {id: id, updates: {name: e.target.value}}})
        setNameState(e.target.value);
    }

    const createNode = (index: number) => {    
        const newBody = [...body];
        newBody.splice(index+1, 0, {type: 'text', text: 'New Item', key: 'newItem'});

        setTemplateState({...templateState, body: JSON.stringify(newBody)});
        updateFormTemplateMutation({variables: {id: id, updates: {body: JSON.stringify(newBody)}}})      

        setCurrentNode(index+1);
  }

    const SaveNode = (item: any, index: number) => {    
        const newBody = [...body];
        newBody[index] = item;

        console.log(item)

        setTemplateState({...templateState, body: JSON.stringify(newBody)});
        updateFormTemplateMutation({variables: {id: id, updates: {body: JSON.stringify(newBody)}}})
    }

    const deleteNode = (index: number) => {
      // Display confirmation dialog
      const confirmDelete = window.confirm("Are you sure you want to delete this item?");
  
      // If user confirms deletion
      if (confirmDelete) {
          const newBody = [...body];
          newBody.splice(index, 1);
          setTemplateState({...templateState, body: JSON.stringify(newBody)});
          updateFormTemplateMutation({variables: {id: id, updates: {body: JSON.stringify(newBody)}}})
      }
  }

    const moveNode = (index: number, direction:string) => {
    
      if (direction=='up'){
        if (index==0) return;
        const newBody = [...body];
        const temp = newBody[index-1];
        newBody[index-1] = newBody[index];
        newBody[index] = temp;
        setTemplateState({...templateState, body: JSON.stringify(newBody)});
        updateFormTemplateMutation({variables: {id: id, updates: {body: JSON.stringify(newBody)}}})
      }

      if (direction=='down'){
        if (index==body.length-1) return;
        const newBody = [...body];
        const temp = newBody[index+1];
        newBody[index+1] = newBody[index];
        newBody[index] = temp;
        setTemplateState({...templateState, body: JSON.stringify(newBody)});
        updateFormTemplateMutation({variables: {id: id, updates: {body: JSON.stringify(newBody)}}})
      }

      //setTemplateState({...templateState, body: JSON.stringify(newBody)});
      //updateFormTemplateMutation({variables: {id: id, updates: {body: JSON.stringify(newBody)}}})
  }
    

    const updateFromJSON = () => {
      setTemplateState({id: id, name: nameState, body: jsonState});
      updateFormTemplateMutation({variables: {id: id, updates: {body: jsonState}}})
    }



    if (jsonView){
      return (
        <div>
          <div className='almostboxed'>
            <h2>Form Name</h2>        
            <div style={{display:'grid', gridTemplateColumns: '30rem 1fr 50px 5px 50px 5px 50px'}}>        
              <input value={nameState} onChange={nameChange}/>
              <div></div>
              <div ><button onClick={()=>{setJSONView(false); updateFromJSON() }}>Save</button></div>
              <div></div>
              <div ><button onClick={()=>{setJSONView(false)}}>Editor</button></div>
              <div></div>
              <div><button onClick={onClose}>Close</button></div>
            </div>        
          </div>
          <div>
            <textarea value={ jsonState} onChange={(e:any)=>{ setJsonState(e.target.value) }}   style={{marginLeft:'2rem',width: '95vw', height:'70vh'}}></textarea>
          </div>
        </div>
      )
    }

    const currentNodeObject = (currentNode==-1) ? {} : body[currentNode];

    return (
      <div className=''>

        <div className='almostboxed'>
          <h2>Form Name</h2>        
          <div style={{display:'grid', gridTemplateColumns: '30rem 1fr 50px 5px 50px'}}>        
            <input value={nameState} onChange={nameChange}/>
            <div></div>
            <div ><button onClick={()=>{setJSONView(true)}}>JSON</button></div>
            <div></div>
            <div><button onClick={onClose}>Close</button></div>
        </div>        

        </div>      
          
        <div style={{display:'grid', gridTemplateColumns:'70% 1fr' }}> 
          <div style={{ overflowY: 'scroll', maxHeight: '100vh', paddingRight:'2rem' }}>
            <RenderEditor template= {body} currentNode={currentNode} setCurrentNode={setCurrentNode} createNode={createNode} moveNode={moveNode} deleteNode={deleteNode}/>               
            <button className='editbutton' onClick={()=>{createNode(body.length-1) }}>+</button>
          </div>

          <div style={{ paddingLeft:'1rem' }}>
          <EditorDlg item = {currentNodeObject||{}}  SaveNode={SaveNode}  method='newItem' index={currentNode}/>
          </div>
        </div>
        

      </div>

      
    );
  }
  

