import React, { useCallback, useEffect, useState } from 'react';
import { gql, useMutation, useSubscription } from '@apollo/client';
import { toast } from 'react-toastify';
import Modal from 'react-modal';

const UPDATE_PCODE = gql`
  mutation generatePLevelReport {
    generatePLevelReport{
      message
      responseCode
    }
  }
`;

const SUBSCRIPTION_UPLOAD_PCODE_DATA_EXCEL = gql`
  subscription {
    subscriptionPCodeReportProgress
    {
        id
        runid
        action         
        total
        name
        dob
        race
        sex
        booking
        fin
        mrun
        champid
        arrest_count
        program
        ODRAssessmentDate
        release_date
        pcode_at_odrAssessment
        pcodeDate_at_odrAssessment
        pcodeSource_at_odrAssessment
        building_at_odrAssessment
        pcode_at_release
        pcodeDate_at_release
        pcodeSource_at_release
        building_at_release      
        tests
        message
    }
  }
`;

export const GeneratePLevelReport = () => {
  const [updatePCode] = useMutation(UPDATE_PCODE);
  const { data: subscriptionData } = useSubscription(SUBSCRIPTION_UPLOAD_PCODE_DATA_EXCEL);
    
  const [report, setReport] = React.useState({}) as any; // Change report to a hash
  const [currentStep, setCurrentStep] = useState(0)

  const [selectedItem, setSelectedItem] = useState(null) as any;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showClients, setShowClients] = useState(false);

  const openModal = (item: any) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedItem(null);
    setIsModalOpen(false);
  };

  const eventQueueRef = React.useRef([]) as any ;

  useEffect(() => {
    if (subscriptionData && subscriptionData.subscriptionPCodeReportProgress) {
      const data = subscriptionData.subscriptionPCodeReportProgress;
      eventQueueRef.current.push(data);
    }
  }, [subscriptionData]);

  useEffect(() => {
    const processEventQueue = () => {
      const queue = eventQueueRef.current;
      if (queue.length === 0) return;

      setReport((prevReport: Record<string, any>) => {
        const updatedReport = { ...prevReport };
        
        queue.forEach((data: { action: string; id: number; message?: string; tests?: any }) => {

          if (updatedReport[data.id] == null) {
            updatedReport[data.id] = {
              ...data,
              messages: data.message ? [data.message] : [],
            };
          }
          const messages = updatedReport[data.id]?.messages || []

          if (data.action === 'insert') {
            
            updatedReport[data.id]  = {
                ...data,                
              };
              setCurrentStep(data.id)
          } 
          
          if (data.action === 'update') {
            updatedReport[data.id] = {
              ...data,
              messages: [...messages, data.message],
              client : true
            };
          } 
          
          if (data.action === 'test') {
            if (updatedReport[data.id]) {
              updatedReport[data.id].tests = data.tests;
              updatedReport[data.id].message = data.message;
              updatedReport[data.id].messages = [...messages, data.message];
            }
          } else if (data.action === 'finish') {
            toast.success('Report Generation Finished');
          }
        });

        return updatedReport;
      });

      eventQueueRef.current = [];
    };

    const intervalId = setInterval(processEventQueue, 600); // Process queue every 100ms

    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    updatePCode();
  }, []);
  useEffect(() => {
    updatePCode()    
  }, [])

 const handleClick = () => {
    updatePCode()    
 }

  const style = {
    display: 'grid',
    gridTemplateColumns: '2rem 3rem 10rem 5rem 7rem 6rem 5rem 4rem 5rem 6rem 6rem 4rem 9rem 8rem 7rem 3rem 9rem 8rem',
   
  }

  let otherindex = 0

  const downloadCSV = () => {
    const headers = [
      "QA",
      "id",
      "Row",
      "Name",
      "Booking",
      "FIN",
      "MRUN",
      "ChampID",
      "Arrest Count",
      "Program",
      "ODR Assessment Date",
      "Release Date",
      "P odr",
      "Date odr",
      "PCode Source",
      "Building",
      "P rel",
      "Date rel",
      "PCode Source",      
    ];
  
    const csvContent = [
      headers.join(","),
      ...Object.values(report).map((item: any) => [
        item.tests ? "PASS" : "FAIL",
        item.id,
        item.name,
        item.booking,
        item.fin,
        item.mrun,
        item.champid,
        item.arrest_count,
        item.program,
        item.ODRAssessmentDate,
        item.release_date,
        item.pcode_at_odrAssessment,
        item.pcodeDate_at_odrAssessment,
        item.pcodeSource_at_odrAssessment,
        item.building_at_odrAssessment,
        item.pcode_at_release,
        item.pcodeDate_at_release,
        item.pcodeSource_at_release,        
      ].join(",")),
    ].join("\n");
  
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "pLevelReport.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const buttonText = (showClients) ? "Show All" : "Show Only Clients"
  return (
    <div className='boxed'>
      <div style={{ display: 'grid', gridTemplateColumns: '80% 1fr' }} >
        <h1>Refresh PCode Report</h1>        
        <div><button onClick={downloadCSV}>Download CSV</button></div>
        <div><button onClick={() => setShowClients(!showClients)}>{buttonText}</button></div>
        <div>Processing: {currentStep}</div>

      </div>
      
      <div style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '10px', marginTop: '2rem', backgroundColor: 'white' }}> 

      <div style={{...style, marginBottom: '1rem', color: '#333', fontWeight: 'bold'}} >
        <div>qa</div>
        <div>id</div>        
        <div>Name</div>        
        <div>Booking</div>
        <div>FIN</div>
        <div>MRUN</div>
        <div>ChampID</div>
        <div>Arrest Count</div>
        <div>Program</div>
        <div>ODR Assessment Date</div>
        <div>Release Date</div>
        <div>P<br/>odr</div>
        <div>Date<br/>odr</div>
        <div>PCode Source</div>
        <div>Building</div>
        <div>P<br/>rel</div>
        <div>Date<br/>rel</div>
        <div>PCode Source</div>        
      </div>
        {Object.values(report).map((item: any, index: number) => {
           
            let alt = otherindex % 2 == 0 ? 'alt' : ''
            const color = (item.tests =='passed') ? 'green' : 'red'
           
            if (showClients) {
                if (item.client != true) {
                    return null;
                }
            }
            otherindex++;
            return (
                <div className={alt} onClick={() => openModal(item)}>
                <div  style={{...style, marginLeft: '5px'}} key={index} title={item.message}> 
                <div><img src={`/ui/build/icons/${color}check.png`} alt='check' width='10px' /></div>                
                <div>{item.id}</div>                
                <div>{item.name}</div>
                <div>{item.booking}</div>
                <div>{item.fin}</div>
                <div>{item.mrun}</div>
                <div>{item.champid}</div>
                <div>{item.arrest_count}</div>
                <div>{item.program}</div>
                <div>{item.ODRAssessmentDate}</div>
                <div>{item.release_date}</div>
                <div>{item.pcode_at_odrAssessment}</div>
                <div>{item.pcodeDate_at_odrAssessment}</div>
                <div>{item.pcodeSource_at_odrAssessment}</div>
                <div>{item.building_at_odrAssessment}</div>
                <div>{item.pcode_at_release}</div>
                <div>{item.pcodeDate_at_release}</div>
                <div>{item.pcodeSource_at_release}</div>                
              </div>
              <div>{item.message}</div>
              </div>
              )

        })}
      </div>

      <Modal isOpen={isModalOpen} onRequestClose={closeModal}>
        <h2>Messages</h2>
        {selectedItem && selectedItem.messages && (
          <ul>
            {selectedItem.messages.map((message: string, index: number) => (
              <li key={index}>{message}</li>
            ))}
          </ul>
        )}
        <button onClick={closeModal}>Close</button>
      </Modal>
    </div>
  );
};
