// ParticipantSearchPage.tsx
import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client'; // Import useLazyQuery


import { Link, useParams, useNavigate, Navigate  } from 'react-router-dom';


import { gql } from '@apollo/client';
//import the css file
import './searchParticipants.css';

import  ReactModal from 'react-modal';

import { CalAIM } from '../participantsManager/calAIM';
import { Notes } from '../participantsManager/notes';
import { History } from '../participantsManager/history';
import { Legal } from '../participantsManager/legal';
import { Medical } from '../participantsManager/medical';
import { Summary } from '../participantsManager/summary';
import { ParticipantDocuments } from '../../ParticipantDocuments/participantDocuments';


import { useUser } from '../../../store/useUser';
import { useHelpTopics } from '../../../store/useHelpTopics';


export const settingSearch = {
  withoutSearch: true,
}

const programSquare =(idstring:string)=>{
        
    const id=parseInt(idstring)
    
    if (id < 20000000) //19999999 and below
        return <div className='programSquareContainer'><span className='programSquare mist'>MIST</span><a href={`/show_full.php?participant=${idstring}`}>{idstring}</a></div>
    if (id < 30000000) //29999999 and below
        return <div className='programSquareContainer'><span className='programSquare zodr'>ODR</span><a href={`/odrhousing/show_full.php?participant=${idstring}`}>{idstring}</a></div>
    if (id < 40000000) //39999999 and below
        return <div className='programSquareContainer'><span className='programSquare prg_wmn'>MIST</span><a href={`/prg_wmn/show_full.php?participant=${idstring}`}>{idstring}</a></div>
    if (id < 60000000) //59999999 and below
        return <div className='programSquareContainer'><span className='programSquare fist'>FIST</span><a href={`/fist/show_full.php?participant=${idstring}`}>{idstring}</a></div>
    if (id < 70000000) //69999999 and below
        return <div className='programSquareContainer'><span className='programSquare dsh'>DSH</span><a href={`/dsh/show_full.php?participant=${idstring}`}>{idstring}</a></div>
    if (id < 80000000) //79999999 and below
        return <div className='programSquareContainer'><span className='programSquare lead'>LEAD</span><a href={`/lead/show_full.php?participant=${idstring}`}>{idstring}</a></div>
    // anyone else is ODR Range we dont do that here

    return <div></div>    
}

const ModelHeader = ({paricipant, title, onClose=()=>{}}:any)=>{
    return (
        <div className='modalHeader'>
           
            <div className='modalBanner'>
            <h2>{title}</h2>             
              <button className='modalClose' onClick={onClose}>X</button>
            </div>

            < div className='modalHeaderName'>{paricipant.nameHistory&&paricipant.nameHistory[0].firstName} {paricipant.names&&paricipant.nameHistory[0].lastName}</div>
        </div>
    )
}

/*const selectionOptions = {
  genderChecked: 'Gender',
  mrunChecked: 'MRUN',
  finChecked: 'FIN',
  cinChecked: 'CID',  
}as any*/

export const SearchParticipants = ({Close = ()=>{}}:any) => {
  const navigate = useNavigate();
  const { user, isUserLoading } = useUser();
    const [modalOpen, setModalOpen] = useState('');
    
    //const [modalIsOpen, setModalIsOpen] = useState(false);

    const [searchInput, setSearchInput] = useState<string>('');
    const [searchResults, setSearchResults] = useState([]) as any;

    const [activeParticipant, setActiveParticipant] = useState<any>({names:{}});

    const {pushHelpTopic, popHelpTopic} = useHelpTopics();
  
    useEffect(() => {
      pushHelpTopic('Search');
      return () => {
        popHelpTopic();
      };
    }, []);
  
    // Load checkbox states from localStorage or use defaults
    const cookieSearchBoxState = JSON.parse(localStorage.getItem('searchBoxState') || '{}');
    
    const defaultCheckboxStates = {
      genderChecked: true,
      mrunChecked: true,
      finChecked: true,
      cinChecked: true,
      icd10Checked: false,
      careplanChecked: false,
      initialAssessmentChecked: false,
      programCompletionChecked: false,
      lastLACAREReferralDateChecked: false,
      lastLACAREScreeningDateChecked: false,
      
    } as any

    Object.keys(defaultCheckboxStates).forEach((checkboxName) => {
      if (cookieSearchBoxState[checkboxName] === undefined) {
        cookieSearchBoxState[checkboxName] = defaultCheckboxStates[checkboxName];
      }
      }
    );
    
    const [checkboxStates, setCheckboxStates] = useState( cookieSearchBoxState );
  
    const [searchParticipants, { loading, data, error }] = useLazyQuery(
      gql`
        query searchParticipants($search: String! ) {
          searchParticipants(search: $search) {
            id
            nameHistory {
              firstName
              lastName
            }
            programs
            gender 
            mrun 
            fin 
            cid 
            
           
          }
        }
      `
    );
  
    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchInput(event.target.value);
    };
  
    const handleSearchClick = () => {
      searchParticipants({
        variables: {
          search: searchInput,
          includeGender: checkboxStates.genderChecked,
          includeMrun: checkboxStates.mrunChecked,
          includeFin: checkboxStates.finChecked,
          includeCin: checkboxStates.cinChecked,
          includeIcd10: checkboxStates.icd10Checked,
          includeProgramCompletion: checkboxStates.programCompletionChecked,
          includeCarePlan: checkboxStates.careplanChecked,
          includeInitialAssessment: checkboxStates.initialAssessmentChecked,
          includeLACAREReferralDate: checkboxStates.lastLACAREReferralDateChecked,
          includeLACAREScreeningDate: checkboxStates.lastLACAREScreeningDateChecked,

        },
      });
    };
  
    // When the data is available, update the search results
    useEffect(() => {
      if (data && data.searchParticipants) {
      /*  if (data.searchParticipants.length == 1) {
          navigate(`/participants/${data.searchParticipants[0].id}`)
        }*/
        setSearchResults(data.searchParticipants);
        
      }
    }, [data]);
  
    const updateCheckboxState = (checkboxName: keyof typeof checkboxStates) => {
      setCheckboxStates((prevState:any) => {
        const updatedState = {
          ...prevState,
          [checkboxName]: !prevState[checkboxName],
        };
  
        // Save the updated state to localStorage
        localStorage.setItem('searchBoxState', JSON.stringify(updatedState));
  
        return updatedState;
      });
    };
  return (
    <div>
      
      <h1>Participant Search</h1>
      <form onSubmit={(e) => { e.preventDefault(); handleSearchClick(); }}>
        <div style={{display: 'grid', gridTemplateColumns: '22rem 10rem'}}>            
            <div>
              <input type="text" placeholder="Search by name, CID, MRUN, or FIN" value={searchInput} onChange={handleSearchInputChange}/></div>
            <div ><button onClick={handleSearchClick}>Search</button></div>
          
        </div>
        </form>
        
      

      <div>
        <h2>Search Results:</h2>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div>
            <div className='boxed' style={{display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', gap: '10px'}}>
                <div>Names</div>
                <div>Gender</div>
                <div>Programs</div>
                <div>MRUN</div>
                <div>FIN</div>
                <div>ChampID</div>                
            </div>
            {searchResults.map((result:any) =>  {
               const lastProgramCompletionDate = (result.lastProgramCompletionDate) ? result.lastProgramCompletionDate : {date: null, id: null};
               const lastCarePlanDate = (result.lastCarePlanDate) ? result.lastCarePlanDate : {date: null, id: null};
               const lastInitialAssessmentDate = (result.lastInitialAssessmentDate) ? result.lastInitialAssessmentDate : {date: null, id: null};
             const lastLACAREReferralDate = (result.lastLACAREReferralDate) ? result.lastLACAREReferralDate : {date: null, id: null};
             const lastLACAREScreeningDate = (result.lastLACAREScreeningDate) ? result.lastLACAREScreeningDate : {date: null, id: null};

             const odrparticipant = result.id
              
              return (
              <div className='boxed'>
              <div style={{display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', gap: '10px'}} key={result.id}>
                <Link to={`/participants/${result.id}`} onClick={Close}>
                {result.nameHistory && result.nameHistory.map((name:any, index:number) => {
                    return (
                        <div key={index}>
                        {name.firstName} {name.lastName}
                        </div>
                    );

                }   
                )}   
                </Link>          
                <div>{result.gender}</div>
                <div>{result.programs && result.programs.map((p:any)=>{return programSquare(p)})}</div>
                <div>{result.mrun}</div>
                <div>{result.fin}</div>
                <div>{result.cid}</div>                
                <div>



                </div>
                {<div>
                 
                
                
                            </div>}
           
            </div>
           {/* <button className='spaced' onClick={() => {
                setCalAIMModalIsOpen(true)
                setActiveParticipant({...result})}}>Legal</button>
                
            <button className='spaced' onClick={() => {
                setNotesModalIsOpen(true)
                setActiveParticipant({...result})}}>Medical</button>            

              <button className='spaced' onClick={() => {
                setCalAIMModalIsOpen(true)
                setActiveParticipant({...result})}}>Plan and Location</button>
                
                <button className='spaced' onClick={() => {
                setNotesModalIsOpen(true)
                setActiveParticipant({...result})}}>Notifications</button>
            
                            
                *
                <button className='spaced' onClick={() => {
                setModalOpen('calAIM')
                setActiveParticipant({...result})}}>CalAIM</button>


                { user.groups.includes('superadmin') &&
                  <button className='spaced' onClick={() => {
                  setModalOpen('medical')
                  setActiveParticipant({...result})}}>Medical</button> }

                { user.groups.includes('admin') &&
                  <button className='spaced' onClick={() => {
                  setModalOpen('legal')
                  setActiveParticipant({...result})}}>Legal</button> }

                  */}

                {/* true && <button className='spaced' onClick={() => {
                setModalOpen('calAIM')
                setActiveParticipant({...result})}}>CalAIM</button>}

                
                <button className='spaced' onClick={() => {
                setModalOpen('notes')
                setActiveParticipant({...result})}}>Notes/messages</button>

                { true &&
                <button className='spaced' onClick={() => {
                setModalOpen('documents')
                setActiveParticipant({...result})}}>Documents</button> }  

                { true &&
                <button className='spaced' onClick={() => {
                setModalOpen('history')
                setActiveParticipant({...result})}}>History</button> }

                { true &&
                <button className='spaced' onClick={() => {
                setModalOpen('summary')
                setActiveParticipant({...result})}}>Summary</button> */}

    

            </div>              
            )})}
          </div>
        )}
      </div>

      

      {modalOpen=='calAIM' && <ReactModal isOpen={true} onRequestClose={() => setModalOpen('')}>        
        <ModelHeader title="CalAIM Demographics" paricipant={activeParticipant} onClose={() => setModalOpen('')}/>
        <CalAIM participant={activeParticipant.id} onClose={() => setModalOpen('')}/>           
      </ReactModal>}

      {modalOpen=='notes' && <ReactModal isOpen={true} onRequestClose={() => setModalOpen('')}>
        <ModelHeader title="Notes" paricipant={activeParticipant} onClose={() => setModalOpen('')}/>
        <Notes participant={activeParticipant.id} onClose={() => setModalOpen('')}/>   
      </ReactModal>}

      {modalOpen=='legal' && <ReactModal isOpen={true} onRequestClose={() => setModalOpen('')}>
        <ModelHeader title="Legal" paricipant={activeParticipant} onClose={() => setModalOpen('')}/>
        <Legal participant={activeParticipant.id} onClose={() => setModalOpen('')}/>   
      </ReactModal>}

      {modalOpen=='medical' && <ReactModal isOpen={true} onRequestClose={() => setModalOpen('')}>
        <ModelHeader title="medical" paricipant={activeParticipant} onClose={() => setModalOpen('')}/>
        <Medical participant={activeParticipant.id} onClose={() => setModalOpen('')}/>   
      </ReactModal>}

      {modalOpen=='history' && <ReactModal isOpen={true} onRequestClose={() => setModalOpen('')}>        
        <div style={{ display: 'flex', justifyContent: 'flex-end', // Align items to the end (right)
        }}> <button onClick={() => setModalOpen('')}>X</button></div>
        <History participant={activeParticipant.id} onClose={() => setModalOpen('')}/>   
      </ReactModal>}
      
      {modalOpen=='summary' && <ReactModal isOpen={true} onRequestClose={() => setModalOpen('')}>        
        <div style={{ display: 'flex', justifyContent: 'flex-end', // Align items to the end (right)
        }}> <button onClick={() => setModalOpen('')}>X</button></div>
        <Summary participant={activeParticipant.id} onClose={() => setModalOpen('')}/>   
      </ReactModal>}

      {modalOpen=='documents' && <ReactModal isOpen={true} onRequestClose={() => setModalOpen('')}>        
        <div style={{ display: 'flex', justifyContent: 'flex-end', // Align items to the end (right)
        }}> <button onClick={() => setModalOpen('')}>X</button></div>
        <ParticipantDocuments participant={activeParticipant.id} onClose={() => setModalOpen('')}/>   
      </ReactModal>}

 
    </div>
  );
};