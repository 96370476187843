import { useContext, useEffect } from 'react';
import { StoreContext } from '../storeProvider';

export const useHelpTopics = () => {
    const { currentHelpTopic, pushHelpTopic, popHelpTopic } = useContext(StoreContext) as any;
    return { currentHelpTopic, pushHelpTopic, popHelpTopic };
}

export const useCurrentHelpTopic = () => {
    const { currentHelpTopic } = useContext(StoreContext) as any;
    return currentHelpTopic;
}



export const useSetHelpTopic = (helptopic: string) => {
    const { currentHelpTopic, pushHelpTopic, popHelpTopic } = useContext(StoreContext) as any;

    useEffect(() => {
      pushHelpTopic(helptopic);
      return () => {
        popHelpTopic();
        };
    }, []);   
    
    return helptopic;
}
