import React, { useState } from 'react';

import { gql, useMutation, useQuery } from '@apollo/client';


const UPDATE_PARTICIPANT_DEMOGRAPHICS = gql`
  mutation UpdateParticipantDemographics($participant: Integer!, $updates: updateParticipantInput!) {
    updateParticipantDemographicsByProgramParticipantNumber(participant: $participant, updates: $updates) {
        message
        responseCode
    }
  }
`;


const DDfields= [
    '----',
    'participantId',
    'firstName',
    'lastName',
    'gender',
    'dob',
    'champId',
    'ssn',
    'leadRace',
    'leadPathway',    
    'leadDisposition',
    'leadProvider',
    'leadEnrollmentDate',
    'leadSite',
    'leadReferringAgency',
    'leadReferringIndividual',
    'leadReferralType',
    'leadExitDate',
    'leadExitReason',
    'leadExitNote'
]

const _d = (d: any): string | undefined => { 
    if (d === undefined || d === "" || d === null)
        return undefined;

    //if the date isnt in the format of dd/mm/yyyy, return undefined
    if (!/^\d+\/\d+\/\d{4}$/.test(d))
        return undefined;
    
    const dateObject = new Date(d);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const day = String(dateObject.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
}

const readCSV = (data: any) => {
 //read the CSV file without xlsx, use the split function to split the data into rows
    const rows = data.split('\n');
    // Extract column names
    const columns = rows[0].split('~');
    // Extract data
    const jsonData = rows.slice(1).map((row:any) => row.split('~'));
    return {columns, jsonData};
}

export const CSVImport = () => {
  const [file, setFile] = useState<File | null>(null);
  const [columnNames, setColumnNames] = useState([]) as any;
  const [cvsPreview, setCvsPreview] = useState([]) as any;
  const [fieldsMap, setFieldsMap] = useState([]) as any;    

  const [updateParticipantDemographics] = useMutation(UPDATE_PARTICIPANT_DEMOGRAPHICS); // Initialize the mutation hook

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {

        const {columns, jsonData} = readCSV(event.target.result);
          /*const data = event.target.result;
          const workbook = read(data, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = utils.sheet_to_json(sheet, { header: 1 , raw: true, rawNumbers: true});

          // Extract column names
          const columns = jsonData[0] || [] as any; // Assuming first row contains column names*/
          setColumnNames(columns.slice(0, 20));
          const fieldsMap = {} as any
          columns.map ((col:any, index:number) => {
            fieldsMap[col] = DDfields[0]
          })


          const preview = [] as any;
          for (let index = 1; index < jsonData.length; index++) {
            const row = jsonData[index] as any;
                if (row[0] === undefined)
                    break;

                const newrow= [] as any
                for (let i = 0; i < 20; i++) {
                    newrow.push(row[i]|| '')
                }
                preview.push(newrow)          

                if (! (row[20] === "" || row[20] === undefined || row[20] === null)){
                    const nrow = [...newrow] as any
                    for (let i = 20; i < 28; i++) {
                        nrow[i-8] = row[i]|| ''
                    }
                    preview.push (nrow)
                }
                
            }
            setCvsPreview(preview)
          
        }
      };      
      reader.readAsBinaryString(file);
    }
  };


  const handleSave = async () => {
    const data = cvsPreview.map((row:any) => {
        const obj = {} as any;
        for (let i = 0; i < row.length; i++) {
            obj[fieldsMap[columnNames[i]]] = row[i];
        }
        obj.leadExitDate = _d(obj.leadExitDate) 
        obj.leadEnrollmentDate = _d (obj.leadEnrollmentDate)
        obj.dob = _d ( obj.dob)
        obj.participantId = obj.participantId ? parseInt(obj.participantId) : null;

        return obj;
    });

    const messages = [] as any;
    //send data to server
    for (let i = 0; i < data.length; i++) {
        const row = data[i];
        const participantId = row.participantId;
        delete row.participantId;


        try {            
        await updateParticipantDemographics({
            variables: {
                participant: participantId,
                updates: row
            }            
        });
        
        }
        catch (e:any) {
            console.log(`${e.message} ${i} ${JSON.stringify(row)}`);
            
        }

        
    }

    console.log(messages);

  }

  const onhandleFieldChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newFieldsMap = {...fieldsMap};
    newFieldsMap[e.target.name] = e.target.value;
    setFieldsMap(newFieldsMap);
    console.log(newFieldsMap)
  }

  return (
    <div>
      <h1>Upload Spreadsheet</h1>
      <form onSubmit={handleSubmit}>
        <input type="file" accept=".csv" onChange={handleFileChange} />
        <button type="submit" disabled={!file}>Upload</button>
      </form>

      <button onClick={handleSave}>Submit</button>
      <h2>Column Names:</h2>
      <ul>
        {columnNames.map((columnName:string, index:number) => (
            <div key={index} style={{ display:'grid', gridTemplateColumns:'1fr 1fr 50%' }} className= { index % 2 ? '' : 'alt'}>
              <li >{columnName}</li>
                <select name={columnName} onChange={onhandleFieldChange}>
                {DDfields.map((field, index) => (
                <option key={index} value={field}>{field}</option>
                ))}
                </select>
            </div>
        ))}
      </ul>

        <h2>Preview:</h2>
        <table>
            <thead>
                <tr>
                    {columnNames.map((columnName:string, index:number) => (
                        <th key={index}>{columnName}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {cvsPreview.map((row:any, index:number) => (
                    <tr key={index} className={ (index % 2) ? '' : 'alt'}>
                        {row.map((cell:any, index:number) => (
                            <td key={index}>{cell}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>

       
    </div>
  );
};
