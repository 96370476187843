import React from "react";
import { gql, useMutation } from "@apollo/client";


const UPDATE_PARTICIPANT_PROGRAM_DATA = gql`
  mutation updateParticipantProgramDataByProgramNumber($programNumber: Integer!, $updates: participantProgramDataInput) {
    updateParticipantProgramDataByProgramNumber(programNumber: $programNumber, updates: $updates) {
      message
    }
  }
`;



const CalAimDemographics = ({ calaimData : c, programNumber, healthPlanProviders }: any) => {
  const [calaimData, setCalaimData] = React.useState(c);


  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateParticipantProgramData({
      variables: {
        programNumber: programNumber,
        updates: {
          ssn: calaimData.ssn,
          cin: calaimData.cin,
          cii: calaimData.cii,
          healthplanProvider: calaimData.healthplanProvider,
          pcpname: calaimData.pcpname,
          fin: calaimData.fin,
        },
      },
    });
  };

  const [updateParticipantProgramData] = useMutation(UPDATE_PARTICIPANT_PROGRAM_DATA);

  return (
    <div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <h3>ECM CalAIM Demographics</h3>
        <div>ECM Care Manager: {calaimData.ecmmanager}</div>
      </div>

      <form onSubmit={handleSubmit}>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
          <div>
            <div style={{ marginBottom: "1rem" }}>
              <label htmlFor="ssn">SSN</label>
              <div>
                <input
                  type="text"
                  name="ssn"
                  value={calaimData.ssn}
                  id="calaim_ssn"
                  onChange={(e) => setCalaimData({ ...calaimData, ssn: e.target.value })}
                />
              </div>
            </div>

            <div style={{ marginBottom: "1rem" }}>
              <label htmlFor="cin">MediCal-CIN</label>
              <div>
                <input
                  type="text"
                  name="cin"
                  value={calaimData.cin}
                  id="calaim_cin"
                  onChange={(e) => setCalaimData({ ...calaimData, cin: e.target.value })}
                />
              </div>
            </div>

            <div style={{ marginBottom: "1rem" }}>
              <label htmlFor="cii">CII</label>
              <div>
                <input
                  type="text"
                  name="cii"
                  value={calaimData.cii}
                  id="calaim_cii"
                    onChange={(e) => setCalaimData({ ...calaimData, cii: e.target.value })}
                />
              </div>
            </div>
          </div>

          <div>
            <div style={{ marginBottom: "1rem" }}>
              <label htmlFor="healthplan">Health Plan Provider</label>
              <div>
                <select
                  name="healthplan"
                  value={calaimData.healthplan}
                  id="calaim_hcprovider"
                  onChange={(e) => setCalaimData({ ...calaimData, healthplan: e.target.value })}
                >
                  <option value="">--Select an option--</option>
                  {healthPlanProviders.map((provider: any) => (
                    <option key={provider.providerId} value={provider.providerId}>
                      {provider.providerName}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div style={{ marginBottom: "1rem" }}>
              <label htmlFor="pcpname">PCP Name</label>
              <div>
                <input
                  type="text"
                  name="pcpname"
                  value={calaimData.pcpname}
                  id="calaim_pcpname"
                  onChange={(e) => setCalaimData({ ...calaimData, pcpname: e.target.value })}
                />
              </div>
            </div>

            <div style={{ marginBottom: "1rem" }}>
              <label htmlFor="fin">FIN</label>
              <div>
                <input
                  type="text"
                  name="fin"
                  value={calaimData.fin}
                  id="calaim_fin"
                  onChange={(e) => setCalaimData({ ...calaimData, fin: e.target.value })}
                />
              </div>
            </div>
          </div>
        </div>
        <div></div>
        <button type="submit">Update</button>
      </form>
    </div>
  );
};

export default CalAimDemographics;
