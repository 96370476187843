


import { useState, useEffect } from 'react';
import { useParams, useNavigate  } from 'react-router-dom';
import { useQuery, gql, useMutation } from '@apollo/client';

import { useUser } from '../../../store/useUser';


import {toast} from 'react-toastify';

const CustomDate = (props: any) => {
  const { value } = props;
  if (!value)
  return <div></div>;
  const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.\d{3}Z$/;
  const [, year, month, day, hour, minute, second] = value.match(regex) || [];


  if (!year) {
    // Handle invalid date format
    return <div>Invalid date format</div>;
  }

  const usaDateFormat = `${month}/${day}/${year}`;

  return <div>{usaDateFormat}</div>;
};

const GET_PARTICIPANT_BY_ODR_NUMBER = gql`
  query GetParticipantByODRNumberAdmin($odrnumber: Integer!) {
    getParticipantByODRNumberAdmin(odrnumber: $odrnumber) {
      ab109AP {uniqueid status entryby entrydate}
      acuityAP {uniqueid acuity entryby entrydate}
      champidAP {uniqueid champid entryby entrydate}
      cidAP {uniqueid cid entryby entrydate}
      ciiAP {uniqueid cii entryby entrydate}
      cinAP {uniqueid cin entryby entrydate}
      consentsAP {uniqueid entryby entrydate}
      ecmContactsAP {uniqueid phone_number address entryby entrydate}
      finAP {uniqueid fin entryby entrydate}
      healthPlanAP {uniqueid  clinicempaneled dhsempaneled ecm_assignment_date ecmmanager ecmprovider healthPlan healthplanabbr healthplancode pcpname entryby entrydate}
      icd10AP {uniqueid icd10code entryby entrydate}
      mainAP {uniqueid mainfour entryby entrydate}
      medicalDemographicsNotesAP {uniqueid note entryby entrydate}
      mrunAP {uniqueid mrun entryby entrydate}
      ssnAP {uniqueid ssn entryby entrydate}
      wpcAP {uniqueid wpc entryby entrydate}
      
      ab109PP {uniqueid status program entryby entrydate}
      adherencePP {uniqueid adherence program entryby entrydate}
      arrestDatePP {uniqueid arrestdate  program entryby entrydate}
      dispositionPP {uniqueid category  program entryby entrydate}
      champidPP {uniqueid champid entryby  program entrydate} 
      clinicalpresentationPP {uniqueid clinicalpresentation  program entryby entrydate}
      completionDate {uniqueid program entryby entrydate}
      dobPP {uniqueid dob  program entryby entrydate}
      dsmPP {uniqueid dsm5dx  program entryby entrydate}
      isnumberPP {uniqueid  program entryby entrydate}
      istPP {uniqueid  program entryby entrydate}
      locationPP {uniqueid location  program entryby entrydate}
      maxdatePP {uniqueid maxdate  program entryby entrydate}
      medicalProblemsPP {uniqueid medicalproblems  program entryby entrydate}
      mrunPP {uniqueid  mrun program entryby entrydate}
      namesPP {uniqueid  firstname lastname  program entryby entrydate}
      notesPP {uniqueid notes  program entryby entrydate}
      otherMedsPP {uniqueid othermeds  program entryby entrydate}
      pathwayPP {uniqueid  pathway program  entryby entrydate}
      providerPP {uniqueid provider program entryby entrydate}
      psychHistoryPP {uniqueid psychhistory program entryby entrydate}
      psychotropicmedsPP {uniqueid psychotropicmeds program entryby entrydate}
      releasedPP {uniqueid released program  entryby entrydate}
      releaseDatePP {uniqueid releasedate program entryby entrydate}
      sexoffenderPP {uniqueid sexoffender program entryby entrydate}
      
      substanceHistoryPP {uniqueid entryby entrydate}
       
    }
  }
`;

const convertToHash = (data: any) => {
  const result: any = {};
  Object.keys(data).forEach((key) => {
    if (key === '__typename') return;

    if (Array.isArray(data[key])) {
      data[key].forEach((item: any) => {
        const dateKey = new Date(parseInt(item.entrydate)).toLocaleDateString(); // Convert entrydate to a readable date
        if (!result[dateKey]) {
          result[dateKey] = [];
        }
        result[dateKey].push( item );
      });
    }
  });
  return result;
};

const tableNames = (name: string) =>{
  return name
  switch  (name) {
    case 'ab109APAdmin' : 
      return 'AB109';
    case 'acuityAPAdmin' :
      return 'Acuity';
    case 'champidAPAdmin' :
      return 'Champ ID';
    case 'cidAPAdmin' :
      return 'CID';
    case 'ciiAPAdmin' :
      return 'CII';
    case 'cinAPAdmin' :
      return 'CIN';
    case 'consentsAPAdmin' :
      return 'Consents';
    case 'ecmContactsAPAdmin' :
      return 'ECM Contacts';
    case 'finAPAdmin' :
      return 'FIN';
    case 'healthPlanAPAdmin' :
      return 'Health Plan';
    case 'icd10APAdmin' :
      return 'ICD10';
    case 'mainAPAdmin' :
      return 'Main Four';
    case 'medicalDemographicsNotesAPAdmin' :
      return 'Medical Demographics Notes';
    case 'mrunAPAdmin' :
      return 'MRUN';
    case 'ssnAPAdmin' :
      return 'SSN';
    case 'notesPPAdmin' :
      return 'Note';
    case 'wpcAPAdmin' :
      return 'WPC';
    default:
      return name;    
    
  }
}

const d = (date:any)=>{
  return new Date(parseInt(date)).toLocaleDateString();
}

const eventFormater = (eventType: string, item: any) => {  
  switch (eventType) {

    case 'releasedPPAdmin' :
      return (
        <div className='columns2'>
          <div><span className='bold'>Released:</span> <span>{(item.released) ? "Yes": "No"}</span></div>
          <div>Entry By: {item.entryby}</div>         
        </div>
      );

  case 'ab109PPAdmin' :
    return (
      <div className='columns2'>
        <div><span className='bold'>AB109:</span> <span>{item.status}</span></div>
        <div>Entry By: {item.entryby}</div>        
      </div>
    );

    case 'champidPPAdmin' :
      return (
        <div className='columns2'>
          <div><span className='bold'>Champ ID:</span> <span>{item.champid}</span></div>
          <div>Entry By: {item.entryby}</div>        
        </div>
      );

  case 'locationPPAdmin' :
    return (
      <div className='columns2'>
        <div><span className='bold'>Location:</span> <span>{item.location}</span></div>
        <div>Entry By: {item.entryby}</div>        
      </div>
    );

  case 'notesPPAdmin' :
    return (
      <div >
        <div><span className='bold'>Notes:</span> <span>{item.notes}</span></div>
        <div><span className='bold'>Entry By:</span> <span>{item.entryby}</span></div>        
      </div>
    );
    case 'mrunPPAdmin' :
      return (
        <div>
          <div><span className='bold'>MRUN:</span> <span>{item.mrun}</span></div>
          <div>Entry By: {item.entryby}</div>        
        </div>
      );

      case 'sexoffenderPPAdmin' :        
          return (
            <div className='columns2'>
              <div><span className='bold'>RSO:</span> <span>{item.sexoffender ? "Yes": "No"}</span></div>
              <div>Entry By: {item.entryby}</div>        
            </div>
            )


      case 'istPPAdmin' :
        return (
          <div className='columns2'>
            <div><span className='bold'>IST:</span> <span>{item.ist}</span></div>
            <div>Entry By: {item.entryby}</div>        
          </div>
        );

      case 'substanceHistoryPPAdmin' :
        return (
          <div>
            <div><span className='bold'>Substance History:</span> <span>{item.substancehistory}</span></div>
            <div>Entry By: {item.entryby}</div>        
          </div>
        );

      case 'maxdatePPAdmin' :
        return (
          <div className='columns2'>
            <div><span className='bold'>Max Date:</span> <span>{d(item.maxdate)}</span></div>
            <div>Entry By: {item.entryby}</div>        
          </div>
        );


      case 'ab109PPAdmin' :
        return (
          <div className='columns2' >
            <div><span className='bold'>AB109:</span> <span>{item.status}</span></div>
            <div>Entry By: {item.entryby}</div>        
          </div>
        );

    case 'dobPPAdmin' :
      return (
        <div className='columns2' >
          <div><span className='bold'>DOB:</span> <span>{d(item.dob)}</span></div>
          <div>Entry By: {item.entryby}</div>        
        </div>
      );

      case 'providerPPAdmin' :
        return (
          <div className='columns2' >
            <div><span className='bold'>Provider:</span> <span>{item.provider}</span></div>
            <div>Entry By: {item.entryby}</div>        
          </div>
        );

    case 'namesPPAdmin' :
      return (
        <div className='columns2' >
          <div><span className='bold'>First Name:</span> <span style={{marginRight: "3rem"}}>{item.firstname}</span><span className='bold'>Last Name:</span> <span>{item.lastname}</span></div>
          
          <div>Entry By: {item.entryby}</div>        
        </div>
      );
      case 'arrestDatePPAdmin' :
        return (
          <div className='columns2' >
            <div><span className='bold'>Arrest Date:</span> <span>{d(item.arrestdate)}</span></div>
            <div>Entry By: {item.entryby}</div>        
          </div>
        );

        case 'mrunAPAdmin' :
          return (
            <div className='columns2' >
              <div><span className='bold'>MRUN:</span> <span>{item.mrun}</span></div>
              <div>Entry By: {item.entryby}</div>        
            </div>
          );

    case 'clinicalpresentationPPAdmin' :
      return (
        <div>
          <div><div className='bold'>Clinical Presentation:</div> <span dangerouslySetInnerHTML={{ __html: item.clinicalpresentation }}></span> </div>
          <div><span className='bold'>Entry By: {item.entryby}</span></div>        
        </div>
      );

    case 'dispositionPPAdmin' :
      return (
        <div className='columns2' >
          <div><span className='bold'>Disposition:</span> <span>{item.category}</span></div>
          <div>Entry By: {item.entryby}</div>        
        </div>
      );
    case 'wpcAPAdmin' :
      return (
        <div className='columns2' >
          <div><span className='bold'>WPC:</span> <span>{(item.wpc) ? "Yes" : "No"}</span></div>
          <div>Entry By: {item.entryby}</div>        
        </div> 
      );

    case 'ssnAPAdmin' :
      return (
        <div className='columns2' >
          <div><span className='bold'>SSN:</span> <span>{item.ssn}</span></div>
          <div>Entry By: {item.entryby}</div>        
        </div>
      );

    case 'icd10APAdmin' :
      return (
        <div className='columns2' >
          <div><span className='bold'>ICD10:</span> <span>{item.icd10code}</span></div>
          <div>Entry By: {item.entryby}</div>        
        </div>
      );
  
    case 'healthPlanAPAdmin' :
      return (
        <div className='columns2' >
          <div><span className='bold'>Health Plan:</span> <span>{item.clinicempaneled}</span></div>
          <div>Entry By: {item.entryby}</div>        
        </div>
      );


    case 'dsmPPAdmin' :
      return (
        <div className='columns2' >
          <div><span className='bold'>DSM5DX:</span> <span>{item.dsm5dx}</span></div>
          <div>Entry By: {item.entryby}</div>        
        </div>
      );

    case 'medicalProblemsPPAdmin' :
      return (
        <div className='columns2'>
          <div><div className='bold'>Medical Problems:</div> <span dangerouslySetInnerHTML={{ __html: item.medicalproblems }}></span></div>
          <div><span className='bold'>Entry By: {item.entryby}</span></div>        
        </div>
      );

      case 'pathwayPPAdmin' :
        return (
          <div className='columns2'>
            <div><span className='bold'>Pathway:</span> <span>{item.pathway}</span></div>
            <div>Entry By: {item.entryby}</div>        
          </div>
        );

      case 'adherencePPAdmin' :
        return (
          <div className='columns2'>
            <div><span className='bold'>Adherence:</span> <span>{item.adherence}</span></div>
            <div>Entry By: {item.entryby}</div>        
          </div>
        );

        case 'releaseDatePPAdmin' :
          return (
            <div className='columns2'>
              <div><span className='bold'>Release Date:</span> <span>{d(item.releasedate)}</span></div>
              <div>Entry By: {item.entryby}</div>        
            </div>
          );        
        case 'isnumberPPAdmin' :
          return (
            <div className='columns2'>
              <div><span className='bold'>IS Number:</span> <span>{item.isnumber}</span></div>
              <div>Entry By: {item.entryby}</div>        
            </div>
          );

        case 'cinAPAdmin' :
          return (
            <div className='columns2'>
              <div><span className='bold'>CIN:</span> <span>{item.cin}</span></div>
              <div>Entry By: {item.entryby}</div>        
            </div>
          );

        case 'finAPAdmin' :
          return (
            <div className='columns2'>
              <div><span className='bold'>FIN:</span> <span>{item.fin}</span></div>
              <div>Entry By: {item.entryby}</div>        
            </div>
          );

        case 'finPPAdmin' :
          return (
            <div className='columns2'>
              <div><span className='bold'>FIN:</span> <span>{item.fin}</span></div>
              <div>Entry By: {item.entryby}</div>        
            </div>
          );


        case 'completionDatePPAdmin' :
          return (
            <div className='columns2'>
              <div><span className='bold'>Completion Date:</span> <span>{d(item.completiondate)}</span></div>
              <div>Entry By: {item.entryby}</div>        
            </div>
          );

      case 'consentsAPAdmin' :
        return (
          <div className='columns2'>
            <div><span className='bold'>Consents:</span> <span>{item.consents}</span></div>
            <div>Entry By: {item.entryby}</div>        
          </div>
        );

      case 'otherMedsPPAdmin' :
        return (
          <div className='columns2'>
            <div><span className='bold'>Other Meds:</span> <span>{item.othermeds}</span></div>
            <div><span className='bold'>Entry By: {item.entryby}</span></div>        
          </div>
        );
      case 'psychHistoryPPAdmin' :
        return (
          <div>
            <div><div className='bold'>Psych History:</div> <span dangerouslySetInnerHTML={{ __html: item.psychhistory }}></span></div>
            <div><span className='bold'>Entry By: {item.entryby}</span></div>        
          </div>
        );
      case 'psychotropicmedsPPAdmin' :
        return (
          <div>
            <div><div className='bold'>Psychotropic Meds:</div><span dangerouslySetInnerHTML={{ __html: item.psychotropicmeds }}></span></div>
            <div><span className='bold'>Entry By: {item.entryby}</span></div>        
          </div>
        );
      case 'acuityAPAdmin' :
        return (
          <div className='columns2'>
            <div><span className='bold'>Acuity:</span> <span>{item.acuity}</span></div>
            <div>Entry By: {item.entryby}</div>        
          </div>
        );
      case 'cidAPAdmin' :
        return (
          <div className='columns2'>
            <div><span className='bold'>CID:</span> <span>{item.cid}</span></div>
            <div>Entry By: {item.entryby}</div>        
          </div>
        );
      case 'ciiAPAdmin' :
        return (
          <div className='columns2'>
            <div><span className='bold'>CII:</span> <span>{item.cii}</span></div>
            <div>Entry By: {item.entryby}</div>        
          </div>
        );
      case 'mainAPAdmin' :
        return (
          <div className='columns2'>
            <div><span className='bold'>Main Four:</span> <span>{item.mainfour}</span></div>
            <div>Entry By: {item.entryby}</div>        
          </div>
        );
      case 'medicalDemographicsNotesAPAdmin' :
        return (
          <div>
            <div><div className='bold'>Medical Demographics Notes:</div> <span dangerouslySetInnerHTML={{ __html: item.note }}></span></div>
            <div><span className='bold'>Entry By: {item.entryby}</span></div>        
          </div>
        );

      case 'ecmContactsAPAdmin' :
        return (
          <div className='columns2'>
            <div><span className='bold'>Phone Number:</span> <span>{item.phone_number}</span></div>
            <div><span className='bold'>Address:</span> <span>{item.address}</span></div>
            <div>Entry By: {item.entryby}</div>        
          </div>
        );

      case 'ssnPPAdmin' :
        return (
          <div className='columns2'>
            <div><span className='bold'>SSN:</span> <span>{item.ssn}</span></div>
            <div>Entry By: {item.entryby}</div>        
          </div>
        );


  }    
}

export const Summary = (params: any)=>{
    const { user, isUserLoading } = useUser();
    const { participant: participantReactParams, submit } = params;
    
    const { participant: participantURLParams } :any = useParams();
    const participant = participantReactParams || participantURLParams;


    const [state, setState] = useState<any>({ nameHistory: []});
    const [ecmState, setEcmState] = useState<any>({});
    const [healthPlanState, setHealthPlanState] = useState<any>({});

    const [currentItem, setCurrentItem] = useState<any>('');
    

    const { loading, error, data, refetch } = useQuery(GET_PARTICIPANT_BY_ODR_NUMBER, {
        variables: { odrnumber: participant },
        onCompleted: (result) => {        
            const { ecm, healthplan, ...p } = result.getParticipantByODRNumberAdmin;
                        
            setState( {...p, nameHistory : p.nameHistory ||[], dobHistory: p.dobHistory||[] } )            
        },
    });

    

    if (loading || isUserLoading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;    
    

    //resort the data so that the keys are the dates and the values are the data
    //const currentData = state[currentItem]||[];
    
    
    
   
   //for each item in state we need to extract the date and create a new object with the date as the key and the data as the value
    const currentData = Object.keys(state).map((key, index) => {
        
         const value = state[key];
         const date = value.entrydate;
         return { date, value };
    });

    const currentDataKeys = (currentData.length) ? Object.keys(currentData[0]).filter(key => key !== '__typename' ) : [];

    const currentDataValues = Object.values(currentData)||[];
    const currentDataLenght = currentDataKeys.length;

    const report = convertToHash(state);
  
    
    

    return (
      <div > 
        {report && Object.keys(report).sort().map((key, index) => {
          const value = report[key];
          const className= (index % 2) ? 'alt' : 'white';
          return (            
            <div style={{ display: 'grid', gridTemplateColumns: '25rem 1fr' }} key = {index} className={`boxed`}>
              <div>{key}</div>
              <div>                
              {value.map((item: any, index: number) => {                

                const eventType = item.__typename;
                
                return (                    
                  <div style={{ paddingLeft: '2rem', paddingTop: '0.75rem', paddingBottom: '0.75rem'} } >
                     {/*<div style={{color:'red'}}>{tableNames(eventType)}</div>*/}
                    <div>
                    {eventFormater(eventType, item)}
                      
                    </div>
                  </div>                  
                );
              }
              )}
            </div>              
            </div>
          )        
          
        })}
      </div>)

} 