


import { useState, useEffect } from 'react';
import { useParams, useNavigate  } from 'react-router-dom';
import { useQuery, gql, useMutation } from '@apollo/client';

import { useUser } from '../../../store/useUser';


import {toast} from 'react-toastify';

const CustomDate = (props: any) => {
  const { value } = props;
  if (!value)
  return <div></div>;
  const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.\d{3}Z$/;
  const [, year, month, day, hour, minute, second] = value.match(regex) || [];


  if (!year) {
    // Handle invalid date format
    return <div>Invalid date format</div>;
  }

  const usaDateFormat = `${month}/${day}/${year}`;

  return <div>{usaDateFormat}</div>;
};

const GET_PARTICIPANT_BY_ODR_NUMBER = gql`
  query GetParticipantByODRNumberAdmin($odrnumber: Integer!) {
    getParticipantByODRNumberAdmin(odrnumber: $odrnumber) {
      ab109AP {uniqueid status entryby entrydate}
      acuityAP {uniqueid acuity entryby entrydate}
      champidAP {uniqueid champid entryby entrydate}
      cidAP {uniqueid cid entryby entrydate}
      ciiAP {uniqueid cii entryby entrydate}
      cinAP {uniqueid cin entryby entrydate}
      consentsAP {uniqueid entryby entrydate}
      ecmContactsAP {uniqueid phone_number address entryby entrydate}
      finAP {uniqueid fin entryby entrydate}
      healthPlanAP {uniqueid  clinicempaneled dhsempaneled ecm_assignment_date ecmmanager ecmprovider healthPlan healthplanabbr healthplancode pcpname entryby entrydate}
      icd10AP {uniqueid icd10code entryby entrydate}
      mainAP {uniqueid mainfour entryby entrydate}
      medicalDemographicsNotesAP {uniqueid note entryby entrydate}
      mrunAP {uniqueid mrun entryby entrydate}
      ssnAP {uniqueid ssn entryby entrydate}
      wpcAP {uniqueid wpc entryby entrydate}
      
      ab109PP {uniqueid status program entryby entrydate}
      adherencePP {uniqueid adherence program entryby entrydate}
      arrestDatePP {uniqueid arrestdate  program entryby entrydate}
      dispositionPP {uniqueid category  program entryby entrydate}
      champidPP {uniqueid champid entryby  program entrydate} 
      clinicalpresentationPP {uniqueid clinicalpresentation  program entryby entrydate}
      completionDate {uniqueid program entryby entrydate}
      dobPP {uniqueid dob  program entryby entrydate}
      dsmPP {uniqueid dsm5dx  program entryby entrydate}
      isnumberPP {uniqueid  program entryby entrydate}
      istPP {uniqueid  program entryby entrydate}
      locationPP {uniqueid location  program entryby entrydate}
      maxdatePP {uniqueid maxdate  program entryby entrydate}
      medicalProblemsPP {uniqueid medicalproblems  program entryby entrydate}
      mrunPP {uniqueid  mrun program entryby entrydate}
      namesPP {uniqueid  firstname lastname  program entryby entrydate}
      notesPP {uniqueid notes  program entryby entrydate}
      otherMedsPP {uniqueid othermeds  program entryby entrydate}
      pathwayPP {uniqueid  pathway program  entryby entrydate}
      providerPP {uniqueid provider program entryby entrydate}
      psychHistoryPP {uniqueid psychhistory program entryby entrydate}
      psychotropicmedsPP {uniqueid psychotropicmeds program entryby entrydate}
      releasedPP {uniqueid released program  entryby entrydate}
      releaseDatePP {uniqueid releasedate program entryby entrydate}
      sexoffenderPP {uniqueid sexoffender program entryby entrydate}
      
      substanceHistoryPP {uniqueid entryby entrydate}
       
    }
  }
`;

/*
ssnPP {uniqueid entryby entrydate}
*/
const UPDATE_PARTICIPANT_BY_ODR_NUMBER = gql`
  mutation UpdateParticipantByODRNumber($ODRNumber: Integer!, $updates: updateParticipantInput!) {
    updateParticipantByODRNumber(ODRNumber: $ODRNumber, updates: $updates) {     
      message     
  }}
`;



export const History = (params: any)=>{
    const { user, isUserLoading } = useUser();
    const { participant: participantReactParams, submit } = params;
    
    const { participant: participantURLParams } :any = useParams();
    const participant = participantReactParams || participantURLParams;


    const [state, setState] = useState<any>({ nameHistory: []});
    const [ecmState, setEcmState] = useState<any>({});
    const [healthPlanState, setHealthPlanState] = useState<any>({});

    const [currentItem, setCurrentItem] = useState<any>('');
    

    const { loading, error, data, refetch } = useQuery(GET_PARTICIPANT_BY_ODR_NUMBER, {
        variables: { odrnumber: participant },
        onCompleted: (result) => {        
            const { ecm, healthplan, ...p } = result.getParticipantByODRNumberAdmin;
                        
            setState( {...p, nameHistory : p.nameHistory ||[], dobHistory: p.dobHistory||[] } )            
        },
    });

    

    const [updateParticipant] = useMutation(UPDATE_PARTICIPANT_BY_ODR_NUMBER);

    if (loading || isUserLoading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;    
    
    const currentData = state[currentItem] || [{}];
    
    
    const currentDataKeys = (currentData.length) ? Object.keys(currentData[0]).filter(key => key !== '__typename' ) : [];

    const currentDataValues = Object.values(currentData)||[];
    const currentDataLenght = currentDataKeys.length;

   

    const deleteItem = async (id:any) => {
      //create a popup to confirm the delete
      //if the user clicks yes, then delete the item
      //if the user clicks no, then do nothing
      const result = window.confirm('Are you sure you want to delete this item?');
      if (result) {
        const { uniqueid } = currentData[id];
        const updates = { uniqueid, status: 'deleted' };

        console.log (currentData[id].uniqueid, currentItem)
        /*const { data, errors } = await updateParticipant({ variables: { ODRNumber: participant, updates } });
        if (errors) {
          toast.error('Error deleting item');
        } else {
          toast.success('Item deleted');
          refetch();
        }*/
      }

    }

    const d = (date:any)=>{
      return new Date(parseInt(date)).toLocaleDateString();
    }

    const currentItemName = currentItem.replace('AP', '').replace('PP', '').toUpperCase(currentItem);

    const ODRbuttons = [
      ['AB190', 'ab190AP'],
      ['Acuity', 'acuityAP'],
      ['Champ ID', 'champidAP'],
      ['CID', 'cidAP'],
      ['CII', 'ciiAP'],
      ['CIN', 'cinAP'],
      ['Consents', 'consentsAP'],
      ['Ecm Contacts', 'ecmContactsAP'],
      ['FIN', 'finAP'],
      ['Health Plan', 'healthPlanAP'],
      ['IDC10', 'icd10AP'],
      ['Main', 'mainAP'],
      ['Medical Demographics Notes', 'medicalDemographicsNotesAP'],
      ['MRUN', 'mrunAP'],
      ['SSN', 'ssnAP'],
      ['WPC', 'wpcAP'],
    ]

    const ProgramButtons = [
      ['AB109', 'ab109PP'],
      ['Adherence', 'adherencePP'],
      ['Arrest Date', 'arrestDatePP'],
      ['Disposition', 'dispositionPP'],
      ['Champ ID', 'champidPP'],
      ['Clinical Presentation', 'clinicalpresentationPP'],
      ['Completion Date', 'completionDate'],
      ['DOB', 'dobPP'],
      ['DSM', 'dsmPP'],
      ['IS Number', 'isnumberPP'],
      ['IST', 'istPP'],
      ['Location', 'locationPP'],
      ['Max Date', 'maxdatePP'],
      ['Medical Problems', 'medicalProblemsPP'],
      ['MRUN', 'mrunPP'],
      ['Names', 'namesPP'],
      ['Notes', 'notesPP'],
      ['Pther Meds', 'otherMedsPP'],
      ['Pathway', 'pathwayPP'],
      ['Provider', 'providerPP'],
      ['Psych History', 'psychHistoryPP'],
      ['Psychotropic Mds', 'psychotropicmedsPP'],
      ['Released', 'releasedPP'],
      ['Release Date', 'releaseDatePP'],
      ['Sex Offender', 'sexoffenderPP'],
      ['SSN', 'ssnPP'],
      ['Substance History', 'substanceHistoryPP']]

    return (
        <div>          
          <div className="columns2firstSmall">
            <div className='boxed'>
              <h2>ODR Tables</h2>
              {ODRbuttons.map(([name, value]) => {
                return <div style={{paddingBottom: '0.3rem'}} onClick={()=>{setCurrentItem(value)}}>{name}</div>
              })}
            
                
              <h2>Program Tables</h2>
              {ProgramButtons.map(([name, value]) => {
                return <div style={{paddingBottom: '0.3rem'}} onClick={()=>{setCurrentItem(value)}}>{name}</div>
              })}
                

            </div>
            <div className='boxed'> 
              <h4>{currentItemName}</h4>
              <div   style={{display: 'grid', gridTemplateColumns: `repeat(${currentDataLenght}, 1fr)`, }}>
                {currentDataKeys.map((key, index) => {
                  return (
                    <div key={index}>
                      <h5>{key}</h5>                      
                    </div>
                  );
                })}
                </div>

                {currentDataValues.map((value :any, index: number) => {
                  const className= (index % 2) ? 'alt' : 'white';

                  if (!value) return <div className={className}></div>;
                  const keys = Object.keys(value).filter(key => key !== '__typename' );

                  return (
                    <div style={{display: 'grid', gridTemplateColumns: `9fr 25px`}}>
                    <div key={index}  className={className} style={{display: 'grid', gridTemplateColumns: `repeat(${currentDataLenght}, 1fr)`, }}>
                      {keys.map((key, index) => {
                        if (key === 'entrydate') {
                          return (
                            <div key={index}>
                              <div> {d(value[key])} </div>
                            </div>
                          );
                        }


                        return (
                          <div key={index}>
                            <div>{value[key]}</div>
                          </div>
                        );
                      })}
                    </div>{user.groups.includes('superadmin') && <button className='button warning' onClick={()=>{deleteItem (index)}}>X</button>}
                    </div>
                  );
                })}
              

            
            
            </div>
          </div>
          


        </div>
            
    )
} 