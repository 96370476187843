import React, { useState, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import FormTemplate from './forms/oldIA.json';
import { FormView } from './lib/formView';
import { useParams } from 'react-router-dom';

export const settingsInitialAssessment = {
  withPrint: true,
}
 

const UPDATE_CALAIM_INITIAL_ASSESSMENT = gql`
  mutation UpdateCalAimInitialAssessment(
    $id: Int
    $data: CalAIMInitialAssessmentInput!
  ) {
    updateCalAIMInitialAssessment(id: $id, data: $data) {
      id
      
    }
  }
`;

const GET_CALAIM_INITIAL_ASSESSMENT = gql`
  query GetCalAIMInitialAssessment($documentID: Integer!) {
    getCalAIMInitialAssessment(documentID: $documentID) {      
      contents
      id
      metadata
      name
      odrparticipant      
    }
  }
`;



export const FormInitialAssessment = (params:any) => {
  const { id: ReactID } : any= params
  const { id: URLID } : any= useParams();  

  const id = ReactID || URLID;  
  const { loading, error, data } = useQuery(GET_CALAIM_INITIAL_ASSESSMENT, {
    variables: { documentID : id},
    onCompleted: (data) => {
      //console.log("data", data)
    }
  });

  const [state, setState] = useState({}) as any;

  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
      if (data && !loading) {
    console.log ("data", data)
        const newstate = {} as any;
        const form = JSON.parse(data.getCalAIMInitialAssessment.contents);
        //map those values to the form and any thing that maches dddd-dd-ddT trucate to dd-dd-dd
        for (let key in form) {
          newstate[key] = `${form[key]}`;
          if (form[key] && typeof form[key] === 'string' && /\d{4}-\d{2}-\d{2}T.*/.test(form[key])) {
            newstate[key] = form[key].substring(0, 10);
          } 
          if (form[key] && typeof form[key] === 'number') {
            newstate[key] = `${form[key]}`;
          }
          if (form[key] ==null) {
            newstate[key] = '';
          }

        }
        

        setState(newstate);
        console.log(newstate);
      }
  }, [data, loading]);

  if (loading) return <p>Loading...</p>;

  return (
    <div>
    
      <div>
        <FormView formTemplate={FormTemplate} formState={state} />
      </div>
    </div>
  );
};
