import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import {
  LIST_HEALTH_PLANS_BILLING_INFO,
  GET_HEALTH_PLAN_BILLING_INFO_BY_ID,
  UPDATE_HEALTH_PLAN_BILLING_INFO_BY_ID,
} from './queries'; // Import your GraphQL queries and mutations

import { ediHash as X } from './ediHash';
const ediHash = X as any;

export const HealthPlanManager = () => {
  // Define state variables
  const [selectedHealthPlan, setSelectedHealthPlan] = useState<any>(null);
  const [updatedHealthPlan, setUpdatedHealthPlan] = useState<any>({});

  // GraphQL queries and mutations
  const { loading, error, data, refetch } = useQuery(
    LIST_HEALTH_PLANS_BILLING_INFO
  );
  const [updateHealthPlan] = useMutation(UPDATE_HEALTH_PLAN_BILLING_INFO_BY_ID);

  const { loading: planLoading, data: planData } = useQuery(
    GET_HEALTH_PLAN_BILLING_INFO_BY_ID,
    {
      variables: { id: selectedHealthPlan ? selectedHealthPlan.id : null },
      skip: !selectedHealthPlan, // Skip the query if no health plan is selected
      onCompleted: (data) => {
        // Set the updated health plan to the selected health plan
        console.log('data', data);
        setUpdatedHealthPlan(data.getHealthPlanBillingInfoByID);
      }
    }
  );

  // Handle the selection of a health plan
  const handleHealthPlanSelection = (healthPlan: any) => {
    setSelectedHealthPlan(healthPlan);
    //setUpdatedHealthPlan(planData ? { ...planData.getHealthPlanBillingInfoById } : {});
    // Trigger a refetch of the main health plan data query when a health plan is selected
    //refetch();
  };

  // Handle updates and save changes
  const handleSaveChanges = () => {
    const updatedHealthPlanCopy = { ...updatedHealthPlan };
    delete updatedHealthPlanCopy.__typename;
    delete updatedHealthPlanCopy.id;
    delete updatedHealthPlanCopy.healthPlanName;

    updateHealthPlan({
      variables: {
        id: updatedHealthPlan.id,
        updates: updatedHealthPlanCopy,
      },
    })
      .then(() => {
       toast.success('Health plan updated successfully.');
      })
      .catch((error: any) => {
        console.error('Error updating health plan:', error);
      });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSaveChanges()
  };
  
  // Render the list of health plans and the edit form
  return (
    <div>
      <h1>Health Plan Manager</h1>

      <div style={{display:'grid', gridTemplateColumns:'25rem 1fr'}}>

            
        <div>
          <h2>Health Plans</h2>
          <p>Select a health plan to edit.</p>
          {loading && <p>Loading...</p>}
          {error && <p>Error loading health plans.</p>}
          {data && (
            <ul style={{ marginTop: '1.5rem' }}>
              {data.listHealthPlansBillingInfo.map((healthPlan: any) => (
                <li
                  key={healthPlan.id}
                  onClick={() => handleHealthPlanSelection(healthPlan)}
                >
                  {healthPlan.healthPlanName}
                </li>
              ))}
            </ul>
          )}
        </div>      

        
        <div className='boxed' >
          {selectedHealthPlan && (
            <div>
              <h2>Edit Health Plan: {selectedHealthPlan.healthPlanName}</h2>
              <form onSubmit={handleSubmit}>
                {planLoading ? (
                  <p>Loading health plan details...</p>
                ) : (
                  planData && (
                    <>
                      {Object.keys(ediHash).map((key) => (
                        <div key={key}>
                          <div
                            style={{
                              fontWeight: 'bold',
                              marginTop: '1.5rem',
                            }}
                          >
                            {ediHash[key] &&
                              `${ediHash[key].shortDescription} (${
                                ediHash[key].location
                              })`}
                          </div>
                          <div className="tooltip">
                            {ediHash[key] && ediHash[key].longDescription}
                          </div>
                          <input
                            type="text"
                            value={updatedHealthPlan[key] || ''}
                            onChange={(e) =>
                              setUpdatedHealthPlan({
                                ...updatedHealthPlan,
                                [key]: e.target.value,
                              })
                            }
                          />
                        </div>
                      ))}
                      <button type="submit" >
                        Save Changes
                      </button>
                    </>
                  )
                )}
              </form>
            </div>
          )}
        </div>
      </div>
      </div>    
  );
};
