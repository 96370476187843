import React, { useState, useEffect, useRef } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useAuthenticationState } from '../../store/useAuthenticationState';
import { DiscountModal } from './AutoLogoutComponents/discountModal';

const LOGIN_PASSWORD = gql`
    mutation LoginPassword($username: String!, $password: String!) {
        loginPassword(username: $username, password: $password)
    }
`;

const LOGIN_TWOFACTOR = gql`
    mutation LoginTwofactor($token: String!, $code: String!) {
        loginTwofactor(token: $token, code: $code)
    }
`;



export const Login = ({onSuccess} :any) => {
    const { authenticationState, setAuthenticationState } = useAuthenticationState();

    const [state, setState] = useState({
        username: '',
        password: '',
        error: '',
        twofactor: '',
        state : 'login',
        loading: false,
        token : ''
    })

    useEffect(() => {        
        setAuthenticationState(undefined);        
    }, []);

    const [loginPassword, { data: loginPasswordData, error: loginPasswordError }] = useMutation(LOGIN_PASSWORD);
    const [loginTwofactor, { data: loginTwofactorData, error: loginTwofactorError }] = useMutation(LOGIN_TWOFACTOR);

    const handleUsername = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log(state);
        const loginPasswordResult = await loginPassword({ variables: { username: state.username, password: state.password } });
        if (loginPasswordResult) {
            setState({ ...state, state: 'twofactor', token: loginPasswordResult.data.loginPassword });
        }
    }

    const handleTwofactor = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log(state);
        const loginTwofactorResult = await loginTwofactor({ variables: { token: state.token, code: state.twofactor } });
        if (loginTwofactorResult) {            
            setAuthenticationState( loginTwofactorResult.data.loginTwofactor );
            if (onSuccess) {                
                onSuccess();
            }
        }
    }


    return (
        <div>
            <DiscountModal>
            
            <img src={`${process.env.PUBLIC_URL}/logoODR.png`} alt="logo" style={{width: '200px', margin: '0 auto'}}/>
            <h2>Login</h2>
            {state.state =='login' &&
             <form onSubmit={handleUsername}>
                <div style={{textAlign: 'center'}}>
                <div style={{marginTop: '2rem'}}>
                    <label>Username </label>
                    <input type="text" value={state.username} onChange={(e) => setState({ ...state, username: e.target.value })} />
                    </div>
                    <div style={{marginTop: '2rem'}}>
                    <label>Password </label>
                    <input type="password" value={state.password} onChange={(e) => setState({ ...state, password: e.target.value })} />
                    </div>
                    <div></div>
                    <div style={{marginTop: '2rem'}}>
                        <button type="submit">Login</button>
                    </div>
                </div>
            </form>}

            {state.state =='twofactor' &&
             <form onSubmit={handleTwofactor}>
                <div>A code has been sent to your email address</div>
                <div style={{marginTop: '2rem'}}>Two Factor Code </div>
                <input type="text" value={state.twofactor} onChange={(e) => setState({ ...state, twofactor: e.target.value })} />
                <button style={{marginLeft: '2rem'}} type="submit">Login</button>
            </form>}
            </DiscountModal>
        </div>
    )
}