


import { useState, useEffect } from 'react';
import { useParams, useNavigate  } from 'react-router-dom';
import { useQuery, gql, useMutation } from '@apollo/client';
import { FormRenderer , initForm} from '../forms/lib/formRender';

import {toast} from 'react-toastify';

const CustomDate = (props: any) => {
  const { value } = props;
  if (!value)
  return <div></div>;
  const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.\d{3}Z$/;
  const [, year, month, day, hour, minute, second] = value.match(regex) || [];


  if (!year) {
    // Handle invalid date format
    return <div>Invalid date format</div>;
  }

  const usaDateFormat = `${month}/${day}/${year}`;

  return <div>{usaDateFormat}</div>;
};

const GET_PARTICIPANT_BY_ODR_NUMBER = gql`
  query GetParticipantByODRNumber($odrnumber: Integer!) {
    getParticipantByODRNumber(odrnumber: $odrnumber) {
       bookingNumber
       bookingHistory {
          booking          
          entrydate
        }   
        rso 
        mainNumber
        arrestDate
        arrestHistory 
        maxDate
        istStatus
        istStatusHistory {
          date
          status        
      }
    }      
  }
`;

const UPDATE_PARTICIPANT_BY_ODR_NUMBER = gql`
  mutation UpdateParticipantByODRNumber($ODRNumber: Integer!, $updates: updateParticipantInput!) {
    updateParticipantByODRNumber(ODRNumber: $ODRNumber, updates: $updates) {     
      message     
  }}
`;



export const Legal = (params: any)=>{
    const { participant: participantReactParams, submit } = params;
    
    const { participant: participantURLParams } :any = useParams();
    const participant = participantReactParams || participantURLParams;

    const [state, setState] = useState<any>({ nameHistory: []});

    const { loading, error, data, refetch } = useQuery(GET_PARTICIPANT_BY_ODR_NUMBER, {
        variables: { odrnumber: participant },
        onCompleted: (result) => {        
            const { ecm, healthplan, ...p } = result.getParticipantByODRNumber;
                        
            setState( {...p, nameHistory : p.nameHistory ||[], dobHistory: p.dobHistory||[] } )            
        },
    });

    

    const [updateParticipant] = useMutation(UPDATE_PARTICIPANT_BY_ODR_NUMBER);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    const participantData = data.getParticipantByODRNumber;
    


    return (
        <div>
            <h3>Legal</h3>
            <div className='columns6'>
                <div>
                    <div>Main Number</div>
                    <div>{participantData.mainNumber}</div>
                    <div>Max Date</div>
                    <CustomDate value={participantData.maxDate}/>
                    <div>RSO</div>
                    <div>{participantData.rso ?'Yes': 'No'}</div>
                    
                </div>
                <div>
                <div>IST Status</div>
                    <div>{participantData.istStatus ? 'Competent to stand trial' : 'Incompetent to stand trial'}</div>
                    <div>IST Status History</div>
                    {participantData.istStatusHistory && participantData.istStatusHistory.map((istStatus: any) => (
                      <div key={istStatus.date}className='columns2'>
                        <div>{istStatus.status ? 'Competent to stand trial' : 'Incompetent to stand trial'}</div>
                        <CustomDate value={istStatus.date}/> 
                        
                        </div>
                    ))}
                </div>

                <div>
                    <div>Booking Number</div>
                    <div>{participantData.bookingNumber}</div>
                    <div>Booking History</div>                    
                    {participantData.bookingHistory  && participantData.bookingHistory.map((booking: any) => (
                      <div className='columns2'>
                        <div key={booking.booking}>{booking.booking}</div>  <CustomDate value={booking.entrydate}/>
                        </div>
                    ))}
                    </div>
                <div>                    
                </div>

                <div>
                    <div>Arrest Date</div>
                    <div><CustomDate value={participantData.arrestDate}/></div>
                    <div>Arrest History</div>
                    <div>{participantData.arrestHistory && participantData.arrestHistory.map((date:any)=>{
                        <div key={date}>{date}</div>
                    })}</div>
                    <div>
                  </div>
                </div>
                <div>
    
                </div>

            </div>
        </div>
            
    )
} 