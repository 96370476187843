 
import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';

const GET_STATUS = gql`
    query GetStatus {
        status {
            serverName
            userAuthenticated
            userName
            version
        }
    }
`;

export function StatusPage() {
    //const [key, setKey] = useState('');
    const { loading, error, data } = useQuery(GET_STATUS);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    const { serverName, userAuthenticated, userName, version } = data.status;

  

    return (
        <div>
            <h1>Status</h1>
            <p>Server Name: {serverName}</p>
            <p>User Authenticated: {userAuthenticated ? 'Yes' : 'No'}</p>
            <p>User Name: {userName}</p>
            <p>Version: {version}</p>
           
        </div>
    );
}

