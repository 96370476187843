import React, { useCallback, useEffect, Fragment } from 'react';
import { gql, useMutation, useSubscription } from '@apollo/client';
import JSZip from 'jszip';
import { toast } from 'react-toastify';
import  ReactModal from 'react-modal';
import { GeneratePLevelReport } from './pLevelReport'


const UPDATE_PCODE = gql`
  mutation uploadPCodeDataExcel($base64String: String!) {
    uploadPCodeDataExcel(base64String: $base64String) {
      message
      responseCode
    }
  }
`;

const SUBSCRIPTION_UPLOAD_PCODE_DATA_EXCEL = gql`
  subscription {
    uploadPCodeDataExcel
    {
        source
        admit_date 
        release_date 
        facility 
        building 
        nurse_unit 
        pcode 
        pcode_source 
        clinician 
        pcode_date 
        prior_p_code 
        booking 
        fin 
        mrun 
        name 
        dob 
        race 
        sex 
        status
        error

    }
  }
`;

const ModelHeader = ({paricipant, title, onClose}:any)=>{
  return (
      <div className='modalHeader'>
         
          <div className='modalBanner'>
          <h2>{title}</h2>             
            <button className='modalClose' onClick={onClose}>X</button>
          </div>

          
      </div>
  )
}


const Details = ({ messages, error }: any) => {
  const gridColumns = '2rem repeat(3, 7rem) 10rem 10rem 5rem 10rem 5rem 10rem 10rem 5rem 5rem 5rem 4rem 10rem 10rem 5rem 4rem';

  const renderedMessages = React.useMemo(() => (
    messages.map((message: any, index: number) => {
      const colors = ['grey', 'green', 'red'];
      const color = colors[message.status];

      return (
        <div key={index} style={{ display: 'grid', gridTemplateColumns: gridColumns }} className={color =='grey'? 'alt' : 'white'}>
          <div><img src={`/ui/build/icons/${color}check.png`} alt='check' width='10px' /></div>
          <div>{message.booking}</div>
          <div>{message.fin}</div>
          <div>{message.mrun}</div>
          <div>{message.name}</div>
          <div>{message.clinician}</div>
          <div>{message.dob}</div>
          <div>{message.race}</div>
          <div>{message.sex}</div>
          <div>{message.facility}</div>
          <div>{message.building}</div>
          <div>{message.nurse_unit}</div>
          <div>{message.pcode}</div>
          <div>{message.pcode_source}</div>
          <div>{message.pcode_date}</div>
          <div>{message.prior_p_code}</div>
        </div>
      );
    })
  ), [messages, gridColumns]);

  return (
    <div>
      <div style={{ display: 'grid', gridTemplateColumns: gridColumns, fontWeight: 'bold' }}>
        <div></div>
        <div>Booking</div>
        <div>FIN</div>
        <div>MRN</div>
        <div>Name</div>
        <div>Clinician</div>
        <div>DOB</div>
        <div>RACE</div>
        <div>Sex</div>
        <div>Facility</div>
        <div>Building</div>
        <div>Nurse Unit</div>
        <div>PCode</div>
        <div>PCode Source</div>
        <div>PCode Date</div>
        <div>Prior PCode</div>
      </div>
      {renderedMessages}
    </div>
  );
};


export const CSVImportPCode = () => {
  const [updatePCode] = useMutation(UPDATE_PCODE);
  const { data: subscriptionData } = useSubscription(SUBSCRIPTION_UPLOAD_PCODE_DATA_EXCEL);

  const [errors, setErrors] = React.useState([]) as any;
  const [messages, setMessages] = React.useState([]) as any;
  const [loading, setLoading] = React.useState(false);

  const [pLevelReportModal, setPLevelReportModal] = React.useState(false);

  useEffect(() => {
    // Handle incoming subscription data
    if (subscriptionData && subscriptionData.uploadPCodeDataExcel) { 

      setLoading (false);
      setMessages((prev:any) => [...prev, ...subscriptionData.uploadPCodeDataExcel]);
      
      subscriptionData.uploadPCodeDataExcel.map ((message:any) => {
        if (message.status === 2){
          toast.error( message.error );        
          setErrors((prev:any) => [...prev, message]);
        }
      })

      // Add your logic to handle the subscription data here
    }
  }, [subscriptionData]);

  const handleUpload = useCallback(async (e: any) => {
    if (!window.confirm('Are you sure you want to upload this file?')) {
      return;
    }

    setErrors([]);
    setMessages([]);
    setLoading (true);
    const file = e.target.files[0]; // Get the file directly from the event

    if (!file) return;

    const zip = new JSZip();
    zip.file(file.name, await file.arrayBuffer());

    const zippedContent = await zip.generateAsync({ type: 'uint8array' });

    // Convert Uint8Array to base64 string using a FileReader
    const base64String = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result?.toString().split(',')[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(new Blob([zippedContent], { type: 'application/zip' }));
    });

    try {
      const response = await updatePCode({
        variables: { base64String },
      });

      
    } catch (error) {
      console.error('Upload error:', error);
    }
  }, [updatePCode]);

  if (loading) return <p>Loading...</p>;

  return (
    <div>
      <div style={{ display: 'grid', gridTemplateColumns: '80% 1fr' }}>
        <h1>PCode Upload Spreadsheet</h1>
        <input type="file" accept=".csv" onChange={handleUpload} />
      </div>

      <button onClick={() => setPLevelReportModal(true)}>Refresh PLevel Report</button>
      {errors.length != 0 && <div className='boxed'>
        <h2>Errors</h2>  
        
          <Details messages={errors} error={true}/>
        
      </div>}
      
      <div className='boxed'>
      <h2>Messages</h2>
      <div >
        <Details messages={messages} />
      </div>
      </div>

      {pLevelReportModal && <ReactModal isOpen={pLevelReportModal} onRequestClose={() => setPLevelReportModal(false)}>
        <Fragment>
          <ModelHeader title='PLevel Report' onClose={() => setPLevelReportModal(false)} />
          <GeneratePLevelReport />
        </Fragment>
        </ReactModal>}     

    </div>
  );
};
