import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useMutation, gql, useQuery } from "@apollo/client";
import Select from "react-select";

const UPDATE_PARTICIPANT_PROGRAM_DATA = gql`
  mutation updateParticipantProgramDataByProgramNumber(
    $programNumber: Integer!
    $updates: participantProgramDataInput
  ) {
    updateParticipantProgramDataByProgramNumber(
      programNumber: $programNumber
      updates: $updates
    ) {
      responseCode
      message
    }
  }
`;



export const EditMedical = ({
  isOpen,
  onClose,
  refetch,
  programNumber,
  medicalData,
  icd10Options  
}: {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  programNumber: number;
  medicalData: any;
  icd10Options: any;
}) => {
  const [formData, setFormData] = useState({
    icd10Codes: [] as { value: string; label: string }[],
    psychotropicMeds: "",
    otherMeds: "",
    clinicalPresentation: "",
    psychHistory: "",
    medicalProblems: "",
    substanceHistory: "",
    currentMeds: "",
    dsm5dx: "",
  });
  const [updateParticipantProgramDataByProgramNumber] = useMutation(
    UPDATE_PARTICIPANT_PROGRAM_DATA
  );


  useEffect(() => {
    if (medicalData ) {
      

      setFormData({
        icd10Codes: medicalData.icd10Codes
          ? medicalData.icd10Codes.map(
              (code: string) =>
                icd10Options.find((option: any) => option.value === code) || {
                  value: code,
                  label: code,
                }
            )
          : [],
        psychotropicMeds: medicalData.psychotropicMeds || "",
        otherMeds: medicalData.otherMeds || "",
        clinicalPresentation: medicalData.clinicalPresentation || "",
        psychHistory: medicalData.psychHistory || "",
        medicalProblems: medicalData.medicalProblems || "",
        substanceHistory: medicalData.substanceHistory || "",
        currentMeds: medicalData.currentMeds || "",
        dsm5dx: medicalData.dsm5dx || "",
      });
    }
  }, [medicalData ]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleIcd10Change = (selectedOptions: any) => {
    setFormData((prev) => ({ ...prev, icd10Codes: selectedOptions }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const icd10Codes = formData.icd10Codes.map((code: any) => code.value);
    try {
      const result = await updateParticipantProgramDataByProgramNumber({
        variables: {
          programNumber,
          updates: {
            ...formData,
            icd10Codes: undefined,
            icd10: icd10Codes
          },
        },
      });
      if (
        result.data.updateParticipantProgramDataByProgramNumber.responseCode ===
        "SUCCESS"
      ) {
        console.log("Update successful");
        refetch();
        onClose();
      } else {
        console.error(
          "Update failed:",
          result.data.updateParticipantProgramDataByProgramNumber.message
        );
      }
    } catch (error) {
      console.error("Error updating medical data:", error);
    }
  };



  return (
    <ReactModal
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        content: {
          position: "relative",
          background: "white",
          padding: "20px",
          maxHeight: "80vh",
          overflowY: "scroll",
          width: "100%",
          maxWidth: "800px",
          inset: 0,
        },
      }}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <div style={{ marginBottom: "1.5rem" }}>
        <div
          style={{ display: "flex", justifyContent: "end", alignItems: "end" }}
        >
          <button type="button" onClick={onClose}>
            Close
          </button>
        </div>
        <h2
          style={{
            fontSize: "1.875rem",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Edit Medical Information
        </h2>
        <form
          onSubmit={handleSubmit}
          style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          <div style={{ display: "grid", gridTemplateColumns: "10rem 1fr" }}>
            <label htmlFor="icd10Codes">ICD10 Codes:</label>
            <Select
              isMulti
              name="icd10Codes"
              options={icd10Options}
              value={formData.icd10Codes}
              onChange={handleIcd10Change}
              placeholder="Search and select ICD10 codes..."
            />
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "10rem 1fr" }}>
            <label>DSM5 Diagnosis:</label>
            <textarea
              name="dsm5dx"
              value={formData.dsm5dx}
              onChange={handleInputChange}
              rows={4}
              cols={65}
            />
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "10rem 1fr" }}>
            <label>Psychotropic Medication:</label>
            <textarea
              name="psychotropicMeds"
              value={formData.psychotropicMeds}
              onChange={handleInputChange}
              rows={4}
              cols={65}
            />
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "10rem 1fr" }}>
            <label>Other Medication:</label>
            <textarea
              name="otherMeds"
              value={formData.otherMeds}
              onChange={handleInputChange}
              rows={4}
              cols={65}
            />
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "10rem 1fr" }}>
            <label>Current Medication:</label>
            <textarea
              name="currentMeds"
              value={formData.currentMeds}
              onChange={handleInputChange}
              rows={4}
              cols={65}
            />
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "10rem 1fr" }}>
            <label>Clinical Presentation:</label>
            <textarea
              name="clinicalPresentation"
              value={formData.clinicalPresentation}
              onChange={handleInputChange}
              rows={4}
              cols={65}
            />
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "10rem 1fr" }}>
            <label>Psychiatric History:</label>
            <textarea
              name="psychHistory"
              value={formData.psychHistory}
              onChange={handleInputChange}
              rows={4}
              cols={65}
            />
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "10rem 1fr" }}>
            <label>Medical Problems:</label>
            <textarea
              name="medicalProblems"
              value={formData.medicalProblems}
              onChange={handleInputChange}
              rows={4}
              cols={65}
            />
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "10rem 1fr" }}>
            <label>Substance History:</label>
            <textarea
              name="substanceHistory"
              value={formData.substanceHistory}
              onChange={handleInputChange}
              rows={4}
              cols={65}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}
          >
            <button type="submit">Update</button>
          </div>
        </form>
      </div>
    </ReactModal>
  );
};
