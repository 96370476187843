import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { isoDateToMDY } from "../../../utils/common";
import { useMutation, gql } from "@apollo/client";


const UPDATE_PARTICIPANT_PROGRAM_DATA = gql`
  mutation updateParticipantProgramDataByProgramNumber($programNumber: Integer!, $updates: updateParticipantInput!) {
    updateParticipantProgramDataByProgramNumber(programNumber: $programNumber, updates: $updates) {
      success
    }
  }
`;

export const EditLegalCase = ({
  isOpen,
  onClose,
  programNumber,
  caseData,
  refetch,
}: {
  isOpen: boolean;
  onClose: () => void;
  programNumber: number;
  caseData: any;
  refetch: () => void;
}) => {
  const [formData, setFormData] = useState({
    casenumber: "",
    caseopen: false,
    case_entrydate: "",
    charges: [],
    hearings: {
      hearingdate: "",
      hearingdetails: "",
      hearing_entrydate: "",
    },
  });
  const [updateParticipantProgramData] = useMutation(UPDATE_PARTICIPANT_PROGRAM_DATA);

  useEffect(() => {
    if (caseData) {
      setFormData({
        casenumber: caseData.casenumber || "",
        caseopen: caseData.caseopen || false,
        case_entrydate: caseData.case_entrydate || "",
        charges: caseData.charges || [],
        hearings: {
          hearingdate: caseData.hearings?.hearingdate || "",
          hearingdetails: caseData.hearings?.hearingdetails || "",
          hearing_entrydate: caseData.hearings?.hearing_entrydate || "",
        },
      });
    }
    console.log(caseData);
  }, [caseData]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "caseopen") {
      setFormData((prev: any) => ({ ...prev, [name]: value === "true" }));
    } else if (name.startsWith("hearings.")) {
      const hearingField = name.split(".")[1];
      setFormData((prev: any) => ({
        ...prev,
        hearings: { ...prev.hearings, [hearingField]: value },
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleChargeChange = (index: number, value: string) => {
    setFormData((prev: any) => {
      const newCharges = [...prev.charges];
      newCharges[index] = { ...newCharges[index], charge: value };
      return { ...prev, charges: newCharges };
    });
  };

  const addCharge = () => {
    setFormData((prev: any) => ({
      ...prev,
      charges: [
        ...prev.charges,
        { charge: "", charge_entrydate: new Date().toISOString() },
      ],
    }));
  };

  const removeCharge = (index: number) => {
    setFormData((prev) => {
      const newCharges = [...prev.charges];
      newCharges.splice(index, 1);
      return { ...prev, charges: newCharges };
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Here you would typically send the updated data to your backend
    console.log("Updated case data:", formData);
    onClose();
  };

  return (
    <ReactModal
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        content: {
          position: "relative",
          background: "white",
          padding: "20px",
          maxHeight: "80vh",
          overflowY: "scroll",
          width: "100%",
          maxWidth: "600px",
          inset: 0,
        },
      }}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <div style={{ marginBottom: "1.5rem" }}>
        <div
          style={{ display: "flex", justifyContent: "end", alignItems: "end" }}
        >
          <button type="button" onClick={onClose}>
            Close
          </button>
        </div>
        <h2
          style={{
            fontSize: "1.875rem",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Edit Legal Case
        </h2>
        <form
          onSubmit={handleSubmit}
          style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "0.5rem" }}>
            <label>Case Number:</label>
            <input
              type="text"
              name="casenumber"
              value={formData.casenumber}
              onChange={handleInputChange}
            />
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "0.5rem" }}>
            <label>Case Open:</label>
            <select
              name="caseopen"
              value={formData.caseopen ? "true" : "false"}
              onChange={handleInputChange}
            >
              <option value="true">Open</option>
              <option value="false">Closed</option>
            </select>
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "0.5rem" }}>
            <label>Case Entry Date:</label>
            <input
              type="date"
              name="case_entrydate"
              value={formData.case_entrydate}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label>Charges:</label>
            {formData?.charges?.map((charge: any, index: number) => (
              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "0.5rem" }}>
                <input
                  type="text"
                  value={charge.charge}
                  onChange={(e) => handleChargeChange(index, e.target.value)}
                />
                <div><button type="button" onClick={() => removeCharge(index)}>
                  Remove
                </button></div>
              </div>
            ))}
            <div><button
              style={{ display: "block", marginTop: "0.5rem" }}
              type="button"
              onClick={addCharge}
            >
              Add Charge
            </button></div>
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "0.5rem" }}>
            <label>Next Hearing Date:</label>
            <input
              type="date"
              name="hearings.hearingdate"
              value={formData.hearings.hearingdate}
              onChange={handleInputChange}
            />
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "0.5rem" }}>
            <label>Hearing Details:</label>
            <textarea
              name="hearings.hearingdetails"
              value={formData.hearings.hearingdetails}
              onChange={handleInputChange}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}
          >
            <button type="submit">Update Case</button>
          </div>
        </form>
      </div>
    </ReactModal>
  );
};
