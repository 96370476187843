 
import React from 'react';

import { Help } from './help';

export const EditorDlg = (params: any) => {
  const { item, SaveNode, index} = params;
  
  let method= 'edit'
  
  
  const [node, setNode] = React.useState(item) as any;
  const [nodeIndex, setNodeIndex] = React.useState(item) as any;
  const [selectedType, setSelectedTypeFinal] = React.useState(item.type) as any;

  const [options , setOptions] = React.useState( node.options || node.items|| []) as any;
  
  const [currentOption, setCurrentOption] = React.useState('') as any;

  const [currentOptionLabel, setCurrentOptionLabel] = React.useState('') as any;
  const [currentOptionValue, setCurrentOptionValue] = React.useState('') as any;

  const setSelectedType = (value: any) => {
    setSelectedTypeFinal(value);
    setNode({ ...node, type: value });
  }

  React.useEffect(() => {
    // Update the node state whenever the item prop changes
    setNode(item);
    setSelectedTypeFinal(item.type);
    setOptions(item.options || item.items || []);
    setNodeIndex(index);
  }, [item]);

  if (item==null) return (<div></div>)

  
  

  const handleChange = (e: any) => {
    setNode({ ...node, [e.target.name]: e.target.value });    
  }
  

  const handleSave = () => {
    if (selectedType == 'listInput') {
      node.options = options;
    }
    if (selectedType == 'bulletList') {
      node.items = options;
    }

    SaveNode(node, nodeIndex)
  }

  const moveNodeOption = (index: number, direction: string) => {
    const newOptions = [...options];
    const temp = newOptions[index];
    if (direction == 'up' && index == 0) return;
    if (direction == 'down' && index == newOptions.length - 1) return;

    if (direction == 'up') {
      newOptions[index] = newOptions[index - 1];
      newOptions[index - 1] = temp;
    } else {
      newOptions[index] = newOptions[index + 1];
      newOptions[index + 1] = temp;
    }
    setOptions(newOptions);
  }



  return (
    <div >
    
      <div >
        {method =='newItem'&& <h2>Create New Item</h2>}        
        {method =='edit'&& <h2>Edit Item</h2>}        
                
        <div style={{ display: 'grid', gridTemplateColumns: '10rem 20rem', paddingBottom: '2rem'}}>
          <label>Type</label>
              <select value={selectedType}  onChange={(e)=>{setSelectedType(e.target.value)}}   >                  
                  <option>----Text---</option>
                  <option value='bulletList'>Bullet List</option>
                  <option value='bullet'>Bullet</option>
                  <option value='heading'>Heading</option>
                  <option value='paragraph'>Paragraph</option>
                  <option value='section'>Section</option>                  
                  <option>----Inputs---</option>
                  <option value='dateInput'>Date Input</option>
                  <option value='textInput'>Text Input</option>
                  <option value='textAreaInput'>Text Area Input</option>
                  <option value='staticInput'>Static Text Input</option>
                  <option value='yesnoInput'>Yes No Input</option>                        
                  <option value='listInput'>List Input</option>                        
              </select>
                            
              </div>
      
        {/* Render the text box for static text items */}
        { (selectedType == 'section' || selectedType === 'heading' ||  selectedType=='paragraph' || selectedType=='bullet') &&   
          <div style={{ display: 'grid', gridTemplateColumns: '10rem 20rem'}}>
            <label>Text</label>
            <input name='text' value={node.text} onChange={handleChange} />
          </div>}


        {/* Render the label box for most items */}
        { (selectedType == 'textInput' || selectedType=='yesnoInput' || selectedType=='staticInput' || selectedType=='textAreaInput' || selectedType=='dateInput' || selectedType=='listInput' ) && 
        
          <div style={{ display: 'grid', gridTemplateColumns: '10rem 20rem'}}>
          <label>Label</label>
          <input name='label' value={node.label} onChange={handleChange} />          
        </div>}


        {/* Render the key box for inputs */}
        { (selectedType == 'textInput' || selectedType=='yesnoInput' || selectedType=='staticInput' || selectedType=='textAreaInput'|| selectedType=='listInput' || selectedType=='dateInput' || selectedType=='listInput') && 
        
          <div style={{ display: 'grid', gridTemplateColumns: '10rem 20rem'}}>
          <label>Key</label>
          <input name='key' value={node.key||''} onChange={handleChange} />
          <label>Required</label>
          <select name='required' value={node.required||0} onChange={handleChange}>
            <option value={1}>Required</option>
            <option value={0}>Optional</option>
          </select>
          </div>

        }

        { (selectedType == 'listInput') && 
          <div>            
            <div style={{paddingTop:'2rem'}}>Options</div>
            <div>
              <div key={index} style={{display:'grid', gridTemplateColumns:'8rem 8rem 1fr', gridGap: '1rem'}}>
                <label>Label</label>
                <label>Value</label>
                <div></div>

                <input value={currentOptionLabel} onChange={(e)=>{setCurrentOptionLabel(e.target.value)}}/>
                <input value={currentOptionValue} onChange={(e)=>{setCurrentOptionValue(e.target.value)}}/> 
                <div><button onClick={()=>{
                      setOptions([...options, {label: currentOptionLabel, value: currentOptionValue}]) 
                      setCurrentOptionLabel('')
                      setCurrentOptionValue('') }}>Add</button></div>
              </div>
              <ul>
                {options.map((option: any, index: number) => {
                  return (
                    <div key={index} style={{display:'grid', gridTemplateColumns:'25px 25px 8rem 8rem 1fr'}}>
                      <div><button className='editbutton' onClick={()=>{moveNodeOption (index, 'up')}}>▲</button></div>
                      <div><button className='editbutton' onClick={()=>{moveNodeOption (index, 'down')}}>▼</button></div>


                      <div>{option.label}</div>
                      <div>{option.value}</div>
                      <div><button onClick={() => {
                        const newOptions = options.filter((_ :any, optionIndex:any ) => optionIndex !== index);
                        setOptions(newOptions);
                        
                      }}>X</button></div>
                    
                    </div>
                  );
                })}
              </ul>
            </div>
          </div>
        }

        { (selectedType=='bulletList') && 
          <div>
            <div style={{paddingTop:'2rem'}}>Options</div>
            <div>
              <div>
                <input value={currentOption} onChange={(e)=>{setCurrentOption(e.target.value)}}/>
                <button onClick={()=>{
                  setOptions([...options, {text: currentOption}]) ;
                  setCurrentOption('')                   
                  }}>Add</button>
              </div>
              <ul>
                {options.map((option: any, index: number) => {
                  return (
                    <div style={{display: 'grid', gridTemplateColumns: '25px 45px 10rem  25px'}} key={index}>
                      <div><button className='editbutton' onClick={()=>{moveNodeOption (index, 'up')}}>▲</button></div>
                      <div><button className='editbutton' onClick={()=>{moveNodeOption (index, 'down')}}>▼</button></div>
                      {option.text}
                      <div><button className='editbutton' onClick={() => {
                        const newOptions = options.filter((_ :any, optionIndex:any ) => optionIndex !== index);
                        setOptions(newOptions);
                                                
                      }}>X</button></div>
                      
                    </div>
                  );
                })}
              </ul>
            </div>
          </div>
        }
        
      </div>
      <div>
        {method == 'edit' && <button onClick={handleSave}>Update</button>}
        {method == 'newItem' && <button onClick={handleSave}>Save</button>}        
      </div>
    
    <div style={{paddingTop:'10rem'}}><Help inputType={selectedType}/></div>
    </div>
  )
}

