import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import Modal from 'react-modal';
import { useHelpTopics } from '../../../store/useHelpTopics';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// GraphQL Queries and Mutations
const GET_HELP_TOPICS = gql`
  query GetHelpTopics {
    listHelpTopics {
      id
      name
    }
  }
`;

const GET_HELP_TOPIC = gql`
  query GetHelpTopic($id: Integer!) {
    getHelpTopic(id: $id) {
      id
      name
      content
    }
  }
`;

const CREATE_HELP_TOPIC = gql`
  mutation CreateHelpTopic($name: String!, $content: String!) {
    createHelpTopic(name: $name, content: $content) {
      id
      name
      content
    }
  }
`;

const UPDATE_HELP_TOPIC = gql`
  mutation UpdateHelpTopic($id: Integer!, $updates: UpdateHelpTopicInput!) {
    updateHelpTopic(id: $id, updates: $updates) {      
      message
    }
  }
`;

const HTMLInput = ({ name, value, onChange }: any) => {
  const [html, setHtml] = React.useState(value || '');
  const quillRef = React.useRef(null);
  const isInitialRender = React.useRef(true);

  const handleChange = React.useCallback((content: any) => {
    setHtml(content);
    if (!isInitialRender.current) {
      onChange({ target: { name, value: content } });
    }
  }, [name, onChange]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    }
  }, []);

  useEffect(() => {
    if (value !== html) {
      setHtml(value || '');
      isInitialRender.current = true
    }
  }, [value, html]);

  return (
    <div>
      <ReactQuill
        ref={quillRef}
        value={html}
        onChange={handleChange}
      />
    </div>
  );
};
// Main Component
export const HelpTopicsManager = () => {
  const [selectedTopic, setSelectedTopic] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState<'create' | 'edit'>('create');
  const [newTopicName, setNewTopicName] = useState('');
  const [newTopicContent, setNewTopicContent] = useState('');

  const { pushHelpTopic, popHelpTopic } = useHelpTopics();
  
  useEffect(() => {
    pushHelpTopic('HelpTopics');
    return () => {
      popHelpTopic();
    };
  }, []);

  const { loading: topicsLoading, error: topicsError, data: topicsData, refetch: refetchTopics } = useQuery(GET_HELP_TOPICS);
  const { loading: topicLoading, error: topicError, data: topicData } = useQuery(GET_HELP_TOPIC, {
    variables: { id: selectedTopic },
    skip: !selectedTopic,
  });

  const [createHelpTopic] = useMutation(CREATE_HELP_TOPIC);
  const [updateHelpTopic] = useMutation(UPDATE_HELP_TOPIC);

  useEffect(() => {
    if (topicData && topicData.getHelpTopic) {
      setNewTopicName(topicData.getHelpTopic.name);
      setNewTopicContent(topicData.getHelpTopic.content);
    }
  }, [topicData]);

  const handleCreateTopic = async () => {
    try {
      await createHelpTopic({
        variables: { name: newTopicName, content: newTopicContent },
      });
      setNewTopicName('');
      setNewTopicContent('');
      refetchTopics();
      closeModal();
    } catch (error) {
      console.error('Error creating topic:', error);
    }
  };

  const handleUpdateTopic = async () => {
    try {
      await updateHelpTopic({
        variables: {
          id: selectedTopic,
          updates: {
            name: newTopicName,
            content: newTopicContent,
          },
        },
      });
      refetchTopics();
      closeModal();
    } catch (error) {
      console.error('Error updating topic:', error);
    }
  };

  const handleSelectTopic = (id: any) => {
    setSelectedTopic(id);
    setModalMode('edit');
    setIsModalOpen(true);
  };

  const openCreateModal = () => {
    setModalMode('create');
    setNewTopicName('');
    setNewTopicContent('');
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedTopic(null);
  };

  if (topicsLoading) return <p>Loading topics...</p>;
  if (topicsError) return <p>Error loading topics: {topicsError.message}</p>;

  return (
    <div>
      <h1>Help Topics Manager</h1>
      
      {/* Create New Topic Button */}
      <button onClick={openCreateModal}>Create New Topic</button>

      {/* List Topics */}
      <div>
        <h2>Topics List</h2>
        <ul>
          {topicsData.listHelpTopics.map((topic: any) => (
            <li key={topic.id} onClick={() => handleSelectTopic(topic.id)}>
              {topic.name}
            </li>
          ))}
        </ul>
      </div>

      {/* Modal for Create/Edit Topic */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel={modalMode === 'create' ? 'Create New Topic' : 'Edit Topic'}
      > <div style={{display: 'grid', gridTemplateColumns:'95% 1fr'}}>        
        <div><button onClick={modalMode === 'create' ? handleCreateTopic : handleUpdateTopic}>
                {modalMode === 'create' ? 'Create Topic' : 'Save Changes'}
              </button></div>
        <div><button onClick={closeModal}>Close</button></div>
        </div>
        <h2>{modalMode === 'create' ? 'Create New Topic' : 'Edit Topic'}</h2>
        {modalMode === 'edit' && topicLoading ? (
          <p>Loading topic...</p>
        ) : (
          <>
            <div>
              <input
                type="text"
                placeholder="Topic Name"
                value={newTopicName}
                onChange={(e) => setNewTopicName(e.target.value)}
              />
            </div>
            <HTMLInput
              name="content"
              value={newTopicContent}
              onChange={(e:any) => setNewTopicContent(e.target.value)}
            />
            <div>
              <button onClick={modalMode === 'create' ? handleCreateTopic : handleUpdateTopic}>
                {modalMode === 'create' ? 'Create Topic' : 'Save Changes'}
              </button>
              <button onClick={closeModal}>Cancel</button>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};