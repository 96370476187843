import React from "react";
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";
import { isoDateToMDY } from "../../utils/common";
import { CreateEnrollment } from "./modals/CreateEnrollment";
import { EditEnrollment } from "./modals/EditEnrollment";

const GET_ENROLLMENT_DATA = gql`
  query GetEnrollmentData($programNumber: Integer!) {
    getParticipantProgramDataByProgramNumber(programNumber: $programNumber) {
      enrollment {
        id
        site
        enrollmentDate
        exitDate
        exitReason
        notes
        referralType
        referringIndividual
        referringAgency
      }
    }
  }
`;

export const Enrollment = ({ programNumber }: { programNumber: number }) => {
  const { loading, error, data } = useQuery(GET_ENROLLMENT_DATA, {
    variables: { programNumber },
    skip: !programNumber,
  });

  const [showCreateEnrollment, setShowCreateEnrollment] = React.useState({show: false, data: null, method:'none'});

  if (loading) return <p>Loading enrollment data...</p>;
  if (error) return <p>Error loading enrollment data :(</p>;
  if (!data) return null;


  const enrollmentData = data.getParticipantProgramDataByProgramNumber.enrollment;

  const style = {
    display: "grid",
    gridTemplateColumns: "12rem 12rem 12rem 12rem 12rem 12rem 12rem 12rem",
    gap: "0.5rem",
  };

  return (
    <div>
        <button onClick={() => setShowCreateEnrollment({show: true, data: null, method:'create'})}>Add Enrollment</button>
        <div style={{ ...style, fontWeight: "bold", marginBottom: "0.5rem", marginTop: "1rem" }}>
            <div>Enrollment Date</div>
            <div>Site</div>
            <div>Refering Agency</div>
            <div>Refering Individual</div>
            <div>Referal Type</div>
            <div>Exit Date</div>
            <div>Notes</div>            
          </div>
          {enrollmentData.map((enrollment : any, index : any) => (
            <div key={index} style={{...style, borderTop: "1px solid #ccc", marginTop: "0.5rem", paddingTop: "2px"}}>
              <div>{isoDateToMDY(enrollment.enrollmentDate) || ""}</div>
              <div>{enrollment.site || ""}</div>
              <div>{enrollment.referringAgency || ""}</div>
              <div>{enrollment.referringIndividual || ""}</div>
              <div>{enrollment.referralType || ""}</div>
              <div>{isoDateToMDY(enrollment.exitDate) || ""}</div>
              <div>{enrollment.notes || ""}</div>              
              <div><button onClick={() => setShowCreateEnrollment({show: true, data: enrollment, method:'edit'})}>Edit</button></div>
            </div>            
          ))}
          <div style={{...style, borderBottom: "1px solid #ccc", marginTop: "0.5rem"}}></div>
          {showCreateEnrollment.method === 'create' && <CreateEnrollment participant={programNumber} onClose={() => setShowCreateEnrollment({show: false, data: null, method:'none'})} data={showCreateEnrollment.data}/>}
          {showCreateEnrollment.method === 'edit' && <EditEnrollment participant={programNumber} onClose={() => setShowCreateEnrollment({show: false, data: null, method:'none'})} data={showCreateEnrollment.data}/>}
    </div>  
  );
};