import React from "react";
import { Link } from "react-router-dom";
import { useUser } from "../../store/useUser";

export const Notifications = ({ programNumber }: { programNumber: number }) => {
  const { user } = useUser();

  const canEditNotifications = user.access?.includes("EditNotifications");
  const canSendProviderEmail = user.access?.includes("SendProviderEmail");
  const isAdmin = user.groups?.includes("admin");

  return (
    <div>
      <hr />
      <br />
      <table border={0} align="center" cellPadding={5} width="80%" rules="rows">
        <tbody>
          {!canEditNotifications && (
            <tr>
              <td>
                <Link to="/edit_sendmail.php">
                  <div>Edit Email Details</div>
                </Link>
                <br />
                <br />
                {isAdmin && (
                  <Link to="/notifications/email/create_new_template_email.php?program=mist">
                    <div>Create New Template</div>
                  </Link>
                )}
                <br />
                <br />
              </td>
            </tr>
          )}
          <tr>
            <td>
              {!canSendProviderEmail && (
                <>
                  <p>
                    <strong>
                      For use by
                      <br />
                      Providers:
                    </strong>
                  </p>
                  <Link to="/sendmail.php?for=mist_pickup">
                    <div>Email Notification of Pick-Up - MIST</div>
                  </Link>
                  <br />
                  <br />
                </>
              )}
            </td>
            <td>
              <div style={{ borderLeft: "1px solid black", height: "430px" }}>
                &nbsp;
              </div>
            </td>
            {isAdmin && (
              <td>
                <strong>
                  For use by
                  <br />
                  OFFICE OF DIVERSION &amp; REENTRY (ODR):
                </strong>
                <br />
                <br />
                For ICMS clients:
                <br />
                <Link to="/sendmail.php?for=haven_house">
                  <div>Email provider (assign client) - Haven House</div>
                </Link>
                <br />
                <br />
                <Link to="/sendmail.php?for=annamae_house">
                  <div>Email provider (assign client) - Anna Mae's House</div>
                </Link>
                <br />
                <br />
                <Link to="/sendmail.php?for=restoration_house">
                  <div>Email provider (assign client) - St. Andrew's House</div>
                </Link>
                <br />
                <br />
                <Link to="/sendmail.php?for=ella_house">
                  <div>Email provider (assign client) - Ella's House</div>
                </Link>
                <br />
                <br />
                <Link to="/sendmail.php?for=western_house">
                  <div>Email provider (assign client) - Western House</div>
                </Link>
                <br />
                <br />
                <Link to="/sendmail.php?for=percy_house">
                  <div>Email provider (assign client) - Percy House</div>
                </Link>
                <br />
                <br />
                <Link to="/sendmail.php?for=bandera_1">
                  <div>Email provider (assign client) - Bandera 1</div>
                </Link>
                <br />
                <br />
                <Link to="/sendmail.php?for=bandera_2">
                  <div>Email provider (assign client) - Bandera 2</div>
                </Link>
                <br />
                <br />
                <br />
                For Medication:
                <br />
                <Link to="/sendmail.php?for=mist_med_request_direct">
                  <div>Email Non-psychotropic Med Request</div>
                </Link>
                <br />
                <br />
                <Link to="/sendmail.php?for=mist_psychotropic_med_request">
                  <div>Email psychotropic Med Request</div>
                </Link>
                <br />
                <br />
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
};
