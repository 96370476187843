import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { isoDateToMDY } from "../../utils/common";
import { EditLegalGeneral } from "./modals/EditLegalGeneral";
import { EditLegalCase } from "./modals/EditLegalCase";

const GET_LEGAL_STATUS_DATA = gql`
  query GetLegalStatusData($programNumber: Integer!) {
    getParticipantProgramDataByProgramNumber(programNumber: $programNumber) {
      bookingNumber
      arrestDate
      ist {
        status
        statusDate
      }
      maxDate
      rso
      mainfour
      commitmentdate
      diversionDate
      releaseDate
      cases {
        casenumber
        caseopen
        case_entrydate
        charges {
          charge
          charge_entrydate
        }
        hearings {
          hearingdate
          hearingdetails
          hearing_entrydate
        }
      }
    }
  }
`;



//dont use table, use css grid and write casetable
const CasesTable = ({
  cases,
  handleEditCaseClick,
}: {
  cases: any[];
  handleEditCaseClick: (caseData: any) => void;
}) => {
  return (
    <div>
      {cases.map((caseItem: any, index: number) => (
        <div
          key={index}
          style={{
            marginBottom: "1rem",
            border: "1px solid #ccc",
            borderRadius: "1px",
            padding: "0.5rem",
          }}
        >
          <div style={{ marginBottom: "0.5rem" }}>
            <button
              style={{ marginRight: "1rem" }}
              onClick={() => handleEditCaseClick(caseItem)}
            >
              Edit
            </button>
            <span>
              Case Number: <b>{caseItem.casenumber} </b>
            </span>
            {/*<span style={{marginLeft: "2rem"}}>{isoDateToMDY(caseItem.case_entrydate)}</span>*/}

            {caseItem.hearings?.hearingdate && (
              <>
                <span style={{ marginLeft: "10rem" }}>
                  <b>Next Hearing:</b>{" "}
                </span>
                <span style={{ marginLeft: "0rem" }}>
                  {isoDateToMDY(caseItem.hearings?.hearingdate)}
                </span>
                <span style={{ marginLeft: "1rem" }}>
                  {caseItem.hearings?.hearingdetails}
                </span>
              </>
            )}
          </div>

          <hr />
          <div style={{ paddingLeft: "5rem" }}>
            {caseItem.charges.map((charge: any, index: number) => (
              <>
                <div
                  key={index}
                  style={{ marginBottom: "1rem" }}
                  className={index === 0 ? "first-charge" : ""}
                >
                  {charge.charge}
                </div>
              </>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export const LegalStatus = ({ programNumber }: { programNumber: number }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isEditCaseModalOpen, setIsEditCaseModalOpen] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);

  const { loading, error, data, refetch } = useQuery(GET_LEGAL_STATUS_DATA, {
    variables: { programNumber },
    skip: !programNumber,
    fetchPolicy: "network-only",
  });

  if (loading) return <p>Loading legal status data...</p>;
  if (error) return <p>Error loading legal status data :(</p>;
  if (!data) return null;

  const legalData = data.getParticipantProgramDataByProgramNumber;

  const handleEditClick = () => {
    setIsEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
  };
  const handleEditCaseClick = (caseData: any) => {
    setSelectedCase(caseData);
    setIsEditCaseModalOpen(true);
  };

  const handleCloseCaseModal = () => {
    setIsEditCaseModalOpen(false);
    setSelectedCase(null);
  };

  return (
    <div>
      <h3>
        Legal Status{" "}
        <button onClick={handleEditClick}>
          <div style={{ fontSize: "smaller" }}>Edit</div>
        </button>
      </h3>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
          gap: "10px",
          marginBottom: "1rem",
          border: "1px solid #ccc",
          borderRadius: "1px",
          padding: "0.5rem",
        }}
      >
        <div>
          <div>
            <b>Booking:</b>
          </div>
          {legalData.bookingNumber === 0
            ? "O.R"
            : legalData.bookingNumber || "N/A"}
        </div>
        <div>
          <div>
            <b>Main Four: (last four)</b>
          </div>{" "}
          {legalData.mainfour}
        </div>
        <div>
          <div>
            <b>Arrest date:</b>
          </div>{" "}
          {isoDateToMDY(legalData.arrestDate)}
        </div>
        <div>
          <div>
            <b>Commitment Date:</b>
          </div>{" "}
          {isoDateToMDY(legalData.commitmentdate)}
        </div>
        <div>
          <div>
            <b>Diversion Date:</b>
          </div>{" "}
          {isoDateToMDY(legalData.diversionDate)}
        </div>
        <div>
          <div>
            <b>Release Date:(projected)</b>
          </div>{" "}
          {isoDateToMDY(legalData.releaseDate)}
        </div>
        <div>
          <div>
            <b>Declared IST:</b>
          </div>
          {legalData.ist?.status === "0" ? (
            "'Competent to stand trial'"
          ) : legalData.ist?.status === "1" ? (
            "'Incompetent to stand trial'"
          ) : (
            <span style={{ color: "red" }}>NOT SET</span>
          )}{" "}
          <div>{isoDateToMDY(legalData.ist?.statusDate)}</div>
        </div>
        <div>
          <b>Max Date:</b>
          <div>{isoDateToMDY(legalData.maxDate)}</div>
        </div>
        <div>
          <b>RSO:</b>
          {legalData.sexoffenderstatus ? (
            <div style={{ color: "red" }}>YES</div>
          ) : (
            <div style={{ color: "green" }}>NO</div>
          )}
        </div>
      </div>
      <div style={{ marginTop: "5rem" }}>
        <h3>Cases</h3>
        <CasesTable
          cases={legalData.cases}
          handleEditCaseClick={handleEditCaseClick}
        />
      </div>
      <EditLegalGeneral
        refetch={refetch}
        isOpen={isEditModalOpen}
        onClose={handleCloseModal}
        programNumber={programNumber}
        legalData={legalData}
      />
      <EditLegalCase
        refetch={refetch}
        isOpen={isEditCaseModalOpen}
        onClose={handleCloseCaseModal}
        programNumber={programNumber}
        caseData={selectedCase}
      />
    </div>
  );
};
