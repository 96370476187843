import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";


import { useMutation, gql } from "@apollo/client";


const UPDATE_PARTICIPANT_PROGRAM_DATA = gql`
  mutation updateParticipantProgramDataByProgramNumber($programNumber: Integer!, $updates: participantProgramDataInput) {
    updateParticipantProgramDataByProgramNumber(programNumber: $programNumber, updates: $updates) {
      responseCode
      message
    }
  }
`;

export const EditDisposition = ({
  isOpen,
  onClose,
  programNumber,
  planData,
}: {
  isOpen: boolean;
  onClose: () => void;
  programNumber: number;
  planData: any;
}) => {
  const [formData, setFormData] = useState({ ...planData });
  const [updateParticipantProgramData] = useMutation(UPDATE_PARTICIPANT_PROGRAM_DATA);

  useEffect(() => {
    if (planData) {
      setFormData({ ...planData });
    }
  }, [planData]);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const {__typename, ...vars} = formData;
      await updateParticipantProgramData({
        variables: {
          programNumber,
          updates: {locationAndPlan:vars},
        },
      });
      onClose();
    } catch (error) {
      console.error("Error updating disposition:", error);
    }
  };

  return (
    <ReactModal
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        content: {
          position: "relative",
          background: "white",
          padding: "20px",
          maxHeight: "80vh",
          overflowY: "scroll",
          width: "100%",
          maxWidth: "800px",
          inset: 0,
        },
      }}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <div style={{ marginBottom: "1.5rem" }}>
        <div
          style={{ display: "flex", justifyContent: "end", alignItems: "end" }}
        >
          <button type="button" onClick={onClose}>
            Close
          </button>
        </div>
        <h2
          style={{
            fontSize: "1.875rem",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Edit Location and Plan
        </h2>
        <form
          onSubmit={handleSubmit}
          style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          <div>
            <label>Custody or Community:</label>
            <select
              name="custodyorcommunity"
              value={formData.custodyorcommunity}
              onChange={handleInputChange}
            >
              <option value="0">Community</option>
              <option value="1">In Custody</option>
            </select>
          </div>
          <div>
            <label>Location:</label>
            <input
              type="text"
              name="location"
              value={formData.location}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label>Location Detail:</label>
            <textarea
              name="locationdetail"
              value={formData.locationdetail}
              onChange={handleInputChange}
              rows={4}
              cols={80}
            />
          </div>
          <div>
            <label>Contact:</label>
            <textarea
              name="contact"
              value={formData.contact}
              onChange={handleInputChange}
              rows={4}
              cols={80}
            />
          </div>
          <div>
            <label>Discharge Plan:</label>
            <textarea
              name="dischargeplan"
              value={formData.dischargeplan}
              onChange={handleInputChange}
              rows={4}
              cols={80}
            />
          </div>
          <div>
            <label>Comments:</label>
            <textarea
              name="comments"
              value={formData.comments}
              onChange={handleInputChange}
              rows={4}
              cols={80}
            />
          </div>
          <div>
            <label>High Priority Status:</label>
            <input
              type="text"
              name="high_priority_status"
              value={formData.high_priority_status}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label>IH Assignment Date:</label>
            <input
              type="date"
              name="ih_assignment_date"
              value={formData.ih_assignment_date}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label>IH Request Date:</label>
            <input
              type="date"
              name="ih_request_date"
              value={formData.ih_request_date}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label>IH Request Status:</label>
            <input
              type="text"
              name="ih_request_status"
              value={formData.ih_request_status}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label>Notes:</label>
            <textarea
              name="notes"
              value={formData.notes}
              onChange={handleInputChange}
              rows={4}
              cols={80}
            />
          </div>
          <div>
            <label>Pharmacy Notes:</label>
            <textarea
              name="pharmacynotes"
              value={formData.pharmacynotes}
              onChange={handleInputChange}
              rows={4}
              cols={80}
            />
          </div>
          <div>
            <label>PSH Funding Source:</label>
            <input
              type="text"
              name="psh_funding_source"
              value={formData.psh_funding_source}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label>PSH Search Notes:</label>
            <textarea
              name="psh_search_notes"
              value={formData.psh_search_notes}
              onChange={handleInputChange}
              rows={4}
              cols={80}
            />
          </div>
          <div>
            <label>PSH Search Status:</label>
            <input
              type="text"
              name="psh_search_status"
              value={formData.psh_search_status}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label>PSH Search Status Date:</label>
            <input
              type="date"
              name="psh_search_status_date"
              value={formData.psh_search_status_date}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label>PSH Type:</label>
            <input
              type="text"
              name="psh_type"
              value={formData.psh_type}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label>Restrictions:</label>
            <textarea
              name="restrictions"
              value={formData.restrictions}
              onChange={handleInputChange}
              rows={4}
              cols={80}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}
          >
            <button type="submit">Update</button>
          </div>
        </form>
      </div>
    </ReactModal>
  );
};
