import React, { useState } from 'react';
import { useLazyQuery, gql } from '@apollo/client';
import * as XLSX from 'xlsx';

// Define the GraphQL query
const GET_USER_INFORMATION_QUERY = gql`
  query GetUserInformation($usernames: [String]!) {
    getUserInformation(usernames: $usernames) {
      entries {
        username
        name
        lastlogin
        email
        disabled
      }      
      totalCount
    }
  }
`;

const DateDisplay = (params:any) => {
  const { value } = params;

  if (!value) {
    return <React.Fragment>None</React.Fragment>;
  }

  // Convert the provided date string to a Date object
  const date = new Date(value);

  // Extract the individual date components
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-based
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();

  // Create the formatted date string
  const formattedDate = `${month}/${day}/${year}`;

  return <React.Fragment>{formattedDate}</React.Fragment>;
};

export const MassDeactivate = () => {
  const [displayEdit, setDisplayEdit] = useState(true);
  const [usernames, setUsernames] = useState('');
  const [getUserInformation, { loading, error, data }] = useLazyQuery(
    GET_USER_INFORMATION_QUERY,
    {
      variables: { usernames: usernames.split(/\s+/) },
    }
  );

  const handleChange = (event:any) => {
    setUsernames(event.target.value);
  };

  const handleButtonClick = () => {
    setDisplayEdit(false);
    getUserInformation();
  };

  const handleReset = () => {
    setDisplayEdit(true);
    setUsernames('');
  };

  const handleDownload = () => {
    const ws = XLSX.utils.json_to_sheet(data.getUserInformation.entries);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'User Information Report');
    XLSX.writeFile(wb, 'user_information_report.xlsx');
  };

  if (displayEdit) return (
    <div>
      <h2>User Information Report</h2>
      <textarea
        value={usernames}
        onChange={handleChange}
        placeholder="Enter space or newline separated list of usernames"
        rows={5}
        cols={50}
      /><br/>
      <button onClick={handleButtonClick}>Get User Information</button>
    </div>
  );

  const handleDisableUser = async (username:any) => {
    // Implementation for disabling user
  };

  return (
    <div>
      <h2>User Information Report</h2>
      <button onClick={handleDownload}>Download as Excel</button>
      <button onClick={handleReset}>Reset</button>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}
      {data && (
        <ul>
          <div>            
            <ul>
              {data.getUserInformation.entries.map((entry:any, index:number) => (
                <div className="columns6 boxed" key={index}>
                  <div>
                    <strong>Username:</strong> {entry.username}
                  </div>
                  <div>
                    <strong>Name:</strong> {entry.name}
                  </div>
                  <div>
                    <strong>Last Login:</strong>{' '}
                    <DateDisplay value={entry.lastlogin} />
                  </div>
                  <div>
                    <strong>Email:</strong> {entry.email}
                  </div>
                  <div>
                    <strong>Disabled:</strong> {entry.disabled ? 'Yes' : 'No'}
                  </div>
                  <div>
                    <button onClick={() => handleDisableUser(entry.username)}>
                      Disable
                    </button>
                  </div>
                </div>
              ))}
            </ul>
            {data.getUserInformation.errors && (
              <p>Error: {data.getUserInformation.errors.join(', ')}</p>
            )}
          </div>
        </ul>
      )}
    </div>
  );
};
