import React from 'react';
import  ReactModal from 'react-modal';
import {FormEditor} from '../lib/formEditor';
import { FormRenderer } from '../lib/formRender';
import { useQuery, useMutation, gql } from '@apollo/client';

import { useUser } from '../../../store/useUser';

//const init= require ('../forms/initialassessment.json')


/*const convert = (form: any)=>{
    return form.map((item: any)=>{
        const  n = {
          key : item.name,
          required: item.required,
          datatype: item.datatype,
          
        } as any

        if (item.class == 'heading'){            
          n.type ='heading'            
          n.text = item.text
        }
        if (item.class == 'paragraph' ){
          n.type = 'paragraph'
          n.text = item.text
        }

        if (item.class == 'text' ){
            n.type = 'textInput'
            n.label= item.text
        }

        if (item.class == 'textarea' ){
          n.type = 'textAreaInput'
          n.label= item.text
        }

        if (item.class == 'date' ){
          n.type = 'dateInput'
          n.label= item.text
        }

        if (item.class == 'toggle' ){
          n.type = 'yesnoInput'
          n.label= item.text
        }

        if (item.class == 'select') 
        {
          n.type = 'listInput'
          n.label= item.text
          n.options = item.options.map((option: any)=>{
            return {value: option.text, label: option.text, key: option.text}
          })
        }


        return n
    })
}

*/


const LIST_FORM_TEMPLATES = gql`
  query listFormTemplates {
    listFormTemplates {
      id
      name
      body
    }
  }
`;

const UPDATE_FORM_TEMPLATE = gql`
  mutation updateFormTemplate($id: Integer!, $updates: FormTemplateInput!) {
    updateFormTemplate(id: $id, updates: $updates) {
      id
      name
      body
    }
  }
`;

const CREATE_FORM_TEMPLATE = gql`
  mutation createFormTemplate($updates: FormTemplateInput!) {
    createFormTemplate(updates: $updates) {
      id
      name
      body
    }
  }
`;



export const FormTemplates= (params: any)=>{
    const [selectedTemplate, setSelectedTemplate] = React.useState(-1) as any;
    const { loading, error, data, refetch } = useQuery(LIST_FORM_TEMPLATES);
    const [updateFormTemplateMutation] = useMutation(UPDATE_FORM_TEMPLATE);
    const [createFormTemplateMutation] = useMutation(CREATE_FORM_TEMPLATE);
    const { user } = useUser();
    
    const Templates = data ? data.listFormTemplates : [];

    const onSave =  (formTemplate: any)=>{
        if (selectedTemplate > -1){
            updateFormTemplateMutation({variables: {id: Templates[selectedTemplate].id, updates: {body: JSON.stringify(formTemplate)}}})
        } 
        refetch();
    }

    /*const x = ()=>{
      const z = convert(init)
      updateFormTemplateMutation({variables: {id: 3, updates: {body: JSON.stringify(z)}}})
    }*/

    const onClose = ()=>{
        setSelectedTemplate(-1)
        refetch();
    }

    const newForm = async ()=>{
        await createFormTemplateMutation({variables: {updates: {name: 'New Form', body: JSON.stringify([])}}})
        refetch();
    }



    /*if (selectedTemplate == Templates.length){
        const body = JSON.parse( Templates[selectedTemplate].body);
        const name = Templates[selectedTemplate].name;
        
        //return (<FormRenderer formTemplate={body} formState={{}} onClose={onClose}/>)
        return (<FormEditor onSave= { onSave }  name={"New Form"}  formTemplate={JSON.stringify([])} onClose={onClose}/>)        
    }*/
    

    if (selectedTemplate > -1 && Templates[selectedTemplate]){
        const body = JSON.parse( Templates[selectedTemplate].body);
        const name = Templates[selectedTemplate].name;
        
        //return (<FormRenderer formTemplate={body} formState={{}} onClose={onClose}/>)
        return (<FormEditor template= {Templates[selectedTemplate]} onSave= { onSave }  name={name} formTemplate={body} onClose={onClose}/>)        
    }

    return (
        <div>
            <h2>Forms</h2>
            
            <button style={{marginBottom: '2rem'}} onClick={newForm}>Create New Template</button>
            <div>{Templates.map((key:any, index:number)=>{
                return (
                    <div key={index} onClick={()=>{ setSelectedTemplate(index)}}>{key.name}</div>
                )
            })}
            </div>
        </div>
    )
} 
