import React from 'react';
import { getCookie, setCookie, eraseCookie } from './cookies';


export const AuthContext = React.createContext<any>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const authRef = React.useRef({

    authenticationState: getCookie('Authorization') || '',
    setAuthenticationState: (auth: string) => {
      if (!auth) {
        eraseCookie('Authorization');
        authRef.current.authenticationState = '';
      } else {
        setCookie('Authorization', auth);
        authRef.current.authenticationState = auth;
      }
    },
  }) as any;

  return <AuthContext.Provider value={authRef.current}>{children}</AuthContext.Provider>;
};


