import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { server} from './settings'
import { Router } from './router'
import { Apollo } from './apollo'


import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { StoreProvider } from './store/storeProvider';
import { AuthProvider } from './store/authenticationProvider';
import { useAuthenticationState } from './store/useAuthenticationState';



import './css';


const ApolloWrapper = ()=> {
  const { authenticationState } = useAuthenticationState();
  
  return (
    <div className="App">
      <Apollo server= {server} auth={authenticationState}>           
        <StoreProvider>
            <Router/>     
        </StoreProvider>
        <ToastContainer />
      </Apollo>     
    </div>
  );
}


export const App = () => {
	return (		
      <AuthProvider>
			  <ApolloWrapper />      		
      </AuthProvider>
	);
};

// Below identifies the root window. No need to change anything. 
//**************************************************************
//**************************************************************
//**************************************************************
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
