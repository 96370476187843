import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";

export const EditCalAIM = ({
  isOpen,
  onClose,
  calaimData,
  user,
  onUpdate,
}: {
  isOpen: boolean;
  onClose: () => void;
  calaimData: any;
  user: any;
  onUpdate: (formData: any) => void;
}) => {
  const [formData, setFormData] = useState({
    calaim_pos: "",
    proceduremod1: "",
    proceduremod2: "",
    proceduremod3: "",
    proceduretype: "",
    procedurelength: "",
    note_entry_date: "",
    procedurenote: "",
    entryBy: "",
  });

  useEffect(() => {
    if (calaimData) {
      setFormData({
        calaim_pos: calaimData.posCode || "",
        proceduremod1: calaimData.procedureModifier1 || "",
        proceduremod2: calaimData.procedureModifier2 || "",
        proceduremod3: calaimData.outreach === "0" ? "0" : "1",
        proceduretype: calaimData.procedureType || "",
        procedurelength: calaimData.serviceUnits || "",
        note_entry_date: calaimData.serviceStart || "",
        procedurenote: calaimData.timespentNote || "",
        entryBy: calaimData.entryBy || user.fullname,
      });
    }
  }, [calaimData, user]);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onUpdate(formData);
  };

  return (
    <ReactModal
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        content: {
          position: "relative",
          background: "white",
          padding: "20px",
          maxHeight: "80vh",
          overflowY: "scroll",
          width: "100%",
          maxWidth: "800px",
          inset: 0,
        },
      }}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <div style={{ marginBottom: "1.5rem" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
          <h2 >Edit CalAIM Note</h2>
          <div><button type="button" onClick={onClose}>
            Close
          </button>
          </div>
        </div>
        
        <form onSubmit={handleSubmit}>
          <div style={{display: "grid",gridTemplateColumns: "1fr 1fr", gap: "1rem" }}>
            <div>
              <label htmlFor="calaim_pos">Place of Service</label>
            </div>
            <div >
              <select
                name="calaim_pos"
                id="calaim_pos"
                value={formData.calaim_pos}
                onChange={handleInputChange}
                className="c-form-control"
              >
                <option value="">-- Select an option --</option>
                <option value="12">Home</option>
                <option value="4">Homeless Shelter</option>
                <option value="10">Telehealth provided in pts home</option>
                <option value="2">
                  Telehealth provided other than in pts home
                </option>
                <option value="99">Other Place of Service</option>
                <option value="13">Assisted Living Facility</option>
                <option value="31">Skilled Nursing Facility</option>
                <option value="23">Emergency Room - Hospital</option>
                <option value="20">Urgent Care Facility</option>
                <option value="11">Office</option>
                <option value="14">Group Home</option>
                <option value="16">Temporary Lodging</option>
                <option value="17">Walk-in Retail Health Clinic</option>
                <option value="18">Place of Employment- Worksite</option>
                <option value="19">Off Campus-Outpatient Hospital</option>
                <option value="21">Inpatient Hospital</option>
                <option value="22">On Campus-Outpatient Hospital</option>
                <option value="24">Ambulatory Surgical Center</option>
                <option value="32">Nursing Facility</option>
                <option value="33">Custodial Care Facility</option>
                <option value="34">Hospice</option>
                <option value="49">Independent Clinic</option>
                <option value="50">Federally Qualified Health Center</option>
                <option value="51">Inpatient Psychiatric Facility</option>
                <option value="52">
                  Psychiatric Facility - Partial Hospitalization
                </option>
                <option value="53">Community Mental Health Center</option>
                <option value="55">
                  Residential Substance Abuse Treatment Facility
                </option>
                <option value="56">
                  Psychiatric Residential Treatment Center
                </option>
                <option value="57">
                  Non-residential Substance Abuse Treatment Facility
                </option>
                <option value="71">Public Health Clinic</option>
              </select>
            </div>
          </div>

          <div>
            <label>Procedure Modifier</label>
            <div className="form-group">
              <div className="form-check">
                <input
                  type="radio"
                  name="proceduremod1"
                  value="1"
                  checked={formData.proceduremod1 === "1"}
                  onChange={handleInputChange}
                  className="form-check-input"
                />
                <label>In Person</label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  name="proceduremod1"
                  value="0"
                  checked={formData.proceduremod1 === "0"}
                  onChange={handleInputChange}
                  className="form-check-input"
                />
                <label>Telehealth</label>
              </div>
            </div>

            <div className="form-group">
              <div className="form-check">
                <input
                  type="radio"
                  name="proceduremod2"
                  value="1"
                  checked={formData.proceduremod2 === "1"}
                  onChange={handleInputChange}
                  className="form-check-input"
                />
                <label>Clinical</label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  name="proceduremod2"
                  value="0"
                  checked={formData.proceduremod2 === "0"}
                  onChange={handleInputChange}
                  className="form-check-input"
                />
                <label>NonClinical</label>
              </div>
            </div>

            <div className="form-group">
              <div className="form-check">
                <input
                  type="radio"
                  name="proceduremod3"
                  value="0"
                  checked={formData.proceduremod3 === "0"}
                  onChange={handleInputChange}
                  className="form-check-input"
                />
                <label>Outreach</label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  name="proceduremod3"
                  value="1"
                  checked={formData.proceduremod3 === "1"}
                  onChange={handleInputChange}
                  className="form-check-input"
                />
                <label>Care</label>
              </div>
            </div>
          </div>

          <div>
            <label htmlFor="proceduretype">Procedure Type</label>
            <select
              name="proceduretype"
              id="calaim_careprovider"
              value={formData.proceduretype}
              onChange={handleInputChange}
              className="c-form-control"
            >
              <option value="">-- Select an option --</option>
              <option value="Initial Assessment-first 90 days">
                Initial Assessment-first 90 days
              </option>
              <option value="Care Plan-every quarter or more">
                Care Plan-every quarter or more
              </option>
              <option value="Client Contact">Client Contact</option>
              <option value="Client Outreach">Client Outreach</option>
              <option value="Care Coordination">Care Coordination</option>
            </select>
          </div>

          <div>
            <label htmlFor="procedurelength">Procedure Length</label>
            <select
              name="procedurelength"
              id="calaim_caremanager"
              value={formData.procedurelength}
              onChange={handleInputChange}
              className="c-form-control"
            >
              <option value="5">5 mins</option>
              <option value="10">10 mins</option>
              <option value="15">15 mins</option>
              <option value="20">20 mins</option>
              <option value="25">25 mins</option>
              <option value="30">30 mins</option>
              <option value="35">35 mins</option>
              <option value="40">40 mins</option>
              <option value="45">45 mins</option>
              <option value="50">50 mins</option>
              <option value="55">55 mins</option>
              <option value="60">60 mins</option>
              <option value="75">1hour 15mins</option>
              <option value="90">1hour 30mins</option>
              <option value="105">1hour 45mins</option>
              <option value="120">2hours</option>
              <option value="150">2hours 30mins</option>
              <option value="180">3hours</option>
              <option value="210">3hours 30mins</option>
              <option value="240">4hours</option>
            </select>
          </div>

          <div>
            <label htmlFor="note_entry_date">Note Date</label>
            <input
              type="text"
              name="note_entry_date"
              id="calaim_note_entry_date"
              value={formData.note_entry_date}
              onChange={handleInputChange}
              className="c-form-control calaim-datepicker"
              autoComplete="off"
              required
            />
          </div>

          <div>
            <label htmlFor="procedurenote">Procedure Note:</label>
            <textarea
              name="procedurenote"
              value={formData.procedurenote}
              onChange={handleInputChange}
              className="c-form-control"
            ></textarea>
          </div>

          <div className="button-group">
            <input
              type="submit"
              name="update"
              value="Update"
              className="btn btn-secondary"
            />
            <input
              type="button"
              name="delete"
              value="Delete"
              className="btn btn-secondary btn-delete"
              style={{ backgroundColor: "#dc3545", marginLeft: "10px" }}
              onClick={() => {
                if (window.confirm("Are you sure to delete?")) {
                  // Implement delete logic here
                  console.log("Delete action triggered");
                }
              }}
            />
          </div>
        </form>
      </div>
    </ReactModal>
  );
};
