


import React from 'react';
import { useQuery, gql } from '@apollo/client';

const VERSION_QUERY = gql`
  query version {
    version {
      message
      responseCode
    }
  }
`;

export const About = () => {
  const { loading, error, data } = useQuery(VERSION_QUERY);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return <h1>Version: {data.version.message}</h1>;
};

