import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_PARTICIPANT, CREATE_PARTICIPANT_OUTREACH, DELETE_PARTICIPANT_OUTREACH } from './queries';
import Select from 'react-select';
import {toast} from 'react-toastify';
import  ReactModal from 'react-modal';

const qaStaffOptions = [
  { value: 'Antony Schreurs', label: 'Antony Schreurs' },
  { value: 'Diana Ramirez', label: 'Diana Ramirez' },
  { value: 'William Prewett', label: 'William Prewett' }
];

const _d = (date:any)=>{
  if (date == null) return '';
  return date.split('T')[0];
}

const contactMethod = ['Email', 'Phone', 'In Person'];
const results = [ 'Rejected to Enroll', 'Agreed to Enroll', 'Waiting Response (First Attempt)', 'Waiting Response (Follow Up Attempt)'];

const OutreachView = ({outreach, refetch, close} : any) => {
  const [deleteParticipantOutreach] = useMutation(DELETE_PARTICIPANT_OUTREACH);

  const handleDelete = (outreachid: number) => {
    if (!window.confirm('Are you sure you want to delete this outreach attempt?'))       
      return;

    deleteParticipantOutreach({variables: {outreachid: outreach.id}});      
    refetch();
    close();
    
  }

  return (
    <div>
      <h2>Outreach Attempt</h2>
      <div style= {{display: 'grid', gridTemplateColumns : '1fr 1fr 1fr 1fr' , marginBottom: '1rem', fontWeight :'bold'}}>          
          <div>Date:</div>
          <div>Contact Method:</div>
          <div>Result</div>
          <div>QA</div>
        </div>
      <div style= {{display: 'grid', gridTemplateColumns : '1fr 1fr 1fr 1fr' }}>          
          <div>{_d(outreach.contactDate)}</div>
          <div>{contactMethod[outreach.contactMethod]}</div>
          <div>{results[outreach.results]}</div>
          <div>{outreach.qaStaff}</div>
        </div>
        <div style= {{display: 'grid', gridTemplateColumns : '1fr 1fr 1fr 1fr' , marginTop: '1rem', fontWeight :'bold'}}>          
          <div>Address:</div>
          <div>City:</div>
          <div>ZIP:</div>
          <div>Email:</div>
        </div>
        <div style= {{display: 'grid', gridTemplateColumns : '1fr 1fr 1fr 1fr' }}>          
          <div>{outreach.address}</div>
          <div>{outreach.city}</div>
          <div>{outreach.zip}</div>
          <div>{outreach.email}</div>
        </div>
        <div style= {{display: 'grid', gridTemplateColumns : '1fr 1fr 1fr 1fr' , marginTop: '1rem', fontWeight :'bold'}}>          
          <div>Phone Number:</div>
          <div>Name of Contact:</div>
        </div>
        <div style= {{display: 'grid', gridTemplateColumns : '1fr 1fr 1fr 1fr' }}>          
          <div>{outreach.phoneNumber}</div>
          <div>{outreach.nameOfContact}</div>
        </div>
        <div style= {{display: 'grid', gridTemplateColumns : '1fr 1fr 1fr 1fr', marginTop: '1rem', fontWeight :'bold'}}>          
          <div>Notes:</div>
        </div>
        <div>{outreach.notes}</div>
        <div style={{marginTop: '2rem'}}>
        <button style= {{color: 'white', backgroundColor: 'red'}} onClick={()=>{
          handleDelete(outreach.uniqueid);
        }}>Delete</button>
        </div>
    </div>
  );
}

export const Outreach: React.FC = () => {
  const { odrnumber, participant } = useParams<{ odrnumber: any, participant: any }>();

  const { loading, error, data, refetch } = useQuery(GET_PARTICIPANT, {
    variables: { odrnumber : parseInt(odrnumber) }
  });

  const [currentOutReach, setCurrentOutReach] = useState(null);

  const [createParticipantOutreach] = useMutation(CREATE_PARTICIPANT_OUTREACH);

  const [formState, setFormState] = useState({
    contactMethod: '',
    date: '',
    results: '',
    qaStaff: '',
    address: '',
    city: '',
    zip: '',
    email: '',
    phoneNumber: '',
    nameOfContact: '',
    notes: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleSelectChange = (selectedOption: any) => {
    setFormState({ ...formState, qaStaff: selectedOption ? selectedOption.value : '' });
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleSubmit = () => {

    if (formState.date == null || formState.date == '') 
      return toast.error('Please enter a date');

    if (formState.contactMethod == null || formState.contactMethod == '')
      return toast.error('Please select a contact method');

    if (formState.results == null || formState.results == '')
      return toast.error('Please select results');

    if (formState.qaStaff == null || formState.qaStaff == '')
      return toast.error('Please select QA Staff');


    const safeVars = {
      contactMethod: formState.contactMethod || undefined,
      date: formState.date || undefined,
      results: formState.results || undefined,
      qaStaff: formState.qaStaff || undefined,
      address: formState.address|| undefined,
      city: formState.city || undefined,
      zip: formState.zip|| undefined,
      email: formState.email|| undefined,
      phoneNumber: formState.phoneNumber|| undefined,
      nameOfContact: formState.nameOfContact || undefined,
      notes: formState.notes || undefined
    };
    

    createParticipantOutreach({
      variables: {
        odrnumber: odrnumber,
        participant: participant,
        input: safeVars
      }
    }).then(response => {
      console.log('Outreach attempt created', response.data);
      refetch();
      // Handle success or error response
    });

    setFormState({
      contactMethod: '',
      date: '',
      results: '',
      qaStaff: '',
      address: '',
      city: '',
      zip: '',
      email: '',
      phoneNumber: '',
      nameOfContact: '',
      notes: ''
    });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

 

  return (
    <div>
      <h1>Client Outreach</h1>
            
      <form className='boxed'>
      <h2>Create New Outreach Attempt</h2>
      <div style= {{display: 'grid', gridTemplateColumns : '1fr 1fr 1fr 1fr'}}>
      <label>Date:</label>
      <label>Contact Method:</label>
      <label>Results:</label>      
      </div>  

        <div style= {{display: 'grid', gridTemplateColumns : '1fr 1fr 1fr 1fr'}}>

        <div>
            
            <input type="date" name="date" onChange={handleChange} value={formState.date}/>
          </div>

          <div>
            
            <select name="contactMethod" onChange={handleChange} value={formState.contactMethod}>
            <option value={undefined}>---Select an Option----</option>
              <option value="0">Email</option>
              <option value="1">Phone</option>
              <option value="2">In Person</option>
            </select>
          </div>
          
          <div>
            
            <select name="results" onChange={handleChange} value={formState.results}>
             <option value={undefined}>---Select an Option----</option>
              <option value="1">Agreed to Enroll</option>
              <option value="0">Rejected to Enroll</option>              
            </select>
          </div>
          
        </div>

          <div style= {{display: 'grid', gridTemplateColumns : '20rem' , marginTop : '1.5rem'}}>
            <label>QA Staff:</label>
          </div>
          
          <div style= {{display: 'grid', gridTemplateColumns : '20rem' ,}}>
           <div><Select
            options={qaStaffOptions}
            onChange={handleSelectChange}
            isClearable
            isSearchable
            placeholder="Select or type a staff member"
          />        
          </div>          
          </div>

        <div style= {{display: 'grid', gridTemplateColumns : '1fr 1fr 1fr' , marginTop : '1.5rem'}}>
          <label>Address:</label>
          <label>City:</label>
          <label>ZIP:</label>
        </div>

        <div style= {{display: 'grid', gridTemplateColumns : '1fr 1fr 1fr' }}>        
          <div><input type="text" name="address" onChange={handleChange} value={formState.address}/></div>
          <div><input type="text" name="city" onChange={handleChange} value={formState.city}/></div>
          <div><input type="text" name="zip" onChange={handleChange} value={formState.zip}/></div>
        </div>

          <div style= {{display: 'grid', gridTemplateColumns : '1fr 1fr 1fr', marginTop : '1rem' }}>          
          <label>Email Address:</label>
          <label>Phone Number:</label>
          <label>Name of Contact:</label>
          </div>

          <div style= {{display: 'grid', gridTemplateColumns : '1fr 1fr 1fr' }}>          

            <div><input type="text" name="email" onChange={handleChange} value={formState.email}/></div>
            <div><input type="text" name="phoneNumber" onChange={handleChange} value={formState.phoneNumber}/></div>
            <div><input type="text" name="nameOfContact" onChange={handleChange} value={formState.nameOfContact}/></div>
          </div>

          <div><textarea name="notes" onChange={handleTextareaChange} value={formState.notes}/></div>

        
        <div style= {{marginTop : '1rem' }}>          
          <button type="button" onClick={handleSubmit}>Create Outreach Attempt</button>
        </div>
      </form>


      <div className='boxed'>
        <h2>Outreach History</h2>
        <div style= {{display: 'grid', gridTemplateColumns : '1fr 1fr 1fr 1fr', marginBottom: '1rem' }}>          
          <div>Date:</div>
          <div>Contact Method:</div>
          <div>Result</div>
          <div>QA</div>
        </div>
        {data.getParticipantByODRNumber.outreach.map((attempt: any, index: number) => {
        

          return (
            <div key={index} style= {{display: 'grid', gridTemplateColumns : '1fr 1fr 1fr 1fr' }} className={(index % 2) ? 'alt' : 'white'} onClick={()=>{
              setCurrentOutReach(attempt);
            }}>          
              <div>{_d(attempt.contactDate)}</div> 
              <div>{contactMethod[attempt.contactMethod]}</div>
              <div>{results[attempt.results]}</div>
              <div>{attempt.qaStaff}</div>
            </div>
          )
      })}
      </div>


      { currentOutReach &&
           <ReactModal isOpen={true} onRequestClose={()=>{setCurrentOutReach(null)}}>
           <div style={{display: 'grid', gridTemplateColumns:'95% 1fr'}}>
              <div></div>
           <div><button onClick={()=>{setCurrentOutReach(null)}}>Close</button></div>            
              <OutreachView outreach={currentOutReach} refetch={refetch} close={()=>{setCurrentOutReach(null)}} />
           </div>
         </ReactModal>
        }

    </div>
  );
};


