import React from 'react';
import { SearchParticipants} from '../pages/CalAIM/searchParticipants/searchParticipants';

import  ReactModal from 'react-modal';

import { usePrintView } from '../store/usePrintView';
import { useHelpSidebarVisibility } from '../store/useHelpSidebarVisibility';
import { useUser } from '../store/useUser';

const closeButtonStyle = {
    position: "absolute",
    top: "10px", // Adjust as needed
    right: "10px", // Adjust as needed    
    border: "1px solid #ccc",
    borderRadius: "5px",        
    cursor: "pointer",
  } as any;

export const Header = ({settings}:any) => {
    const { user, isUserLoading } = useUser();

    const [searchModalOpen, setSearchModalOpen] = React.useState(false);
    const [printModalOpen, setPrintModalOpen] = React.useState(false);
    const {isPrintView, setPrintView} = usePrintView();
    const {toggleHelpSidebar} = useHelpSidebarVisibility();

   /* React.useEffect (() => {    
        if (printModalOpen==true){
            window.print()
            setPrintModalOpen(false);
        }
    }, [printModalOpen]);*/



    /*if (isPrintView== true) {
        
        return <div></div>
    }*/

    return (
        <div style={{display:'grid', gridTemplateColumns: '1fr 20rem', paddingBottom: '10px',marginBottom: '1rem',  borderBottom: "1px solid grey",}}>    
            <div style={{fontWeight:'bold'}}>{isUserLoading ? 'Loading...' : user ? user.realname : 'Not logged in'}</div>
            <div >                
                
                <a href='/menu.php'><button style={{ marginRight: '1rem'}}>Main Menu</button></a>                
                { (!(settings && settings.withoutSearch == false)) && <button style={{ marginRight: '1rem'}} onClick={()=>{setSearchModalOpen(true)}}>Search</button>}                
                <button style={{ marginRight: '1rem'}} onClick={()=>{toggleHelpSidebar()}}>Help</button>
                <button style={{ marginRight: '1rem'}} onClick={()=>{window.history.back()}}>Back</button>
                { (settings && settings.withPrint==true) && <button  onClick={()=>{ setPrintModalOpen(true); }}>Print</button>}
                
                </div>

                {searchModalOpen && 
                <ReactModal isOpen={true} onRequestClose={()=>{setSearchModalOpen(false)}}>
                    <>
                        <button onClick={()=>{setSearchModalOpen(false)}} style={closeButtonStyle}>X</button>
                        <SearchParticipants Close={()=>{setSearchModalOpen(false)}}/>
                    </>
                </ReactModal>}
        </div>);
        return <div></div>
} 
