 
import React from 'react';
import { gql, useMutation } from '@apollo/client';

// Define your GraphQL mutation
const UPDATE_LEAD_DATA = gql`
  mutation UpdateLEADData {
    updateLEADData
  }
`;

export const LEAD_participants_enrollment = () => {
  // Use Apollo's useMutation hook
  const [updateLEADData, { data }] = useMutation(UPDATE_LEAD_DATA);

  // Function to call when button is clicked
  const handleClick = () => {
    updateLEADData({ variables: { id: 'your-id', data: 'your-data' } });
  };

  return (
    <div>
      <button onClick={handleClick}>Update Data</button>
      {data && <p>Data updated successfully!</p>}
    </div>
  );
}


