import React, { useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import { programNameFromID, isoDateToMDY } from "../../utils/common";
import { EditParticipant } from "./modals/EditParticipant";

/*
if(isset($probation) && isset($probation['probation'])){
	if (strtolower($probation['probation']) === 'no') {
		echo '<td align="center"><strong>Not on Probation</strong></td>';
	}else if(strtolower($probation['probation']) === 'yes'){
		echo '<td align="center"><strong>Probation</strong></td>';
	}else if(strtolower($probation['probation']) === 'terminated'){
		echo '<td align="center"><strong>Probation Terminated</strong></td>';
	}
} */

const formatProbation = (probation: string) => {
  const probationStatus = {
    no: "Not on Probation",
    yes: "Probation",
    terminated: "Probation Terminated",
  } as any;

  return probationStatus[probation] || "N/A";
};

const GET_PARTICIPANT_PROGRAM_DATA = gql`
  query GetParticipantProgramDataByProgramNumber($programNumber: Integer!) {
    getParticipantProgramDataByProgramNumber(programNumber: $programNumber) {
      legacyNameHistory {
        firstName
        lastName
      }
      gender
      dob
      mrun
      champid
      provider
      jcit
      releaseDate
      race
      pathway
      probation
      disposition {
        disposition
        dispositionDate
      }
      isnumber
    }
  }
`;

interface ProgramDataSectionProps {
  selectedProgram: string;
  onDataLoaded: () => void;
}

export const ProgramDataSection: React.FC<ProgramDataSectionProps> = ({
  selectedProgram,
  onDataLoaded,
}) => {
  const {
    loading: programLoading,
    error: programError,
    data: programData,
  } = useQuery(GET_PARTICIPANT_PROGRAM_DATA, {
    variables: { programNumber: parseInt(selectedProgram) },
    skip: !selectedProgram,
  });

  const [editParticipant, setEditParticipant] = React.useState(false);

  useEffect(() => {
    if (programData) {
      onDataLoaded();
    }
  }, [programData, onDataLoaded]);

  if (programLoading) return <p>Loading program data...</p>;
  if (programError) return <p>Error loading program data :(</p>;
  if (!programData) return null;

  const data = programData.getParticipantProgramDataByProgramNumber;

  return (
    <div>
      <h2 style={{ fontWeight: "bold", display: "flex", justifyContent: "flex-start" }}>
        <div style={{ marginRight: "0.5rem" }}>{programNameFromID(selectedProgram)} Program Data</div>
        <div style={{ textAlign: "left" }}>
          <button onClick={() => setEditParticipant(true)}>
            <div style={{ fontSize: "smaller" }}>Edit</div>
          </button>
        </div>
      </h2>
      <div style={{ paddingBottom: "1rem" }}></div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(7,1fr)",
          gridRowGap: "1.5rem",
        }}
      >
        <div>
          <div style={{ fontWeight: "bold" }}>
            {programNameFromID(selectedProgram)} ID
          </div>
          <div>{selectedProgram}</div>
        </div>
        <div>
          <div style={{ fontWeight: "bold" }}>
            {data.legacyNameHistory.map((name: any) => (
              <div key={name.firstName}>
                {name.firstName} {name.lastName}
              </div>
            ))}
          </div>
        </div>

        <div>
          <b>Gender</b> : <div>{data.gender || "N/A"}</div>
        </div>

        <div>
          <b>DOB</b> : <div>{isoDateToMDY(data.dob) || "N/A"}</div>
        </div>

        <div>
          <b>Race</b> : <div>{data.race || "N/A"}</div>
        </div>

        <div>
          <b>IS Number</b> : <div>{data.isnumber || "N/A"}</div>
        </div>
        <div>
          <b>Pathway</b> : <div>{data.pathway || "N/A"}</div>
        </div>
        <div>
          <b>Provider</b> : <div>{data.provider || "N/A"}</div>
        </div>
        <div>
          <b>JCIT</b> : <div>{data.jcit || "N/A"}</div>
        </div>
        <div>
          <b>Probation</b> :{" "}
          <div>{formatProbation(data.probation) || "N/A"}</div>
        </div>
        <div>
          <b>Released On</b> :{" "}
          <div>{isoDateToMDY(data.releaseDate) || "N/A"}</div>
        </div>
        <div>
          <b>Disposition</b> :
          <div>{data.disposition?.disposition || "N/A"}</div>
          <div>{isoDateToMDY(data.disposition?.dispositionDate) || "N/A"}</div>
        </div>
      </div>
      {<EditParticipant
          isOpen={editParticipant}
          onClose={() => setEditParticipant(false)}
          programNumber={parseInt(selectedProgram)}
        />}
    </div>
  );
};
