import React, { useState, useEffect } from 'react';
import { useLazyQuery, useSubscription } from '@apollo/client';
import gql from 'graphql-tag';
import { useHelpTopics } from '../../../store/useHelpTopics';

// Define the GraphQL query and subscription
const DATABASE_HEALTH_REPORT_QUERY = gql`
  query DatabaseHealthReport ($checkCanonical: Boolean, $checkNull: Boolean, $checkDates: Boolean, $checkGender: Boolean, $checkMrun: Boolean, $checkBooking: Boolean, $checkCase: Boolean, $checkChamp: Boolean) {
    databaseHealthReport (checkCanonical: $checkCanonical, checkNull: $checkNull, checkDates: $checkDates, checkGender: $checkGender, checkMrun: $checkMrun, checkBooking: $checkBooking, checkCase: $checkCase, checkChamp: $checkChamp) {
      message
    }
  }
`;

const DATABASE_HEALTH_REPORT_SUBSCRIPTION = gql`
  subscription SubscriptionDatabaseHealthReport {
    subscriptionDatabaseHealthReport {
      id
      json
    }
  }
`;

export const DatabaseHealthReportPage = () => {
  const { pushHelpTopic, popHelpTopic } = useHelpTopics();
  const [settings, setSettings] = useState({
    checkCanonical: false,
    checkNull: false,
    checkDates: false,
    checkGender: false,
    checkMrun: false,
    checkBooking: false,
    checkCase: false,
    checkChamp: false,
  });

  useEffect(() => {
    pushHelpTopic('Database Health Report');
    return () => {
      popHelpTopic();
    };
  }, []);

  const [healthReports, setHealthReports] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Use the query to start the report
  const [runQuery, { data: queryData, error: queryError }] = useLazyQuery(DATABASE_HEALTH_REPORT_QUERY) as any;

  // Use the subscription to receive updates
  const { data: subscriptionData, error: subscriptionError } = useSubscription(DATABASE_HEALTH_REPORT_SUBSCRIPTION) as any;

  useEffect(() => {
    if (queryError) {
      setError(queryError.message);
      setLoading(false);
    }
    if (subscriptionError) {
      setError(subscriptionError.message);
      setLoading(false);
    }
  }, [queryError, subscriptionError]);

  useEffect(() => {
    if (queryData) {
      console.log('Database Health Report started:', queryData.databaseHealthReport.message);
    }
  }, [queryData]);

  useEffect(() => {
    if (subscriptionData) {
      setHealthReports((prevReports: any[]) => [...prevReports, subscriptionData.subscriptionDatabaseHealthReport]);
      setLoading(false);
    }
  }, [subscriptionData]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      [event.target.name]: event.target.checked,
    });
  };

  const handleRunReport = () => {
    setLoading(true);
    setHealthReports([]);
    runQuery({ variables: settings });
  };

  if (loading) return <p>Loading database health report...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <h1>Database Health Report</h1>
      <div>
        {Object.keys(settings).map((key) => (
          <label key={key}>
            <input
              type="checkbox"
              name={key}
              checked={settings[key as keyof typeof settings]}
              onChange={handleCheckboxChange}
            />
            {key.replace('check', '')}
          </label>
        ))}
      </div>
      <button onClick={handleRunReport}>Run Report</button>
      <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr'}}>
        <div>ID</div>
        <div>Table</div>
        <div>Status</div>
        <div>Column</div>
        <div>Auto Repair</div>
      </div>
      <div>
        {healthReports.map((report: any, index: number) => {
          const json = JSON.parse(report.json)
          return (
            <div key={index} className={index % 2 === 0 ? 'alt' : ''}>
              <div>{report.id}</div>
              {json && json.map((error: any, index: number) => (
                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', marginLeft: '5rem'}} key={index}>
                  <div>{error.status}</div>
                  <div>{error.column}</div>
                  <div>{error.value}</div>
                </div>
              ))}
            </div>
          )
        })}
      </div>
    </div>
  );
};