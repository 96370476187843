import Modal from 'react-modal';
//import { useManualRefreshJWT } from '../../store/userstore/useJWTStore';
import React, { useState, useEffect, useRef } from 'react';

import { TIMEOUT_PERIODS } from '../../settings';
import { useAuthenticationState } from '../../store/useAuthenticationState';
import { gql, useMutation } from '@apollo/client';

import {WarningControl} from './AutoLogoutComponents/warningControl';
import {LockScreen} from './AutoLogoutComponents/lockScreen';
import {DiscountModal} from './AutoLogoutComponents/discountModal';

const REFRESH_JWT = gql`
  mutation RefreshJWT {
    refreshJWT
  }
`;




export const AutoLogout = () => {    
    const {setAuthenticationState} = useAuthenticationState();
    const [refreshJWTMutation] = useMutation(REFRESH_JWT);

    const [state, setState] = useState('active');    

    const lastActivityTime = useRef(Date.now());    

    const refreshJWT = async () => {
        const {data} = await refreshJWTMutation();
        setAuthenticationState(data.refreshJWT);
        return data.refreshJWT;
    }


    const checkUserActivity = ()  => {          
        refreshJWT();
        if (state=='locked') return
        if (state=='pending') return

        if (Date.now() - lastActivityTime.current > (TIMEOUT_PERIODS.ACTIVITYTIME )) {            
           return setState('pending');
        }        
    }

    const handleActivity = () => {
        lastActivityTime.current = Date.now();        
    }
    
    const reset = () => {
        setState('active');
        handleActivity();
      };

    useEffect(() => {
        const sessionTimer = setInterval(checkUserActivity, TIMEOUT_PERIODS.SESSION );
        refreshJWT();

        document.addEventListener('mousemove', handleActivity);
        document.addEventListener('keydown', handleActivity);

        return () => {
            document.removeEventListener('mousemove', handleActivity);
            document.removeEventListener('keydown', handleActivity);
            clearInterval(sessionTimer);
        };
    }, [state]); // Empty dependency array


        return (
            <div>
            {(state == 'pending' || state == 'locked') && 
                <DiscountModal>
                    {state=='pending' && <WarningControl onReset={reset} lock={()=>{setState('locked')}}   />}            
                    {state=='locked' && <LockScreen onSuccess={reset}   />}            
                </DiscountModal>}
            </div>
        )
        
};


