import React, { useState, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import FormTemplate from './forms/careplan.json';
import { FormRenderer } from './lib/formRender';
import { useParams } from 'react-router-dom';

export const settingsCarePlan = {
  withPrint: true,
}

const UPDATE_CALAIM_INITIAL_ASSESSMENT = gql`
  mutation UpdateCalAimInitialAssessment(
    $id: Int
    $data: CalAIMInitialAssessmentInput!
  ) {
    updateCalAIMInitialAssessment(id: $id, data: $data) {
      id
      
    }
  }
`;

const getCalAIMCarePlan = gql`
  query getCalAIMCarePlan($documentID: Integer!) {
    getCalAIMCarePlan(documentID: $documentID) {
      cin
      contents
      id
      metadata
      name
      odrparticipant
      published
      tags
      type
    }
  }
`;




export const FormCarePlan = (params:any) => {
  const { id: ReactID } : any= params
  const { id: URLID } : any= useParams();  

  const id = ReactID || URLID;  
  const { loading, error, data } = useQuery(getCalAIMCarePlan, {
    variables: { documentID : id}
  });

  const [state, setState] = useState({}) as any;


  useEffect(() => {
      if (data && !loading) {
        const newstate = {} as any;
        const form = JSON.parse(data.getCalAIMCarePlan.contents);
        for (let key in form) {
          newstate[key] = form[key];
          if (form[key] && typeof form[key] === 'string' && /\d{4}-\d{2}-\d{2}T.*/.test(form[key])) {
            newstate[key] = form[key].substring(0, 10);
          } 
        }

        setState(newstate);
      }
  }, [data, loading]);

  if (loading) return <p>Loading...</p>;

  return (
    <div>
    
      <div>
        {FormTemplate.map((item: any, index: number) => {

        if (item.type == 'heading') return (<h3 key={index}>{item.text}</h3>)
          if (item.type == 'text') return (<h5 key={index}>{item.text}</h5>)

          let value =''
          if (state && !item.options) { value = state[item.name]; }
          
          if (state && item.type == 'select'&& item.options) { 
            item.options.map ((option: any) => {
              if (option.value == state[item.name]) {
                value = option.text;
              }
            })
          }

          if (state && item.type == 'toggle') {
            value = state[item.name] == 1 ?  'Yes' : 'No';
          }

          return (
            <div key={index} className='columns4' style={{ marginBottom: '1rem' }}>
              <span >{item.text}</span>
              <span style={{ fontWeight: 'bold', marginLeft: '4rem' }}>{value}</span>
            </div>
          );
        }
        )}
      </div>
    </div>
  );
};
