import React from "react";
import ReactModal from "react-modal";
import { useMutation, gql } from '@apollo/client';

const UPDATE_PARTICIPANT_ENROLLMENT = gql`
  mutation updateParticipantEnrollment(    
    $programNumber: Integer!
    $id: Integer!
    $updates: participantProgramDataEnrollmentInput
  ) {
    updateParticipantEnrollment(      
      programNumber: $programNumber
      id: $id
      updates: $updates
    ) {      
      message
    }
  }
`;

const EnrollmentSites = [
    "SLA/LB", "HW", "Rampart", "ELA"
];

const ReferralTypes = [
    "Social Contact", "Prebooking", "Community Referral"
];

export const EditEnrollment = ({ participant, onClose, data }:any) => {
    const [updatedEnrollment, setUpdatedEnrollment] = React.useState(data || {});
    const [updateParticipantEnrollment] = useMutation(UPDATE_PARTICIPANT_ENROLLMENT);

    const handleSubmit = () => {
        const variables = {          
            programNumber: participant,
            id : data.id,
            updates: {
                enrollmentDate: updatedEnrollment.enrollmentDate ? new Date(updatedEnrollment.enrollmentDate) : null,
                exitDate: updatedEnrollment.exitDate ? new Date(updatedEnrollment.exitDate) : null,
                exitReason: updatedEnrollment.exitReason,
                notes: updatedEnrollment.notes, // Changed from note to notes to match the data structure
                referralType: updatedEnrollment.referralType,
                referringAgency: updatedEnrollment.referringAgency,
                referringIndividual: updatedEnrollment.referringIndividual,
                site: updatedEnrollment.site
            }
        };
        updateParticipantEnrollment({ variables })
            .then(() => {
                onClose();
                // You might want to refetch the enrollment data here or update the local state
            })
            .catch(error => {
                console.error("Error updating enrollment:", error);
                // Handle error (e.g., show error message to user)
            });
    };

    return (
        <ReactModal 
            isOpen={true} 
            style={{
                overlay: {
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.75)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                },
                content: {
                    position: "relative",
                    background: "white",
                    padding: "20px",
                    maxHeight: "80vh",
                    overflowY: "scroll",
                    width: "100%",
                    maxWidth: "800px",
                    inset: 0,
                }
            }} 
            onRequestClose={onClose}
        >
            <div style={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
                <button type="button" onClick={onClose}>Close</button>
            </div>
            <h2>Update Enrollment</h2>
            <div>
                <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px", marginBottom: "10px"}}>
                    <label>Enrollment Date</label>
                    <input 
                        type="date" 
                        value={updatedEnrollment.enrollmentDate || ''} 
                        onChange={(e) => setUpdatedEnrollment({...updatedEnrollment, enrollmentDate: e.target.value})}
                    />                
                </div>

                <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px", marginBottom: "10px"}}>
                    <label>Enrollment Site</label>
                    <select 
                        value={updatedEnrollment.site || ''} 
                        onChange={(e) => setUpdatedEnrollment({...updatedEnrollment, site: e.target.value})}
                    >
                        <option value="">Select a site</option>
                        {EnrollmentSites.map((site) => (
                            <option key={site} value={site}>{site}</option>
                        ))}
                    </select>
                </div>

                <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px", marginBottom: "10px"}}>
                    <label>Referring Agency</label>
                    <input 
                        type="text" 
                        value={updatedEnrollment.referringAgency || ''} 
                        onChange={(e) => setUpdatedEnrollment({...updatedEnrollment, referringAgency: e.target.value})}
                    />                
                </div>

                <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px", marginBottom: "10px"}}>
                    <label>Referring Individual</label>
                    <input 
                        type="text" 
                        value={updatedEnrollment.referringIndividual || ''} 
                        onChange={(e) => setUpdatedEnrollment({...updatedEnrollment, referringIndividual: e.target.value})}
                    />                
                </div>

                <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px", marginBottom: "10px"}}>
                    <label>Referral Type</label>
                    <select 
                        value={updatedEnrollment.referralType || ''} 
                        onChange={(e) => setUpdatedEnrollment({...updatedEnrollment, referralType: e.target.value})}
                    >
                        <option value="">Select a referral type</option>
                        {ReferralTypes.map((type) => (
                            <option key={type} value={type}>{type}</option>
                        ))}
                    </select>
                </div>

                <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px", marginBottom: "10px"}}>
                    <label>Exit Date</label>
                    <input 
                        type="date" 
                        value={updatedEnrollment.exitDate || ''} 
                        onChange={(e) => setUpdatedEnrollment({...updatedEnrollment, exitDate: e.target.value})}
                    />
                </div>

                <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px", marginBottom: "10px"}}>
                    <label>Exit Reason</label>
                    <input 
                        type="text" 
                        value={updatedEnrollment.exitReason || ''} 
                        onChange={(e) => setUpdatedEnrollment({...updatedEnrollment, exitReason: e.target.value})}
                    />
                </div>

                <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px", marginBottom: "10px"}}>
                    <label>Enrollment Notes</label>
                    <textarea 
                        value={updatedEnrollment.notes || ''} 
                        onChange={(e) => setUpdatedEnrollment({...updatedEnrollment, notes: e.target.value})}
                    />
                </div>
            </div>
            <button onClick={handleSubmit}>Update</button>
        </ReactModal>
    );
};