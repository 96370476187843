import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useQuery, useMutation, gql } from "@apollo/client";

/**
 * Formats a date string from ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) to MM-DD-YYYY format.
 * @param {string} date - The date string in ISO format.
 * @returns {string} The formatted date string in MM-DD-YYYY format.
 */
const formatDateString = (date: string): string => {
  if (!date) return "";

  // Parse the ISO date string
  const parsedDate = new Date(date);

  // Extract month, day, and year
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
  const day = String(parsedDate.getDate()).padStart(2, "0");
  const year = parsedDate.getFullYear();

  // Return the formatted date string
  return `${month}-${day}-${year}`;
};

const GET_PARTICIPANT_DATA = gql`
  query GetParticipantData($programNumber: Integer!) {
    getParticipantProgramDataByProgramNumber(programNumber: $programNumber) {
      ab109
      champid
      dispositionHistory {
        disposition
        dispositionDate
      }
      dobHistory {
        dob
        canonical
      }
      gender
      isnumber
      legacyNameHistory {
        firstName
        lastName
        entrydate
        canonical
      }
      mrun
      name {
        firstName
        lastName
      }
      provider
      race
    }
  }
`;

const UPDATE_PARTICIPANT_PROGRAM_DATA = gql`
  mutation updateParticipantProgramDataByProgramNumber(
    $programNumber: Integer!
    $updates: participantProgramDataInput
  ) {
    updateParticipantProgramDataByProgramNumber(
      programNumber: $programNumber
      updates: $updates
    ) {
      responseCode
      message
    }
  }
`;

const style = {
  marginBottom: "2rem",
  border: "1px solid #ccc",
  borderRadius: "1px",
  padding: "0.5rem",
};

export const EditParticipant = ({
  isOpen,
  onClose,
  programNumber,
}: {
  isOpen: boolean;
  onClose: () => void;
  programNumber: number;
}) => {
  const { loading, error, data, refetch } = useQuery(GET_PARTICIPANT_DATA, {
    variables: { programNumber },
  });

  const [updateParticipant] = useMutation(UPDATE_PARTICIPANT_PROGRAM_DATA);
  const [formData, setFormData] = useState<any>({});
  const [programs, setPrograms] = useState([]);

  useEffect(() => {
    if (data) {
      setFormData(data.getParticipantProgramDataByProgramNumber);
      setPrograms(data.getPrograms);
    }
    console.log(data);
  }, [data]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;
    setFormData((prev: any) => {
      const [parent, child] = name.split(".");
      if (child) {
        return {
          ...prev,
          [parent]: {
            ...prev[parent],
            [child]:
              type === "checkbox"
                ? (e.target as HTMLInputElement).checked
                  ? "1"
                  : "0"
                : value,
          },
        };
      } else {
        return {
          ...prev,
          [name]:
            type === "checkbox"
              ? (e.target as HTMLInputElement).checked
                ? "1"
                : "0"
              : value,
        };
      }
    });
  };

  const handleNameChange = (index: number, field: string, value: string) => {
    setFormData((prev: any) => {
      const newNames = [...prev.names];
      newNames[index] = { ...newNames[index], [field]: value };
      return { ...prev, names: newNames };
    });
  };

  const handleDOBChange = (index: number, value: string) => {
    setFormData((prev: any) => {
      const newDOBs = [...prev.dobs];
      newDOBs[index] = { ...newDOBs[index], date: value };
      return { ...prev, dobs: newDOBs };
    });
  };

  const handleISNumberChange = (index: number, value: string) => {
    setFormData((prev: any) => {
      const newISNumbers = [...prev.isNumbers];
      newISNumbers[index] = { ...newISNumbers[index], number: value };
      return { ...prev, isNumbers: newISNumbers };
    });
  };

  const handleCanonicalChange = (field: string, index: number) => {
    setFormData((prev: any) => {
      const newArray = prev[field].map((item: any, i: number) => ({
        ...item,
        canonical: i === index,
      }));
      return { ...prev, [field]: newArray };
    });
  };

  const addName = () => {
    setFormData((prev: any) => ({
      ...prev,
      names: [...prev.names, { firstName: "", lastName: "", canonical: false }],
    }));
  };

  const addDOB = () => {
    setFormData((prev: any) => ({
      ...prev,
      dobs: [...prev.dobs, { date: "", canonical: false }],
    }));
  };

  const addISNumber = () => {
    setFormData((prev: any) => ({
      ...prev,
      isNumbers: [...prev.isNumbers, { number: "", canonical: false }],
    }));
  };

  const deleteISNumber = (index: number) => {
    setFormData((prev: any) => ({
      ...prev,
      isNumbers: prev.isNumbers.filter((_: any, i: number) => i !== index),
    }));
  };

  const handleProgramChange = (newProgram: string) => {
    // Handle program change logic
    console.log("Program changed to:", newProgram);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const updatedData = {
      ab109: formData.ab109,
      champid: formData.champid,
      gender: formData.gender,
      isnumber: formData.isnumber,
      mrun: formData.mrun,
      mrunStatus: formData.mrunStatus,
      name: formData.name
        ? {
            firstName: formData.name.firstName,
            lastName: formData.name.lastName,
          }
        : undefined,
      provider: formData.provider,
      race: formData.race,
      disposition: formData.disposition
        ? {
            disposition: formData.disposition.disposition,
            dispositionDate: formData.disposition.dispositionDate,
            otherReason: formData.disposition.otherReason,
          }
        : undefined,
      care: formData.care,
    };

    // Remove undefined properties
    Object.keys(updatedData).forEach((key) => {
      if (updatedData[key as keyof typeof updatedData] === undefined) {
        delete updatedData[key as keyof typeof updatedData];
      }
    });

    console.log("Updated form data:", updatedData);

    updateParticipant({
      variables: {
        programNumber,
        updates: updatedData,
      },
    });

    refetch();
    onClose();
  };

  const categoryList = [
    "CBR Placement",
    "CBR to Diversion Placement",
    "Diversion Placement",
    "Maxed Out",
    "Diversion Completed",
    "Unrestorable",
    "Restored",
    "Lost to Follow-Up",
    "Conserved",
    "Cancelled",
    "DMH",
    "Other",
  ];
  const providerOptions = [
    "CCM",
    "HR360",
    "OVMC",
    "SERENITY",
    "ALCOTT",
    "AP RECOVERY",
    "BUILDING LIVES",
    "NONE",
  ];
  return (
    <ReactModal
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        content: {
          position: "relative",
          background: "white",
          padding: "20px",
          maxHeight: "80vh",
          overflowY: "auto",
          width: "90%",
          maxWidth: "1200px",
        },
      }}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>Edit Participant</h2>
          <button type="button" onClick={onClose}>
            Close
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          {/* Programs */}
          {/* <div>
            <h3>Programs</h3>
            <select
              value={formData.program}
              onChange={(e) => handleProgramChange(e.target.value)}
            >
              {programs.map((program: any) => (
                <option key={program.slug} value={program.slug}>
                  {program.name}
                </option>
              ))}
            </select>
          </div> */}

          <div style={style}>
            {/* Names */}
            <div style={{ display: "grid",gridTemplateColumns: "15rem 10rem",  gap: "10px", paddingBottom: "10px",}} >
              <div>Names</div>
              <div><button type="button" onClick={addName}>Add Name</button></div>
            </div>
            
              {formData.legacyNameHistory &&
                formData.legacyNameHistory.map((name: any, index: number) => (
                  <div key={index} style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: "10px"}}>                    
                      <div> {new Date(name.entrydate *(name.entrydate > 999999999999 ? 1 : 1000)).toLocaleString("en-US", {year: "numeric",month: "2-digit",day: "2-digit",})}</div>
                      <div>{name.firstName}</div>
                      <div>{name.lastName}</div>
                      <div><input type="checkbox" checked={name.canonical} onChange={() => handleCanonicalChange("legacyNameHistory", index) }/> Real</div>
                    </div>                  
                ))}            
          </div>

          <div style={style}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "15rem 20rem",
                gap: "10px",
              }}
            >
              <div>Gender</div>
              <div>
                <select
                  name="gender"
                  value={formData.gender}
                  onChange={handleInputChange}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Transgender">Transgender</option>
                </select>
              </div>

              <div>Race</div>
              <div>
                <select
                  name="race"
                  value={formData.race}
                  onChange={handleInputChange}
                >
                  <option value="White">White</option>
                  <option value="Black">Black</option>
                  <option value="Asian">Asian</option>
                  <option value="Native Hawaiian">Native Hawaiian</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>

            <div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "15rem 10rem",
                  gap: "10px",
                  paddingTop: "10px",
                }}
              >
                <div>Date of Birth</div>
                <div>
                  <button type="button" onClick={addDOB}>
                    Add DOB
                  </button>
                </div>
              </div>
              {formData.dobHistory &&
                formData.dobHistory.map((dob: any, index: number) => (
                  <div
                    key={index}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "10rem 10rem 10rem",
                      gap: "10px",
                    }}
                  >
                    <div>{formatDateString(dob.dob)}</div>
                    <label>
                      <input
                        type="checkbox"
                        name="dob_canonical"
                        checked={dob.canonical}
                        onChange={() => handleCanonicalChange("dobs", index)}
                      />
                      Real
                    </label>
                  </div>
                ))}
            </div>
          </div>

          <div style={style}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "15rem 20rem",
                gap: "10px",
                paddingTop: "1rem",
              }}
            >
              <div>CHAMP ID</div>
              <div>
                <input
                  type="text"
                  name="champid"
                  value={formData.champid}
                  onChange={handleInputChange}
                />
              </div>

              <div style={{ paddingTop: "0.5rem" }}>
                MRUN: (9 digit number, no dashes needed)
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 10rem",
                  gap: "10px",
                }}
              >
                <input
                  type="text"
                  name="mrun"
                  value={formData.mrun || ""}
                  onChange={handleInputChange}
                  maxLength={9}
                />
                <select
                  name="mrun-status"
                  value={formData.mrunStatus || ""}
                  onChange={handleInputChange}
                >
                  <option value="Open">Open</option>
                  <option value="Closed">Closed</option>
                </select>
              </div>

              <div style={{ paddingTop: "0.5rem" }}>IS Number</div>
              <div>
                <input
                  type="text"
                  name="mrunStatus"
                  value={formData.isNumber || ""}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          {/* DOB */}

          {/* Care (5%) */}
          <div>
            <h3>5%</h3>
            <label>
              <input
                type="radio"
                name="care"
                value="1"
                checked={formData.care === "1"}
                onChange={handleInputChange}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="care"
                value="0"
                checked={formData.care === "0"}
                onChange={handleInputChange}
              />
              No
            </label>
          </div>

          {/* IS Numbers */}
          <div>
            <h3>IS Number</h3>
            {formData.isNumbers &&
              formData.isNumbers.map((isNumber: any, index: number) => (
                <div key={index}>
                  <input
                    type="text"
                    value={isNumber.number}
                    onChange={(e) =>
                      handleISNumberChange(index, e.target.value)
                    }
                    maxLength={7}
                  />
                  <label>
                    <input
                      type="radio"
                      name="isnumber_canonical"
                      checked={isNumber.canonical}
                      onChange={() => handleCanonicalChange("isNumbers", index)}
                    />
                    Current/Preferred
                  </label>
                  <button type="button" onClick={() => deleteISNumber(index)}>
                    Delete
                  </button>
                </div>
              ))}
          </div>

          {/* AB109 */}
          <div>
            <h3>AB109</h3>
            <select
              name="ab109"
              value={formData.ab109}
              onChange={handleInputChange}
            >
              <option value="funded">Funded</option>
              <option value="individual">Individual</option>
              <option value="none">None</option>
            </select>
          </div>

          {/* Disposition */}
          <div>
            <h3>Disposition</h3>
            <select
              name="disposition.disposition"
              value={formData.disposition?.disposition || ""}
              onChange={handleInputChange}
            >
              {categoryList.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
            {formData.disposition?.disposition === "Other" && (
              <input
                type="text"
                name="disposition.otherReason"
                value={formData.disposition.otherReason || ""}
                onChange={handleInputChange}
                placeholder="Specify Other Disposition"
              />
            )}
            <input
              type="date"
              name="disposition.dispositionDate"
              value={formData.disposition?.dispositionDate || ""}
              onChange={handleInputChange}
            />
          </div>

          {/* Disposition History */}
          <div>
            <h3>Disposition History</h3>
            <table>
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {formData.dispositionHistory &&
                  formData.dispositionHistory.map(
                    (disposition: any, index: number) => (
                      <tr key={index}>
                        <td>{disposition.disposition}</td>
                        <td>
                          {new Date(disposition.date).toLocaleDateString() ||
                            "N/A"}
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
          </div>

          {/* Provider */}
          <div>
            <h3>Provider</h3>
            <select
              name="provider"
              value={formData.provider || ""}
              onChange={handleInputChange}
            >
              <option value="">Select Provider</option>
              {providerOptions.map((provider) => (
                <option key={provider} value={provider}>
                  {provider}
                </option>
              ))}
            </select>
          </div>

          {/* Submit and Cancel buttons */}
          <div>
            <button type="submit">Update</button>
            <button type="button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </ReactModal>
  );
};
