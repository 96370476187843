import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import { gql, useQuery } from '@apollo/client';

const GET_PARTICIPANT_SCAN = gql`
  query GetParticipantScan($odrnumber: Integer!, $scanID: Integer!) {
    getParticipantScan(odrnumber: $odrnumber, scanID: $scanID) {
      id
      filename
      filetype
      filelabel
      filedate
      content
      contentType
    }
  }
`;

export const ScanPreview = ({ odrnumber, scan, close }: { odrnumber: number, scan: number, close: () => void }) => {
    const { loading, error, data } = useQuery(GET_PARTICIPANT_SCAN, {
      variables: { odrnumber, scanID: scan },
      skip: !odrnumber || !scan,
    });
  
    const scanData = data?.getParticipantScan;
    const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
    const [showFallback, setShowFallback] = useState(false);
  
    useEffect(() => {
      if (scanData && scanData.content) {
        const binaryData = atob(scanData.content);
        const extension = scanData.filename.split('.').pop()?.toLowerCase() || '';
        let contentType = scanData.contentType;
  
        // Set content type based on file extension
        if (extension === 'pdf') {
          contentType = 'application/pdf';
        } else if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
          contentType = `image/${extension}`;
        }
  
        const blob = new Blob([new Uint8Array(binaryData.split('').map(char => char.charCodeAt(0)))], { type: contentType });
        const url = URL.createObjectURL(blob);
        setDownloadUrl(url);
  
        return () => {
          URL.revokeObjectURL(url);
        };
      }
    }, [scanData, scan]);
  
    const renderFilePreview = () => {
      if (!scanData || !scanData.content || !downloadUrl) return null;
      
      const extension = scanData.filename.split('.').pop()?.toLowerCase() || '';
      const fileName = scanData.filelabel || scanData.filename || `download.${extension}`;
  
      if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
        return <img src={downloadUrl} alt="Scan preview" style={{ maxWidth: '100%', maxHeight: '80vh' }} />;
      }
  
      if (extension === 'pdf') {
        return (
          <>
            {!showFallback && (
              <iframe
                src={downloadUrl}
                style={{ width: '100%', height: '80vh', display: showFallback ? 'none' : 'block' }}
                onError={() => setShowFallback(true)}
              />
            )}
            {showFallback && (
              <div>
                <p>Unable to display PDF. The file might be corrupted or in an unsupported format.</p>                
              </div>
            )}
          </>
        );
      }
  
      return (
        <div>
          <div style={{marginBottom: '10px', marginTop: '10px'}}>Unable to Preview File</div>
          
        </div>
      );
    };
  
    return (
      <div>
        <ReactModal isOpen={true} onRequestClose={close}>
          <div style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
            <div>
              {downloadUrl && scanData && (
                <a href={downloadUrl} download={`${scanData.filelabel || scanData.filename.split('.')[0]}.${scanData.filename.split('.').pop()}`}>
                  Download File
                </a>
              )}
            </div>
            <button onClick={close}>Close</button>
          </div>
          {loading && <p>Loading...</p>}
          {error && <p>Error: {error.message}</p>}
          {!loading && !error && renderFilePreview()}
        </ReactModal>
      </div>
    );
  };