import { useState } from 'react';

export const eraseCookie = (key: string) => {
	document.cookie = `${key}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
}

export const setCookie = (key: string, value: string) => {
	document.cookie = `${key}=${value}; path=/`;
}

export const getCookie = (key: string) => {
	const cookie = document.cookie.match(
		'(^|[^;]+)\\s*' + key + '\\s*=\\s*([^;]+)',
	);
	if (cookie) {
		const tasty = cookie.pop();
		return tasty === 'null' ? null : tasty;
	}
	return null;
};

