import React from 'react';
import { useQuery, gql, useMutation, useApolloClient } from '@apollo/client';
import { useParams,Link } from 'react-router-dom';
import { FormInput } from './lib/formRender';
import { FormView } from './lib/formView';
import  ReactModal from 'react-modal';
import {toast} from 'react-toastify';

import { FormCarePlan } from './careplan';
import { FormInitialAssessment } from './initialassessment';

import { useUser } from '../../store/useUser';

import { SearchParticipants } from './search';
import { useSetHelpTopic } from '../../store/useHelpTopics';

const DELETE_PARTICIPANT_DOCUMENT = gql`
  mutation DeleteParticipantDocument($id: Integer!) {
    deleteParticipantDocument(id: $id) {
      id      
    }
  }
`;


const GET_PARTICIPANT_DOCUMENT = gql`
  query GetParticipantDocument($documentID: Integer!) {
    getParticipantDocument(documentID: $documentID) {
      id
      odrparticipant
      contents
      template
      tags            
      type
      metadata
    }
  }
`;

const UPDATE_PARTICIPANT_DOCUMENT = gql`
  mutation UpdateParticipantDocument($id: Integer!, $updates: GenericJSONDocumentInput!) {
    updateParticipantDocument(id: $id, updates: $updates) {
      id
      contents
      published
      tags            
      metadata
      type
      date
      odrparticipant
      template
    }
  }
`;

const CREATE_PARTICIPANT_DOCUMENT = gql`
  mutation CreateParticipantDocument($updates: GenericJSONDocumentInput!) {
    createParticipantDocument( updates: $updates) {
      id
      contents
      published
      tags            
      metadata
      type
      date
      odrparticipant
      template
    }
  }
`;

const LIST_FORM_TEMPLATES = gql`
  query listFormTemplates {
    listFormTemplates {
      id
      name
      body
    }
  }
`;


const TRANSFER_PARTICIPANT_DOCUMENTS = gql`
  mutation TransferParticipantDocuments($odrnumber: Integer!, $documentID: [String]) {
    transferParticipantDocuments(odrnumber: $odrnumber, documentID: $documentID)
  }
`;

const GET_PARTICIPANT_BY_ODRNUMBER = gql`
  query GetParticipantByODRNumber($odrnumber: Integer!) {
    getParticipantByODRNumber(odrnumber: $odrnumber) {
      id 
      name {
        firstName
        lastName
      }
  
      documents {
        id        
        published
        tags                
        type        
        metadata
        date
      }
    }
  }
`;

const d = (date:any)=>{
  if (!date) return ('')
  const [year, month, day] = date.substring(0, 10).split('-');
  return `${month}-${day}-${year}`;
}

const alias = {
  LACAREREF : 'LACare Referral',
  LACARE_Initial_Assessment : 'Imported Initial Assessment',
  LACARE_Care_Plan : 'Imported Care Plan',
  LACAREProgram_Completion : 'Imported Program Completion',
} as any

export const ParticipantDocuments = (params:any) => {
  const { participant: reactParticipant } = params
  const { odrparticipant: urlParticipant } = useParams() as any;
  const odrparticipant = reactParticipant ||urlParticipant;

  useSetHelpTopic('Participant Documents');

  
  const [createDocument] = useMutation(CREATE_PARTICIPANT_DOCUMENT);
  const [deleteDocument] = useMutation(DELETE_PARTICIPANT_DOCUMENT);
  const [updateDocument] = useMutation(UPDATE_PARTICIPANT_DOCUMENT);

  const [transferDocuments] = useMutation(TRANSFER_PARTICIPANT_DOCUMENTS);

  const { user, isUserLoading } = useUser();
  const [participant, setParticipant] = React.useState ({ name: { firstName: '', lastName: '' }, documents: [] });


  const [isAltView, setIsAltView] = React.useState(false) as any;
  //const participant = data.getParticipantByODRNumber ||{ name: { firstName: '', lastName: '' }, documents: [] };


  const { loading: docListLoading, error: docListError, data: docList } = useQuery(LIST_FORM_TEMPLATES);
  const { loading, error, refetch } = useQuery(GET_PARTICIPANT_BY_ODRNUMBER, {
    variables: { odrnumber: parseInt(odrparticipant) },
    onCompleted: (data) => {
      const participantData = data.getParticipantByODRNumber ||{ name: { firstName: '', lastName: '' }, documents: [] };
        if (!participantData.name ) {
          participantData.name = { firstName: '', lastName: '' }
        }
        if (!participantData.documents) {
          participantData.documents = []
          }
        setParticipant(participantData)      
      }
  }); 

  const client = useApolloClient();
  const importPreviousCarePlan = async () => {

    const mostRecentCarePlan = findMostRecentCarePlan();
    if (!mostRecentCarePlan) {
      toast.error('No previous Care Plan found');
      return;
    }
  
    try {
      const { data } = await client.query({
        query: GET_PARTICIPANT_DOCUMENT,
        variables: { documentID: mostRecentCarePlan.id },
      });
  
      if (data && data.getParticipantDocument) {
        setNewDocumentState(JSON.parse(data.getParticipantDocument.contents));
        toast.success('Previous Care Plan imported');
      }
    } catch (error) {
      console.error('Error importing previous Care Plan', error);
      toast.error('Error importing previous Care Plan');
    }
  };

  const [currentSelectedType, setCurrentSelectedType] = React.useState('') as any;
  const [newDocumentState, setNewDocumentState] = React.useState() as any;
  const [newDocumentModal, setNewDocumentModal] = React.useState() as any;

  const [transferDocumentModal, setTransferDocumentModal] = React.useState() as any;
  const [currentDocumentID, setCurrentDocumentID] = React.useState(null) as any;
  const [currentDocument, setCurrentDocument] = React.useState({template:[]}) as any;
  const [printView, setPrintView] = React.useState(false) as any;  
  const [editMode, setEditMode] = React.useState(false) as any;
  const [adminView, setAdminView] = React.useState(false) as any;

  const [selectDocuments, setSelectDocuments] = React.useState([]) as any; 
  
  const CarePlanHeader = () => {
    return (
      <div style={{display: 'grid', gridTemplateColumns:'12rem 2rem 12rem', paddingTop: '1rem'}}>
        <button onClick={importPreviousCarePlan}>Import Previous Care Plan</button>
        <div/>
        <button onClick={()=>{setIsAltView(!isAltView)}}>
          {isAltView ? 'Full Care Plan' : 'Mini Care Plan View'}
        </button>
      </div>
    )
  }

  const startTransfer = async (participant:any) => {
    //create a popup to confirm transfer
    const confirmed = window.confirm(`Are you sure you want to transfer these document to ODR: ${participant.id} CID: ${participant.cid} ?`);
    if (!confirmed) {
      return; // User cancelled the deletion
    }

    const documentID = selectDocuments.map((i:any)=>`${i}`);
    console.log('transfer', participant, documentID)
    const { data } = await transferDocuments({
      variables: {
        odrnumber: participant.id,
        documentID: documentID
      }
    });


    setTransferDocumentModal(false);
    refetch();
  }

  //console.log('user', user)


  const [ legacyDocument, setLegacyDocument] = React.useState({
    id: null,
    type : '',  
  
  }) as any;

  React.useEffect (() => {    
    if (printView==true){
        window.print()
        setPrintView(false);        
    }
}, [printView]);


  useQuery(GET_PARTICIPANT_DOCUMENT, {
    variables: { documentID: currentDocumentID ? currentDocumentID : null },
    skip: currentDocumentID==null || currentDocumentID==undefined,
      onCompleted: (data) => {
        setCurrentDocument({ 
          id: data.getParticipantDocument.id,
          template: JSON.parse(data.getParticipantDocument.template),
          altTemplate: null
        });

        setNewDocumentState(JSON.parse(data.getParticipantDocument.contents));
        
    },
  });

  if (loading || docListLoading || isUserLoading) return <p>Loading...</p>;
  if (error || docListError) {    
    return <p>Error loading Data</p>;
  }

  
  
  const findMostRecentCarePlan = () => {
    const carePlans = participant.documents.filter((doc: any) => doc.type === 'Care Plan') as any;
    if (carePlans.length === 0) return null;
    return carePlans.reduce((mostRecent: any, current: any) => {
      return new Date(current.date) > new Date(mostRecent.date) ? current : mostRecent;
    });
  };
  

  const documentsByType = participant.documents.reduce((acc:any, document:any) => {
    const type = alias[document.type] || document.type;

    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(document);
    return acc;
  }, {});

  
const templateTypesFull = docList.listFormTemplates.map((template:any) => ({ ...template })); // Create a shallow copy of each template object

templateTypesFull.forEach((template: any) => {
  if (template.name === 'Care Plan') {
    // Find the corresponding altTemplate
    const altTemplate = templateTypesFull.find((t: any) => t.name === '_Care Plan');

    // Check if altTemplate exists and set the altTemplate property accordingly
    if (altTemplate) {
      // Create a new object with the same properties as template and add the altTemplate property
      template = { ...template, altTemplate: altTemplate.body };
    }
  }
});

// Now templateTypesFull contains objects with the altTemplate property

  
  const templatesByType = templateTypesFull.reduce((acc:any, template:any) => { 
    if (!acc[template.name]) {
      acc[template.name] = [];
    }
    acc[template.name]=template;
    return acc;
  },{})

  templateTypesFull.forEach((template: any) => {
    if (template.name === 'Care Plan') {
      // Find the corresponding altTemplate
      const altTemplate = templateTypesFull.find((t: any) => t.name === '_Care Plan');
  
      // Check if altTemplate exists and set the altTemplate property accordingly
      if (altTemplate) {
        template.altTemplate = altTemplate.body;
      }
    }
  });

  const templateTypes = templateTypesFull.filter((template: any) => !template.name.startsWith('_'));


  const createNewDocument = async () => {
    if (!currentSelectedType) {
      return toast.error('Please select a document type');
    }
    setNewDocumentState({})
    setNewDocumentModal(true);
  }


  const handleDeleteDocument = async () => {
    const confirmed = window.confirm('Are you sure you want to delete this document?');
    
    if (!confirmed) {
      return; // User cancelled the deletion
    }
  
    try {
      const { data } = await deleteDocument({
        variables: {
          id: currentDocument.id,          
        }
      });
      toast.success('Document Updated');
      setCurrentDocumentID(null)
      setEditMode(false) 
      refetch();
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleEditDocument = async () => {
    console.log('handleEditDocument', currentDocument)
    try {
      const { data } = await updateDocument({
        variables: {
          id: currentDocument.id,
          updates: {            
            contents: JSON.stringify(newDocumentState),
            published: 0,            
            metadata: '{}',
            type: currentSelectedType,            
            odrparticipant: parseInt(odrparticipant),
            template: JSON.stringify(currentDocument.template)
          }
        }
      });
      toast.success('Document Updated');
      setCurrentDocumentID(null)
      setEditMode(false) 
      refetch();
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleCreateDocument = async () => {
    try {
      const { data } = await createDocument({
        variables: {
          updates: {
            contents: JSON.stringify(newDocumentState),
            published: 0,            
            metadata: '{}',
            type: currentSelectedType,            
            odrparticipant: parseInt(odrparticipant),
            template: templatesByType[currentSelectedType].body
          }
        }
      });
      toast.success('Document Created');
      refetch();
    } catch (error) {
      // Handle error
    }
  };

  const viewDocument = async (document:any) => {    
    setCurrentDocumentID(document.id);
  };

  if (printView ){          
    const printStyle={
      position: 'fixed',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      backgroundColor: 'white', // Semi-transparent black background
      zIndex: '9999', // Set a high z-index to ensure it's on top of other elements
    } as any


      if (legacyDocument.type=='inital') return <div style={printStyle}><FormInitialAssessment id={legacyDocument.id} /></div>
      if (legacyDocument.type=='careplan') return <div style={printStyle}><FormCarePlan id={legacyDocument.id} /></div>
      
      return <FormView print ={true} formTemplate={currentDocument.template} formState={newDocumentState}/>
    
  }


  return (
    <div style={{display:'grid', gridTemplateColumns:'30rem 1fr 30rem'}}>

      <div >
      <h2>{`${participant.name.firstName} ${participant.name.lastName}`}</h2>
      <h3>Create new document</h3>
      <select value={currentSelectedType} onChange={(e) => setCurrentSelectedType(e.target.value)}>
        <option value=''>Select a document type</option>
        {templateTypes.map((template: any) => {
          return <option key={template.id} value={template.name}>{template.name}</option>
        })}
      </select>
      <button onClick={createNewDocument}>New</button>
      
        
      <h3 style={{marginTop:'3rem'}}>Documents</h3>
      
      {Object.keys(documentsByType).map ((item:any,)=>{
        const list = documentsByType[item]
        

        if (item == 'Imported Initial Assessment' || item == 'Imported Care Plan' || item == 'Imported Program Completion') {
          return (
            <div key={item}>
              <div style={{marginTop:'2rem', fontWeight: 'bold'}}>{item}</div>
              {list.map ((item:any, index:any)=>{              
                const style= (adminView) ? {display: 'grid', gridTemplateColumns:'2rem 16rem 1fr'} : {display: 'grid', gridTemplateColumns:'16rem 1fr'}

                return (
                  <div key={`${item}${index}`} >
                      {(item.type=='LACARE_Initial_Assessment') && 
                      <div style={style}>
                        {adminView && <div><input type='checkbox' onChange={(e)=>{if (e.target.checked) {setSelectDocuments([...selectDocuments, `i${item.id}`])} else {setSelectDocuments(selectDocuments.filter((i:any)=>i!=item.id))}}}/></div>}
                        <div>{d(item.date)}</div>
                        <div><button onClick={ ()=>{ setLegacyDocument ({id: item.id, type: 'inital'}) }}>View</button></div>
                      </div>}

                      {(item.type=='LACARE_Care_Plan') &&
                      <div style={style}>
                        {adminView && <div><input type='checkbox' onChange={(e)=>{if (e.target.checked) {setSelectDocuments([...selectDocuments, `c${item.id}`])} else {setSelectDocuments(selectDocuments.filter((i:any)=>i!=item.id))}}}/></div>}
                        <div>{d(item.date)} </div>
                        <div><button onClick={()=>{ setLegacyDocument ({id: item.id, type: 'careplan'})}}>View</button></div>
                      </div>}
                      {/*{(item.type=='LACAREProgram_Completion') &&<>{d(item.date)} <a href={`/CalAIM/forms/programcompletion/${item.id}`} style={{marginLeft:'1rem'}}><button>View</button></a></>}*/}
                  </div>
                )
              })}
            </div>
          )
        }

     
        
     

        return (
          <div key={item}>
            <div style={{marginTop:'2rem', fontWeight: 'bold'}}>{item}</div>
            {list.map ((item:any, index:any)=>{              
              const style = ((adminView) ? {display: 'grid', gridTemplateColumns:'2rem 15rem 1fr'} : {display: 'grid', gridTemplateColumns:'15rem 1fr'})
              return (
                <div key={`${item}${index}`} style={style}>
                      {adminView && <div><input type='checkbox' onChange={(e)=>{if (e.target.checked) {setSelectDocuments([...selectDocuments, item.id])} else {setSelectDocuments(selectDocuments.filter((i:any)=>i!=item.id))}}}/></div>}

                        
                      <div>{d(item.date)}</div> 
                      <div><button onClick={()=>{viewDocument(item)}} style={{marginLeft:'1rem'}}>View</button></div>
                </div>
              )
            })}
          </div>
        )
      })}
      
      </div>

      <div>
        { newDocumentModal && 
          <ReactModal isOpen={true} onRequestClose={()=>{setNewDocumentModal(false)}}>
            <div style={{display: 'grid', gridTemplateColumns:'95% 1fr'}}>
              <div><button onClick={()=>{setNewDocumentModal(false); handleCreateDocument()}}>Save</button></div>
              <div><button onClick={()=>{setNewDocumentModal(false)}}>Close</button></div>
            </div>
            {templatesByType[currentSelectedType].name == "Care Plan" && <CarePlanHeader/>}
            <FormInput formTemplate={JSON.parse( (isAltView) && templatesByType[currentSelectedType].altTemplate || templatesByType[currentSelectedType].body )} formState={newDocumentState} setFormState ={setNewDocumentState} onSubmit ={handleCreateDocument}  onClose={()=>{}}/>
            <button onClick={()=>{setNewDocumentModal(false); handleCreateDocument()}}>Save</button>
          </ReactModal>
        }

        { currentDocumentID && ! editMode &&   //Read Only View
          <ReactModal isOpen={true} onRequestClose={()=>{setCurrentDocument(null)}}>
            <div style={{display: 'grid', gridTemplateColumns:'95% 1fr'}}>
              <div>                
                <button style={{marginRight: '0.5rem'}}  onClick={()=>{setPrintView(true);}}>Print</button>
                <button onClick={()=>{setEditMode(true) }}>Edit</button>            
            </div>
            <div><button onClick={()=>{setCurrentDocumentID(null) ; setEditMode(false)}}>Close</button></div>
            </div>
            <FormView formTemplate={currentDocument.template} formState={newDocumentState} setFormState ={setNewDocumentState} onSubmit ={handleCreateDocument}  onClose={()=>{}}/>
          </ReactModal>
        }


      { currentDocumentID && editMode &&
          <ReactModal isOpen={true} onRequestClose={()=>{setCurrentDocument(null)}}>
            <div style={{display: 'grid', gridTemplateColumns:'93% 1fr'}}>
              <div>
                <button style={{marginRight: '0.5rem'}} onClick={handleEditDocument}>Save</button>
                
                <button onClick={()=>{setEditMode(false) }}>View</button>                
              </div>
              <div style={{display: 'grid', gridTemplateColumns:'1fr 1fr'}}>
                 <button style={{marginRight: '0.5rem', background:'#f44336', color: 'white'}} onClick={handleDeleteDocument}>Delete</button>
                 <button onClick={()=>{setCurrentDocumentID(null) ; setEditMode(false); setCurrentDocumentID(null)} }>Close</button>
                </div>
            </div>
            <FormInput formTemplate={currentDocument.template} formState={newDocumentState} setFormState ={setNewDocumentState} onSubmit ={handleEditDocument}  onClose={()=>{}}/>
          </ReactModal>
        }


      { legacyDocument.type!='' &&
          <ReactModal isOpen={true} onRequestClose={()=>{setCurrentDocument(null)}}>
            <div style={{display: 'grid', gridTemplateColumns:'95% 1fr'}}>
              <div>
                <button style={{marginRight: '0.5rem'}}  onClick={()=>{setPrintView(true);}}>Print</button>                
            </div>
            <div><button onClick={()=>{setLegacyDocument({type:'', id:null}) ; setEditMode(false)}}>Close</button></div>            
            {legacyDocument.type=='inital' && <FormInitialAssessment id={legacyDocument.id} />}
            {legacyDocument.type=='careplan' && <FormCarePlan id={legacyDocument.id} />}            
            </div>
          </ReactModal>
        }

      { transferDocumentModal &&
           <ReactModal isOpen={true} onRequestClose={()=>{setTransferDocumentModal(false)}}>
           <div style={{display: 'grid', gridTemplateColumns:'95% 1fr'}}>
              <div></div>
           <div><button onClick={()=>{setTransferDocumentModal(false)}}>Close</button></div>            
           <SearchParticipants onSelect={startTransfer}/>
           </div>
         </ReactModal>
        }

      


        
      </div>
      <div>
        {(user.username == 'BENLOE') && <Link to={`/admin/forms`}><button>Edit Form Templates</button></Link>}
        {(user.username == 'BENLOE' || user.username == 'E680442') && <button onClick={ ()=>{setAdminView (!adminView)}}>Admin</button>}
        <h3>Participant Information</h3>
        <div>ODR Number: {odrparticipant}</div>
        <div>First Name: {participant.name.firstName}</div>
        <div>Last Name: {participant.name.lastName}</div>
        <div></div>
        {(user.username == 'BENLOE' || user.username == 'E680442') && adminView && <div> <button onClick={()=>{setTransferDocumentModal(true)}}>Transfer</button></div>}
        </div>
    </div>
  );


};
