import Modal from 'react-modal';
//import { useManualRefreshJWT } from '../../store/userstore/useJWTStore';
import React, { useState, useEffect, useRef } from 'react';
import { TIMEOUT_PERIODS } from '../../../settings';
import { useAuthenticationState } from '../../../store/useAuthenticationState';
import { gql, useMutation } from '@apollo/client';


const formatTime = (seconds: number) => {
    if (seconds > 60) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes} min ${remainingSeconds} sec`;
    } 
    return `${seconds} sec`;    
};

export const WarningControl = ({onReset, lock}:any) => {
    const [redirectTimer, setRedirectTimer] = useState(TIMEOUT_PERIODS.MODALTIMEOUT);
    
    

    useEffect(() => {   
        const interval = setInterval(() => {
            setRedirectTimer((prev) => {
                if (prev <= 1) {
                    clearInterval(interval);                    
                    lock();
                    return 0;
                }
                return prev - 1;
            });
        }, 1000); // Update countdown every second

        return () => {
            clearInterval(interval);
        };
    }, [lock]);


    return (
        <div>        
            
            <img src={`${process.env.PUBLIC_URL}/logoODR.png`} alt="logo" style={{width: '200px', margin: '0 auto'}}/>
            <div style={{textAlign: 'center'}}>            
            <h2>Session Locking</h2>
            <p>Your screen will be locked in {formatTime(redirectTimer)}</p>
            
            <button onClick={onReset}>Unlock</button>           
            </div>
        
        </div>
    );
};





