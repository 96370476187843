import React, { useState, useCallback } from "react";
import ReactModal from "react-modal";
import { useMutation, gql } from "@apollo/client";
import { useDropzone } from "react-dropzone";
import { ScanPreview } from "./PreviewScan";

const UPDATE_PARTICIPANT = gql`
  mutation UpdateParticipant(
    $programNumber: Integer!
    $input: participantProgramDataInput!
  ) {
    updateParticipantProgramDataByProgramNumber(
      programNumber: $programNumber
      input: $input
    ) {
      responseCode
      message
    }
  }
`;

export const EditScans = ({
  isOpen,
  onClose,
  programNumber,
  odrNumber,
  scans,
}: {
  isOpen: boolean;
  onClose: () => void;
  programNumber: number;
  odrNumber: number;
  scans: any[];
}) => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [uploadType, setUploadType] = useState("");
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  const [uploadLabel, setUploadLabel] = useState("");
  const [selectedScan, setSelectedScan] = useState<any>(null);

  const [updateParticipant] = useMutation(UPDATE_PARTICIPANT);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setUploadFile(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleUpload = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!uploadFile) return;

    try {
      const result = await updateParticipant({
        variables: {
          programNumber,
          input: {
            scans: [
              {
                filetype: uploadType,
                filelabel: uploadLabel,
                filedata: await fileToBase64(uploadFile),
                filename: uploadFile.name,
              },
            ],
          },
        },
      });

      if (
        result.data.updateParticipantProgramDataByProgramNumber.responseCode ===
        "SUCCESS"
      ) {
        console.log("File uploaded successfully");
        setIsUploadModalOpen(false);
      } else {
        console.error(
          "Upload failed:",
          result.data.updateParticipantProgramDataByProgramNumber.message
        );
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleDelete = async (id: string) => {
    if (window.confirm("Are you sure you want to delete this file?")) {
      try {
        const result = await updateParticipant({
          variables: {
            programNumber,
            input: {
              scans: [
                {
                  id,
                  deleted: true,
                },
              ],
            },
          },
        });

        if (
          result.data.updateParticipantProgramDataByProgramNumber
            .responseCode === "SUCCESS"
        ) {
          console.log("File deleted successfully");
        } else {
          console.error(
            "Deletion failed:",
            result.data.updateParticipantProgramDataByProgramNumber.message
          );
        }
      } catch (error) {
        console.error("Error deleting file:", error);
      }
    }
  };

  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const renderScanSection = (type: string, label: string) => (
    <div>
      <h3>{label}</h3>
      {scans
        .filter((scan) => scan.filetype === type)
        .map((scan) => (
          <div key={scan.id} style={{display: "grid", gridTemplateColumns: "12rem 20rem 12rem", gap: "1rem", marginBottom: "0.5rem" }}>
            <div              
              style={{cursor: "pointer", color: "blue",textDecoration: "underline"}}
              onClick={() => setSelectedScan(scan.id)}
            >
              {type === "otherFile" ? `Other file: ${scan.filelabel}` : label}
            </div>
            (Uploaded {new Date(scan.filedate).toLocaleString()})
            <div style={{display: "flex", justifyContent: "end" }}><button onClick={() => handleDelete(scan.id)}>Delete</button></div>
          </div>
        ))}
      <button
        onClick={() => {
          setUploadType(type);
          setIsUploadModalOpen(true);
        }}
      >
        Upload a new {label}
      </button>
    </div>
  );

  return (
    <ReactModal
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        content: {
          position: "relative",
          background: "white",
          padding: "20px",
          maxHeight: "80vh",
          overflowY: "scroll",
          width: "100%",
          maxWidth: "800px",
          inset: 0,
        },
      }}
      isOpen={isOpen}
      onRequestClose={onClose}
    >

        {selectedScan && (
          <ScanPreview
            odrnumber={odrNumber}
            scan={selectedScan}
            close={() => setSelectedScan(null)}
          />
        )}

      <div >
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", }}><h2>Upload or delete scans for this person</h2>
        <button onClick={onClose}>Close</button>
        </div>

        {renderScanSection("courtFile", "Court File")}
        {renderScanSection("rapSheet", "Rapsheet")}
        {renderScanSection("otherFile", "Other File")}

        <ReactModal
          isOpen={isUploadModalOpen}
          onRequestClose={() => setIsUploadModalOpen(false)}
          contentLabel="Upload File Modal"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.75)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              maxWidth: "400px",
            },
          }}
        >
          <h2>Upload File</h2>
          <form onSubmit={handleUpload}>
            <div>
              <label htmlFor="fileType">Type of file:</label>
              <select
                id="fileType"
                value={uploadType}
                onChange={(e) => setUploadType(e.target.value)}
              >
                <option value="courtFile">Court file for case</option>
                <option value="rapSheet">Rapsheet</option>
                <option value="otherFile">Other File</option>
              </select>
            </div>
            {uploadType === "courtFile" && (
              <div>
                <label htmlFor="insertCode">Insert code:</label>
                <input
                  type="text"
                  id="insertCode"
                  value={uploadLabel}
                  onChange={(e) => setUploadLabel(e.target.value)}
                />
              </div>
            )}
            {uploadType === "otherFile" && (
              <div>
                <label htmlFor="insertLabel">Insert label:</label>
                <input
                  type="text"
                  id="insertLabel"
                  value={uploadLabel}
                  onChange={(e) => setUploadLabel(e.target.value)}
                />
              </div>
            )}
            <div {...getRootProps()} style={dropzoneStyle}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the file here ...</p>
              ) : (
                <p>Drag 'n' drop a file here, or click to select a file</p>
              )}
            </div>
            {uploadFile && <p>Selected file: {uploadFile.name}</p>}
            <button type="submit">Upload</button>
            <button type="button" onClick={() => setIsUploadModalOpen(false)}>
              Cancel
            </button>
          </form>
        </ReactModal>

        
      </div>
    </ReactModal>
  );
};

const dropzoneStyle = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center" as const,
  cursor: "pointer",
  marginBottom: "10px",
};
