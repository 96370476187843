import { useState, useEffect } from 'react';
import { useParams, useNavigate  } from 'react-router-dom';
import { useQuery, gql, useMutation } from '@apollo/client';

import { toast } from 'react-toastify';



const GET_PARTICIPANT_BY_ODR_NUMBER = gql`
  query GetParticipantByODRNumber($odrnumber: Integer!) {
    getParticipantByODRNumber(odrnumber: $odrnumber) {
      id
     notes {
        participant
        note
        date
        author
      }
     }
  }
`;

const UPDATE_PARTICIPANT_BY_ODR_NUMBER = gql`
  mutation UpdateParticipantByODRNumber($ODRNumber: Integer!, $updates: updateParticipantInput!) {
    updateParticipantByODRNumber(ODRNumber: $ODRNumber, updates: $updates) {     
      message     
  }}
`;

//create a control that recives a date 2022-01-24T08:00:00.000Z as a string and formats it to MM/DD/YYYY
const DateControl = (params: any)=>{
    const { children } = params;
    const date = new Date(children);
    const month = date.getMonth()+1;
    const day = date.getDate();
    const year = date.getFullYear();
    return <div>{month}/{day}/{year}</div>
}


const programFromID = (idstring: any) =>{
  const id=parseInt(idstring )|| idstring
  if (id < 20000000) //19999999 and below
      return {text : 'MIST', css: 'mist'}
  if (id < 30000000) //29999999 and below
    return {text : 'ODR', css: 'zodr'}
  if (id < 40000000) //39999999 and below
    return {text : 'PRGWMN', css: 'prgwmn'}
  if (id < 60000000) //59999999 and below
    return {text : 'FIST', css: 'fist'}
  if (id < 70000000) //69999999 and below
    return {text : 'DSH', css: 'dsh'}
  if (id < 80000000) //79999999 and below
    return {text : 'LEAD', css: 'lead'}
  
  return {text : 'ALL', css: 'G'}
  
  }

const ProgramSquare =(params:any)=>{
  const {idstring} = params
  
  const program = programFromID(idstring)  

  return (<div><div className={`programSquareContainer`}><div className={`programSquare ${program.css}`}>{program.text}</div></div></div>)
  
}


export const Notes = (params: any)=>{
    const { participant } = params;
    const [note, setNote] = useState('');
    
    
    const { loading, error, data ,refetch } = useQuery(GET_PARTICIPANT_BY_ODR_NUMBER, {
        variables: { odrnumber: participant },
    });
    
    const [updateParticipant] = useMutation(UPDATE_PARTICIPANT_BY_ODR_NUMBER);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    const participantData = data.getParticipantByODRNumber;

    const onSubmit = async (e: any) => {      
      
      const { data: updateData } = await updateParticipant({
          variables: {
            ODRNumber: participant,
            updates: {notes : { note : note} },
          },
        });

        if (updateData.updateParticipantByODRNumber.message == 'success') {
          setNote('');
          toast.success('Note Saved');
          refetch();
        }
      
  }

    //map over names and programs
    const notes = participantData.notes || [];

    return (
        <div>
            
            <h3>Notes</h3>

            {/*<input type="text" name="note" value={note} onChange={(e)=>setNote(e.target.value)} />
            <button onClick={onSubmit}>Save</button>*/}
            <ul>
                {notes.map((note: any) => (
                    <div className='columns4lastLarge' key={note.date}>
                      <ProgramSquare idstring={note.participant} />
                        <DateControl>{note.date}</DateControl>
                        <div>{note.author}</div>
                        <div>{note.note}</div>
                    </div>
                ))}
            </ul>
        </div>
    )

} 