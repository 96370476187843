import React from "react";
import { Link } from "react-router-dom";
import { programNameFromID, isoDateToMDY } from "../../../utils/common";


export const CalAimForms = ({calaimData, odrNumber} : any) => {
    return (
        <div>
            <h4>ECM CalAIM Forms</h4>
            <div>
          <div style={{display: "grid", gridTemplateColumns: "10rem 1fr", marginBottom: "0.5rem" }}>
            <Link to={`/documents/${odrNumber}`}>Initial Assessment</Link>
            <span> {isoDateToMDY(calaimData.initialAssessmentDate) || "None"}</span>
          </div>
          <div style={{display: "grid", gridTemplateColumns: "10rem 1fr", marginBottom: "0.5rem" }}>
            <Link to={`/documents/${odrNumber}`}>Care Plan</Link>
            <span> {isoDateToMDY(calaimData.carePlanDate) || "None"}</span>
          </div>
          <div style={{display: "grid", gridTemplateColumns: "10rem 1fr", marginBottom: "0.5rem" }}>
            <Link to={`/documents/${odrNumber}`}>Program Completion</Link>
            <span> {isoDateToMDY(calaimData.programCompletionDate) || "None"}</span>
          </div>
          <div style={{marginBottom: "0.5rem" }}>
            <Link to={`/documents/${odrNumber}`}>LA Care Forms</Link>
          </div>
          <div style={{marginBottom: "0.5rem" }}>
            <Link to={`/CalAIM/outreach/${odrNumber}`}>Client Outreach</Link>
          </div>
        </div>
        </div>
    )
}

