import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { EditDisposition } from "./modals/EditDisposition";

const GET_DISPOSITION_DATA = gql`
  query GetPlanData($programNumber: Integer!) {
    getParticipantProgramDataByProgramNumber(programNumber: $programNumber) {
      locationAndPlan{
        comments
        contact
        custodyorcommunity
        dischargeplan
        high_priority_status
        ih_assignment_date
        ih_request_date
        ih_request_status
        location
        locationdetail
        notes
        pharmacynotes
        psh_funding_source
        psh_search_notes
        psh_search_status
        psh_search_status_date
        psh_type
        restrictions
}
    }
  }
`;
const style = {
  marginBottom: "1rem",
  border: "1px solid #ccc",
  borderRadius: "1px",
  padding: "0.5rem",
}


export const PlanAndLocation = ({
  programNumber,
}: {
  programNumber: number;
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const { loading, error, data } = useQuery(GET_DISPOSITION_DATA, {
    variables: { programNumber },
    skip: !programNumber,
  });

  if (loading) return <p>Loading plan data...</p>;
  if (error) return <p>Error loading plan data :(</p>;
  if (!data) return null;

  const planData =
    data.getParticipantProgramDataByProgramNumber.locationAndPlan;

  const handleEditClick = () => {
    setIsEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
  };

  if (!planData) return <p>No plan data found</p>;

  return (
    <div>
      <h3>
        Plan and Location{" "}
        <button onClick={handleEditClick}>
          <div style={{ fontSize: "smaller" }}>Edit</div>
        </button>
      </h3>
      <div style = {style}>
        <div>
          <div style={{ fontWeight: "bold", marginBottom: "1rem" }}>
            {planData.custodyorcommunity == 1 ? "In Custody" : "Community"}
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <b>Location: </b>
            {planData.location || "N/A"}
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <b>Location Detail: </b>
            {planData.locationdetail || "N/A"}
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <b>Contact: </b>
            {planData.contact || "N/A"}
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <b>Discharge Plan: </b>
            {planData.dischargeplan || "N/A"}
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <b>Comments: </b>
            {planData.comments || "N/A"}
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <b>High Priority Status: </b>
            {planData.high_priority_status || "N/A"}
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <b>IH Assignment Date: </b>
            {planData.ih_assignment_date
              ? new Date(planData.ih_assignment_date).toLocaleDateString()
              : "N/A"}
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <b>IH Request Date: </b>
            {planData.ih_request_date
              ? new Date(planData.ih_request_date).toLocaleDateString()
              : "N/A"}
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <b>IH Request Status: </b>
            {planData.ih_request_status || "N/A"}
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <b>Notes: </b>
            {planData.notes || "N/A"}
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <b>Pharmacy Notes: </b>
            {planData.pharmacynotes || "N/A"}
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <b>PSH Funding Source: </b>
            {planData.psh_funding_source || "N/A"}
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <b>PSH Search Notes: </b>
            {planData.psh_search_notes || "N/A"}
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <b>PSH Search Status: </b>
            {planData.psh_search_status || "N/A"}
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <b>PSH Search Status Date: </b>
            {planData.psh_search_status_date
              ? new Date(planData.psh_search_status_date).toLocaleDateString()
              : "N/A"}
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <b>PSH Type: </b>
            {planData.psh_type || "N/A"}
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <b>Restrictions: </b>
            {planData.restrictions || "N/A"}
          </div>
        </div>
      </div>
      <EditDisposition
        isOpen={isEditModalOpen}
        onClose={handleCloseModal}
        programNumber={programNumber}
        planData={planData}
      />
    </div>
  );
};
