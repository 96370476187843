import {toast} from 'react-toastify';

const handlerError = (error: any, key :string) => {
    console.log(error);
    const element = document.getElementById(`label${key}`) as any
    if (element) {
      element.style.color = 'red';
      element.scrollIntoView({ behavior: 'smooth' });
    }
    else 
    toast.error(key);
    toast.error(error);
    return false;
  };
  



    export const FormValidator = (data: any, formState: any): boolean => {
        let isValid = true;
      
        const checkRequired = (item: any) => {
          if (item.required && formState[item.key] == null) {
            
            handlerError(`${item.label} is required`, item.key);            
            throw new Error(`${item.label} is required`);
          }
        };
      
        const traverseData = (data: any) => {
          data.forEach((item: any) => {
            if (item.type === 'section') {
              traverseData(item.children);
            } else if (item.type === 'textInput') {
              checkRequired(item);
            }
          });
        };
      
        traverseData(data);
      
        return isValid;
      };
      
  