


import { useState, useEffect } from 'react';
import { useParams, useNavigate  } from 'react-router-dom';
import { useQuery, gql, useMutation } from '@apollo/client';


import {toast} from 'react-toastify';

const CustomDate = (props: any) => {
  const { value } = props;
  if (!value)
  return <div></div>;
  const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.\d{3}Z$/;
  const [, year, month, day, hour, minute, second] = value.match(regex) || [];


  if (!year) {
    // Handle invalid date format
    return <div>Invalid date format</div>;
  }

  const usaDateFormat = `${month}/${day}/${year}`;

  return <div>{usaDateFormat}</div>;
};

const GET_PARTICIPANT_BY_ODR_NUMBER = gql`
  query GetParticipantByODRNumber($odrnumber: Integer!) {
    getParticipantByODRNumber(odrnumber: $odrnumber) {
       icd10
       fin  
       medicalProblems
       psychotropicMeds
       otherMeds
       clinicalPresentation
       psychHistory
       substanceHistory
    }
  }
`;

const UPDATE_PARTICIPANT_BY_ODR_NUMBER = gql`
  mutation UpdateParticipantByODRNumber($ODRNumber: Integer!, $updates: updateParticipantInput!) {
    updateParticipantByODRNumber(ODRNumber: $ODRNumber, updates: $updates) {     
      message     
  }}
`;



export const Medical = (params: any)=>{
    const { participant: participantReactParams, submit } = params;
    
    const { participant: participantURLParams } :any = useParams();
    const participant = participantReactParams || participantURLParams;


    const [state, setState] = useState<any>({ nameHistory: []});
    const [ecmState, setEcmState] = useState<any>({});
    const [healthPlanState, setHealthPlanState] = useState<any>({});
    

    const { loading, error, data, refetch } = useQuery(GET_PARTICIPANT_BY_ODR_NUMBER, {
        variables: { odrnumber: participant },
        onCompleted: (result) => {        
            const { ecm, healthplan, ...p } = result.getParticipantByODRNumber;
                        
            setState( {...p, nameHistory : p.nameHistory ||[], dobHistory: p.dobHistory||[] } )            
        },
    });

    

    const [updateParticipant] = useMutation(UPDATE_PARTICIPANT_BY_ODR_NUMBER);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    const participantData = data.getParticipantByODRNumber;
    


    return (
        <div>
            <h3>Medical</h3>

            <div >
              <h4>ICD10</h4>
             {participantData.icd10 && participantData.icd10.map((icd: any, index: number) => {
                return (
                    <div key={index}>
                        <div>{icd}</div>
                    </div>
                );
             }   
            )}                   
            </div>
            <div >
              <h4>FIN</h4>
             {participantData.fin}
             </div>             
            
            <div >
              <h4>Medical Problems</h4>
              {participantData.medicalProblems && participantData.medicalProblems.map ((problem: any, index: number) => {
                return (
                    <div key={index}>
                        <div>{problem}</div>
                    </div>
                );
              })}

            </div>
            <div >
              <h4>Psychotropic Meds</h4>
              {participantData.psychotropicMeds}
              </div>



        </div>
            
    )
} 