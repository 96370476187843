import React from 'react';

import { useAuthenticationState } from '../../store/useAuthenticationState';
import { Login } from '../auth/Login';
import { AutoLogout } from './AutoLogout';
import { gql, useQuery } from '@apollo/client';
import { useUser } from '../../store/useUser';

const GET_CURRENT_USER_DATA = gql`
  query GetCurrentUserData {
    getCurrentUserData {
      username
      realname
      groups
      access
    }
  }
`;


export const ProtectedRoute = (params:any ) => {
    const { authenticationState } = useAuthenticationState();
    const { element, settings, children } = params;

    const { setUser, isUserLoading } = useUser();

    useQuery(GET_CURRENT_USER_DATA, {
        skip: !authenticationState,
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
          if (data?.getCurrentUserData) {            
            setUser(data.getCurrentUserData);            
          }
        },
        onError: (error) => {
          console.error('Error fetching user data:', error);
          // Handle error, possibly by redirecting to login or showing an error message
        }
      });

    
  
    if (!authenticationState)      
      return <Login/>

    if (isUserLoading)
      return <div>Loading...</div>;
    
    return (
      <React.Fragment>
        <AutoLogout/>        
        {children}        
        
      </React.Fragment>
    )     
  }
 