import React from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import { isoDateToMDY } from "../../utils/common";
import Modal from "react-modal";
import {toast} from "react-toastify"

import {CreateNote} from "./modals/CreateNote"

const GET_NOTES_DATA = gql`
  query GetNotesData($programNumber: Integer!) {
    getParticipantProgramDataByProgramNumber(programNumber: $programNumber) {
      notes {
        notes
        notesDate
        notesBy
      }
    }
  }
`;




export const Notes = ({ programNumber }: { programNumber: number }) => {
  const { loading, error, data, refetch } = useQuery(GET_NOTES_DATA, {
    variables: { programNumber },
    skip: !programNumber,
  });
  
  
  const [showAddNote, setShowAddNote] = React.useState(false);


  if (loading) return <p>Loading notes data...</p>;
  if (error) return <p>Error loading notes data :(</p>;
  if (!data) return null;

  const notesData = data.getParticipantProgramDataByProgramNumber.notes;

  const style = {
    marginBottom: "1rem",
    border: "1px solid #ccc",
    borderRadius: "1px",
    padding: "0.5rem",
  }
  
  



  return (
    <div>
      <div style={{display: "grid", gridTemplateColumns: "90% 1fr"}}>
      <h3>Notes</h3>
      <div>
        <button onClick={() => setShowAddNote(true)}>Add Note</button>
      </div>
      </div>
      <div
      
      >
        
        {notesData.map((note: any) => (
          <div key={note.notesDate}   style={{
            display: "grid",
            gridTemplateColumns: "10rem 1fr 15rem",
            gap: "10px",
            borderBottom: "1px solid #ccc",                        
            gridGap: "5rem"
          }}>
            <p>{isoDateToMDY(note.notesDate)}</p>
            <p>{note.notes}</p>
            
            <p>By: {note.notesBy}</p>
          </div>
        ))}
      </div>
      {showAddNote && <CreateNote onClose={() => setShowAddNote(false)} programNumber={programNumber} refetch={refetch} />}
    </div>
  );
};