import React from "react";

import { createBrowserRouter, RouterProvider } from "react-router-dom";


import { Layout } from "./layout/layout";
import { Header } from "./layout/header";
import { Footer } from "./layout/footer";
import { Sidebar } from "./layout/sidebar";

import { About } from "./pages/about";
import { FileNotFound404 } from "./pages/filenotfound404";

import {
  FormInitialAssessment,
  settingsInitialAssessment,
} from "./pages/ParticipantDocuments/initialassessment";
import {
  FormCarePlan,
  settingsCarePlan,
} from "./pages/ParticipantDocuments/careplan";
//import {FormEcmForm} from './pages/CalAIM/forms/ecmForm'
//import {FormEcmFormList} from './pages/CalAIM/forms/ecmFormList'

//import {FormScreeningChecklist, FormScreeningChecklistCreate} from './pages/CalAIM/forms/screeningChecklist'
import { DatabaseHealthReportPage } from "./pages/reports/databaseHealth/databaseHealth";

import { ParticipantDocuments } from "./pages/ParticipantDocuments/participantDocuments";
import { ParticipantDocument } from "./pages/ParticipantDocuments/participantDocument";
import { CSVImport } from "./pages/dev/csvImportTool";
import { CSVImportPCode } from "./pages/admin/pLevelReport/pcodeImport";

import { StatusPage } from "./pages/admin/status";

import { HealthPlanCommunicationManager } from "./pages/CalAIM/healthCareCommunications/healthCareCommunicationManager";
import { BillingManager } from "./pages/CalAIM/healthCareBilling/billingManager";
import { HealthPlanManager } from "./pages/CalAIM/healthPlanManager/healthPlanManager";
import {
  SearchParticipants,
  settingSearch,
} from "./pages/CalAIM/searchParticipants/searchParticipants";
import { ParticipantsManager } from "./pages/participant/ParticipantsManager";
import { LacareDashboard } from "./pages/CalAIM/lacareDashboard/lacare";
import { UpdateDatabasePage } from "./pages/admin/secureDevDB";
import { ExportAllClientsReport } from "./pages/reports/exportAllClientsReport";

import { FormTemplates } from "./pages/ParticipantDocuments/admin/DocumentTemplates";

import { DuplicateClientsReport } from "./pages/reports/duplicateClientsReport";
import { ActiveUserReport } from "./pages/reports/activeUserReport";
import { MassDeactivate } from "./pages/admin/massDeactivate";
import { ServerConfig } from "./pages/admin/serverConfig";

import { Outreach } from "./pages/CalAIM/outreach/outreach";
import { Help as HelpAdmin } from "./pages/admin/help/help";

import { LEAD_participants_enrollment } from "./pages/dev/dataTools/lead_participant_enrollment";

import { Login } from "./pages/auth/Login";
import { Help } from "./pages/help/help";

import { ProtectedRoute as ProtectedRouteWrapper } from "./pages/auth/ProtectedRoute";
import { PublicRoute as PublicRouteWrapper } from "./pages/auth/PublicRoute";

import { ParticipantsManager as ParticipantsManagerX } from "./pages/CalAIM/participantsManager/participantManager";

const ProtectedRoute = (params: any) => {
  const { element, settings } = params;
  return (
    <ProtectedRouteWrapper {...params}>
      <Layout
        Header={Header}
        Body={element}
        Footer={Footer}
        Sidebar={Sidebar}
        Help={Help}
        settings={settings}
      />
    </ProtectedRouteWrapper>
  );
};

const PublicRoute = (params: any) => {
  const { element, settings } = params;
  return (
    <PublicRouteWrapper {...params}>
      <Layout
        Header={Header}
        Body={element}
        Footer={Footer}
        Sidebar={Sidebar}
        settings={settings}
      />
    </PublicRouteWrapper>
  );
};

const routes = createBrowserRouter([
  {
    children: [
      { path: "/login", element: <PublicRoute element={Login} /> },
      {
        path: "/admin/dev/securedevdb",
        element: <ProtectedRoute element={UpdateDatabasePage} />,
      },
      {
        path: "/CalAIM/lacaredashboard/:odrparticipant",
        element: <ProtectedRoute element={LacareDashboard} />,
      },
      {
        path: "/participants/:participant",
        element: <ProtectedRoute element={ParticipantsManager} />,
      },
      {
        path: "/participants/:participant",
        element: <ProtectedRoute element={ParticipantsManager} />,
      },

      {
        path: "/x/:participant",
        element: <ProtectedRoute element={ParticipantsManagerX} />,
      },
      {
        path: "/search",
        element: (
          <ProtectedRoute
            element={SearchParticipants}
            settings={settingSearch}
          />
        ),
      },
      {
        path: "/CalAIM/outreach/:odrnumber/:participant",
        element: <ProtectedRoute element={Outreach} />,
      },
      {
        path: "/CalAIM/billing/healthplans",
        element: <ProtectedRoute element={HealthPlanManager} />,
      },
      {
        path: "/CalAIM/billing",
        element: <ProtectedRoute element={BillingManager} />,
      },
      {
        path: "/CalAIM/billing/healthplans/communications",
        element: <ProtectedRoute element={HealthPlanCommunicationManager} />,
      },
      {
        path: "/CalAIM/reports/exportallclients",
        element: <ProtectedRoute element={ExportAllClientsReport} />,
      },
      {
        path: "/reports/duplicateclients",
        element: <ProtectedRoute element={DuplicateClientsReport} />,
      },
      {
        path: "/CalAIM/forms/initalassessement/:id",
        element: (
          <ProtectedRoute
            element={FormInitialAssessment}
            settings={settingsInitialAssessment}
          />
        ),
      },
      {
        path: "/CalAIM/forms/careplan/:id",
        element: (
          <ProtectedRoute element={FormCarePlan} settings={settingsCarePlan} />
        ),
      },
      {
        path: "/documents/:odrparticipant",
        element: <ProtectedRoute element={ParticipantDocuments} />,
      },
      {
        path: "/document/:documentID",
        element: <ProtectedRoute element={ParticipantDocument} />,
      },
      {
        path: "/admin/reports/activeuserreport",
        element: <ProtectedRoute element={ActiveUserReport} />,
      },
      {
        path: "/admin/reports/databasehealth",
        element: <ProtectedRoute element={DatabaseHealthReportPage} />,
      },
      {
        path: "/admin/reports/userdetailsreport",
        element: <ProtectedRoute element={MassDeactivate} />,
      },
      {
        path: "/admin/serverconfig",
        element: <ProtectedRoute element={ServerConfig} />,
      },
      {
        path: "/admin/forms",
        element: <ProtectedRoute element={FormTemplates} />,
      },
      {
        path: "/admin/dev/importCSV",
        element: <ProtectedRoute element={CSVImport} />,
      },
      {
        path: "/admin/importPCode",
        element: <ProtectedRoute element={CSVImportPCode} />,
      },
      //{ path: "/admin/generatePLevelReport", element: <ProtectedRoute element={ GeneratePLevelReport } />},
      { path: "/status", element: <ProtectedRoute element={StatusPage} /> },
      {
        path: "/admin/dev/dbcheck/lead_enrollments",
        element: <ProtectedRoute element={LEAD_participants_enrollment} />,
      },
      { path: "/admin/help", element: <ProtectedRoute element={HelpAdmin} /> },
      //{ path: "/CalAIM/forms/ecmform",  element: <FormEcmForm /> },
      // { path: "/CalAIM/forms/ecmform/:id",  element: <FormEcmForm /> },
      //{ path: "/CalAIM/forms/ecmform/create/:odrparticipant",  element: <FormEcmForm /> },
      //{ path: "/CalAIM/forms/ecmform/list",  element: <FormEcmFormList /> },
      //{ path: "/CalAIM/forms/ecmform/list/:odrparticipant",  element: <FormEcmFormList /> },
      //{ path: "/CalAIM/forms/initialassessment",  element: <FormInitialAssessment /> },
      //{ path: "/CalAIM/forms/initialassessment/:id", element: <FormInitialAssessment />  },
      //{ path: "/CalAIM/forms/screeningchecklist/:id", element: <FormScreeningChecklist />  },
      //{ path: "/CalAIM/forms/screeningchecklist/create/:odrparticipant", element: <FormScreeningChecklistCreate />  },
      { path: "/", element: <React.Fragment /> },
      { path: "/about", element: <About /> },
      { path: "*", element: <FileNotFound404 /> },
    ],
  },
]);

export const Router = () => {
  return <RouterProvider router={routes} />;
};
