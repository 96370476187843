// Add the following imports at the top of your file
import React, { useState, useEffect } from 'react';
import { useQuery, useLazyQuery, gql } from '@apollo/client';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import formTemplate from '../forms/ecmForm.json';
import { FormRenderer, initForm } from '../forms/lib/formRender';
import Modal from 'react-modal';

const LIST_HEALTH_PLAN_COMMUNICATIONS = gql`
  query ListHealthPlanCommunications {
    listHealthPlanCommunications {
      id
      name
      type
      metadata
      readyfortransmission
      transmitted
      tags
    }
  }
`;

const GET_HEALTH_PLAN_COMMUNICATION = gql`
  query GetHealthPlanCommunication($id: Int) {
    getHealthPlanCommunication(id: $id) {
      name
      type
      metadata
      contents
    }
  }
`;

const folderName = {
  StaffCapacityReports: 'ecm/lacare/outbox/StaffCapacityReports/',
  Referrals: 'ecm/lacare/outbox/ODR_Referrals/',
  ROI: 'ecm/lacare/outbox/ODR_ROI/',
  ProgramCompletion: 'ecm/lacare/outbox/ProgramCompletion/',
  Oversight: 'ecm/lacare/outbox/Oversight/',
  OptInForms: 'ecm/lacare/outbox/OptInForms/',
  CarePlans: 'ecm/lacare/outbox/CarePlans/',
  Assessments: 'ecm/lacare/outbox/Assessments/',
  ADT: 'ecm/lacare/outbox/ADT/',
  MIF: 'ecm/lacare/outbox/MIF/',
};

const categories = [
  'ADT',
  'Assessments',
  'CarePlans',
  'MIF',
  'Oversight',
  'OptInForms',
  'ProgramCompletion',
  'Referrals',
  'ROI',
  'StaffCapacityReports',
];

export const ViewHealthPlanCommunication = (params: any) => {
  const { id } = params;
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedFile, setSelectedFile] = useState(null) as any;

  const { loading, error, data, refetch } = useQuery(LIST_HEALTH_PLAN_COMMUNICATIONS);

  const [fetchHealthPlanCommunication, { data: fileData, loading: fileLoading }] =
    useLazyQuery(GET_HEALTH_PLAN_COMMUNICATION);
  const [fileSelected, setFileSelected] = useState(false);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('submited'); // Default filter

  const handleFilterChange = (filter: string) => {
    setSelectedFilter(filter);
  };

  useEffect(() => {
    refetch();
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const { listHealthPlanCommunications }: any = data;

  const handleCategoryClick = (category: string) => {
    setFileSelected(false);
    setSelectedCategory(category);
  };

  const handleFileClick = (id: string) => {
    setSelectedFile(id);
    setFileSelected(true);
    setIsOpen(true);
    fetchHealthPlanCommunication({ variables: { id } });
  };

  const handleDownloadClick = () => {
    // ... (your existing code for downloading)
  };

  const filemetadata = fileData ? JSON.parse(fileData.getHealthPlanCommunication.metadata) : {};

  return (
    <div>
      <div className="withsidebar">
        <div className="sidebar">
          <h3>Categories</h3>
          <ul>
            {categories.map((category: any) => (
              <li key={category} onClick={() => handleCategoryClick(category)}>
                {category}
              </li>
            ))}
          </ul>
        </div>
        <div className="file-list">
          <h2>LA Care SFTP Manager</h2>
          <Tabs>
            <TabList>              
              <Tab onClick={() => handleFilterChange('submited')}>Submitted</Tab>
              <Tab onClick={() => handleFilterChange('transmitted')}>Processed</Tab>
              <Tab onClick={() => handleFilterChange('published')}>Errors</Tab>              
              {/* Add more tabs for other filters as needed */}
            </TabList>

            {/* Published Tab */}
            <TabPanel>
              {listHealthPlanCommunications
                .filter((file: any) => file.type === selectedCategory && file.readyfortransmission)
                .map((file: any) => (
                  <div className="columns4 padding1rem" key={file.name}>
                    <div>{file.readyfortransmission ? 'Ready' : 'Submitted'}</div>
                    <div onClick={() => handleFileClick(file.id)}>
                      <div>{file.name}</div>
                    </div>
                    <div>{file.tags}</div>
                    <div>{file.transmitted}</div>
                  </div>
                ))}
            </TabPanel>

            {/* Tags Missing-CIN Tab */}
            <TabPanel>
              {listHealthPlanCommunications.map((file: any) => (
                  <div className="columns4 padding1rem" key={file.name}>
                    <div>{file.readyfortransmission ? 'Ready' : 'Submitted'}</div>
                    <div onClick={() => handleFileClick(file.id)}>
                      <div>{file.name}</div>
                    </div>
                    <div>{file.tags}</div>
                    <div>{file.transmitted}</div>
                  </div>
                ))}
            </TabPanel>

            {/* Add more TabPanels for other filters as needed */}
          </Tabs>
        </div>
      </div>


      <Modal isOpen={modalIsOpen} onRequestClose={() => setIsOpen(false)} contentLabel="LA Care SFTP Manager" >
        <button onClick={() => setIsOpen(false)}>Close</button>
        <div className="file-details">
          {fileSelected && selectedFile && (
            <>
              <h3>File Details</h3>
              {fileLoading && <p>Loading...</p>}
              {fileData && fileData.getHealthPlanCommunication && (
                <>
                  {filemetadata.type === 'doc' || filemetadata.type === 'docx' ? (
                    <div>
                      <iframe
                        src={`data:application/doc;base64,${fileData.getHealthPlanCommunication.contents}`}
                        width="100%"
                        height="900px"
                      />
                      <a className="button-link" onClick={handleDownloadClick} style={{ cursor: 'pointer' }}>
                        Download
                      </a>
                    </div>
                  ) : null}


                  {filemetadata.type === 'pdf' ? (
                    <div>
                      <iframe
                        src={`data:application/pdf;base64,${fileData.getHealthPlanCommunication.contents}`}
                        width="100%"
                        height="900px"
                      />
                      <a className="button-link" onClick={handleDownloadClick} style={{ cursor: 'pointer' }}>
                        Download
                      </a>
                    </div>
                  ) : null}

                  {filemetadata.type === 'json' ? (
                    <div>
                      <FormRenderer
                        formTemplate={formTemplate}
                        formState={JSON.parse(fileData.getHealthPlanCommunication.contents)}
                        setFormState={() => {}}
                        onSubmit={() => {}}
                        readOnly={true}
                      />
                    </div>
                  ) : null}
                </>
              )}
            </>
          )}
        </div>
      </Modal>
    </div>
  );
                  }
