import React, { useState, useEffect } from 'react';

import "./formRender.css"

import { FormValidator } from './validate';

const columnsCSS = {
  "2" : 'columns2',
  "3" : 'columns3',
} as any


const renderChildrenReadOnly = (formTemplate: any, formState: any, setFormState: any, onSubmitForm:any, depth = 0 ) => {
  
  
  return formTemplate.map((item: any, index: number) => {
    
    if (item.type === 'section'){
      const parent= (depth) ? '' : 'parent'
      const css= (columnsCSS[item.columns]) || ''

      return (
        <div key={index} className={`formBlock ${parent}`}>          
          <h2>{item.text||item.label}</h2>
          <div className={css}>
          {renderChildrenReadOnly(item.children, formState, setFormState, 1)}
          </div>
        </div>
      )
    }
    
          
    if (item.type === 'text') 
      return <div key={index}>{item.text}</div>

      if (item.type === 'bullet') 
      return <li key={index}>{item.text}</li>

      if (item.type === 'bulletList') {
        return (
          <ul>
            {item.items.map((item:any, index:any)=>{
              return <li key={index}>{item}</li>      
            })}
          </ul>
        )            
      }

    if (item.type === 'paragraph') {
      return <p key={index}>{item.text}</p>
    }

    if (item.type === 'heading') {
        return <h1 key={index}>{item.text}</h1>
    }


      if (item.type === 'textInput') {
            return (
              <div key={index} className='inputBlock' >
                <label id={`label${item.key}`} className='label' htmlFor={item.key}>{item.label}</label>
                <div>
                  <span className='bold'>{formState[item.key]}</span>                  
                </div>
              </div>
            )
        }

        if (item.type === 'staticInput') {
          return (
            <div key={index} className='inputBlock' >
              <label className='label' htmlFor={item.key}>{item.label}</label>
              <div>
              <span>{formState[item.key]}</span>                  
              </div>
            </div>
          )
      }

        if (item.type === 'textAreaInput') {
          return (
            <div key={index} className='inputBlock'>
              <label className='label' htmlFor={item.key}>{item.label}</label>
              <div>
              <span className='bold' >{formState[item.key]}</span>                  
              </div>
            </div>
          )
      }

        if (item.type === 'yesnoInput') {
            return (
                <div key={index} className='inputBlock'>
                    
                    <span  className='bold' >{(formState[item.key])? "YES " :"NO "}</span>                  
                    <label  className='label' htmlFor={item.key}>{item.label}</label>
                </div>
            )
        }

        if (item.type === 'listInput') {
          return (
            <div key={index} className='inputBlock'>
              <label htmlFor={`label${item.key}`}>{item.label}</label>
           
           <select>
              {item.options.map((option: any, optionIndex: number) => {
                if (formState[option.key] == 0) return <React.Fragment key={optionIndex}/>;
                return (
                  <React.Fragment key={optionIndex}>                    
                    <div className='bold label' >{option.label}</div>
                  </React.Fragment>
                )
              })}
              </select>
            </div>
          )

          /*return (
            <div key={index} className='inputBlock'>
              <label htmlFor={`label${item.key}`}>{item.label}</label>
              <div>
              {item.options.map((option: any, optionIndex: number) => {
                return (
                  <React.Fragment key={optionIndex}>
                    <span>{formState[item.key]}</span>                  
                    <label className='label' htmlFor={option.id}>{option.label}</label>
                  </React.Fragment>
                )
              })}
            </div></div>
          )*/
        }
      /*  if (item.class === 'select') {
          return (
            <div key={index} className='inputBlock'>
              <label htmlFor={item.id}>{item.text}</label>
              <div>
                <select
                  id={item.id}
                  name={item.name}
                  value={formState[item.name]}
                  onChange={handleChange}
                >
                  {item.options.map((option: any, optionIndex: number) => (
                    <option key={optionIndex} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          );
        }*/


        if (item.type === 'dateInput') {
            return (
                <div key={index} className='inputBlock'>
                    <label htmlFor={item.key}>{item.label}</label>
                    <span className='bold' >{formState[item.key]}</span>                  
                </div>
            )
        }
    
    
    return <React.Fragment key={index}/>;
  })
}


const renderChildren = (formTemplate: any, formState: any, handleChange: any, onSubmitForm: any, depth = 0 ) => {
  
  return formTemplate.map((item: any, index: number) => {
    

    if (item.type === 'section'){

      return (
        <div key={index} className={item.outercss||''}>          
          <h2>{item.label}</h2>
          <div className={item.innercss||''}>
          {renderChildren(item.children, formState, handleChange, 1)}
          </div>
        </div>
      )
    }
    
          
    if (item.type === 'text') 
      return <div key={index}>{item.text}</div>

      if (item.type === 'bullet') 
      return <li key={index}>{item.text}</li>

      if (item.type === 'bulletList') {
        return (
          <ul>
            {item.items.map((item:any, index:any)=>{
              return <li key={index}>{item.text}</li>      
            })}
          </ul>
        )            
      }

    if (item.type === 'paragraph') {
      return <p key={index}>{item.text}</p>
    }

    if (item.type === 'heading') {
        return <h1 key={index}>{item.text}</h1>
    }


      if (item.type === 'textInput') {
            return (
              <div key={index} className='inputBlock' >
                <label id={`label${item.key}`} className='label' htmlFor={item.key}>{item.label}</label>
                
                  <input 
                    name={item.key}
                    type='text'
                    id={item.key} 
                    value={formState[item.key]} 
                    onChange={handleChange}
                  />
                
              </div>
            )
        }

        if (item.type === 'staticInput') {
          return (
            <div key={index} className='inputBlock' >
              <label className='label' htmlFor={item.key}>{item.label}</label>
              <div>
                <input 
                  name={item.key}
                  type='text'
                  id={item.key} 
                  value={formState[item.key]} 
                  readOnly= {true}
                  onChange={()=>{}}
                />
              </div>
            </div>
          )
      }

        if (item.type === 'textAreaInput') {
          return (
            <div key={index} className='inputBlock'>
              <label className='label' htmlFor={item.key}>{item.label}</label>
              <div>
              <textarea 
                id={item.key} 
                name={item.key}
                value={formState[item.key]} 
                onChange={handleChange}
              />
              </div>
            </div>
          )
      }

        if (item.type === 'yesnoInput') {
            return (
                <div key={index} style={{display: 'grid', gridTemplateColumns : '2rem 1fr'}}>
                
                    
                    <div><input 
                      type='checkbox' 
                      id={item.key} 
                      name={item.key}
                      checked={formState[item.key] == 1}
                      onChange={handleChange}
                    />
                    </div>
                    <label  className='label' htmlFor={item.key}>{item.label}</label>
                    
                </div>
            )
        }
        if (item.type === 'listInputDowndown') {
          return (
            <div key={index} className='inputBlock'>
              <label htmlFor={`label${item.key}`}>{item.label}</label>
              <div>
                <select
                  id={item.key}
                  name={item.key}
                  value={formState[item.key]}
                  onChange={handleChange}
                >
                  <option value={''}>
                      -- Select --
                    </option>
                  {item.options.map((option: any, optionIndex: number) => (
                    <option key={optionIndex} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          );
        }
        

        if (item.type === 'listInput') {
          return (
            <div key={index} className='inputBlock'>
              <label htmlFor={`label${item.key}`}>{item.label}</label>
              <div>
                {item.options.map((option: any, optionIndex: number) => (
                  <React.Fragment key={optionIndex}>
                    <input 
                      type='radio' 
                      id={option.id}  
                      name={item.key}
                      value={option.value}
                      checked={formState[item.key] === option.value} 
                      onChange={handleChange}
                    />
                    <label className='label' htmlFor={option.id}>{option.label}</label>
                  </React.Fragment>
                ))}
              </div>
            </div>
          );
        }
      /*  if (item.class === 'select') {
          return (
            <div key={index} className='inputBlock'>
              <label htmlFor={item.id}>{item.text}</label>
              <div>
                <select
                  id={item.id}
                  name={item.name}
                  value={formState[item.name]}
                  onChange={handleChange}
                >
                  {item.options.map((option: any, optionIndex: number) => (
                    <option key={optionIndex} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          );
        }*/


        if (item.type === 'dateInput') {
            const formatedDate = (formState[item.key]) ? new Date(formState[item.key]).toISOString().split('T')[0] : '';
            return (
                <div key={index} className='inputBlock'>
                    <label htmlFor={item.key}>{item.label}</label>
                    <input 
                      type='date' 
                      name={item.key}
                      id={item.key} 
                      value={formatedDate}
                      onChange={handleChange}
                    />
                </div>
            )
        }

        if (item.type === 'submit') {
          return (
            <div key={index} className='inputBlock'>
              <button type='submit' onClick={onSubmitForm}>{item.label}</button>
            </div>
          )
        }
    
    
    return <React.Fragment key={index}/>;
  })
}


export const FormRenderer = (params: any) => {
    const { formTemplate, formState, setFormState, onSubmit, readOnly  } = params;
    
    
    const onSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
          FormValidator(formTemplate, formState)
          onSubmit();
        } catch (error) {
          // Handle any errors that occurred during the mutation
          console.error(error);
        }
    }
  
    const handleChange = (event: any) => {
        const { id, value, type, checked } = event.target;
      
        if (type === 'checkbox') {
          setFormState({
            ...formState,
            [id]: checked ? 1 : 0,
          });
          return;
        }
      
        if (type === 'radio') {
          setFormState({
            ...formState,
            [event.target.name]: value,
          });
          return;
        }
      
        setFormState({
          ...formState,
          [id]: value,
        });
      };

    return (
      <form onSubmit={onSubmitForm}>

        <div className={ (readOnly==true) ? '':'boxed'}>
        {(readOnly==true) ? renderChildrenReadOnly(formTemplate, formState, handleChange, ()=>{} ):renderChildren(formTemplate, formState, handleChange, onSubmitForm)}
        </div>
      </form>

      
    );
  }
  

export const FormInput = FormRenderer;