export function getHelpContent(inputType: string) {
    switch(inputType) {
      case 'bulletList':
        return `
          <h3>Bullet List</h3>
          <p>Represents a list of bullet points.</p>
          <p><strong>Attributes:</strong></p>
          <ul>
            <li><strong>Items:</strong> An array of strings representing each bullet point item.</li>
          </ul>
        `;
        case 'bullet':
            return `
              <h3>Bullet</h3>
              <p>Represents a bullet point.</p>
              <p><strong>Attributes:</strong></p>
              <ul>
                <li><strong>Items:</strong> An array of strings representing each bullet point item.</li>
              </ul>
            `;
      case 'heading':
        return `
          <h3>Heading</h3>
          <p>Represents a heading text.</p>
          <p><strong>Attributes:</strong></p>
          <ul>
            <li><strong>Text:</strong> The text content of the heading.</li>
          </ul>
        `;
      case 'paragraph':
        return `
          <h3>Paragraph</h3>
          <p>Represents a paragraph of text.</p>
          <p><strong>Attributes:</strong></p>
          <ul>
            <li><strong>Text:</strong> The text content of the paragraph.</li>
          </ul>
        `;
      case 'section':
        return `
          <h3>Section</h3>
          <p>Represents a section in a form template.</p>
          <p><strong>Attributes:</strong></p>
          <ul>
            <li><strong>Label:</strong> The label for the section.</li>
            <li><strong>Children:</strong> An array of child elements within the section.</li>
          </ul>
        `;
      case 'dateInput':
        return `
          <h3>Date Input</h3>
          <p>Represents a date input field.</p>
          <p><strong>Attributes:</strong></p>
          <ul>
            <li><strong>Label:</strong> The label for the input field.</li>
            <li><strong>Key:</strong> The unique identifier for the input field.</li>
          </ul>
        `;
      case 'textInput':
        return `
          <h3>Text Input</h3>
          <p>Represents a text input field.</p>
          <p><strong>Attributes:</strong></p>
          <ul>
            <li><strong>Label:</strong> The label for the input field.</li>
            <li><strong>Key:</strong> The unique identifier for the input field.</li>
          </ul>
        `;
      case 'textAreaInput':
        return `
          <h3>Text Area Input</h3>
          <p>Represents a text area input field.</p>
          <p><strong>Attributes:</strong></p>
          <ul>
            <li><strong>Label:</strong> The label for the input field.</li>
            <li><strong>Key:</strong> The unique identifier for the input field.</li>
          </ul>
        `;
      case 'staticInput':
        return `
          <h3>Static Text Input</h3>
          <p>Represents a static text input field.</p>
          <p><strong>Attributes:</strong></p>
          <ul>
            <li><strong>Label:</strong> The label for the input field.</li>
            <li><strong>Key:</strong> The unique identifier for the input field.</li>
          </ul>
        `;
      case 'yesnoInput':
        return `
          <h3>Yes No Input</h3>
          <p>Represents a yes/no input field.</p>
          <p><strong>Attributes:</strong></p>
          <ul>
            <li><strong>Label:</strong> The label for the input field.</li>
            <li><strong>Key:</strong> The unique identifier for the input field.</li>
          </ul>
        `;
      case 'listInput':
        return `
          <h3>List Input</h3>
          <p>Represents a list input field.</p>
          <p><strong>Attributes:</strong></p>
          <ul>
            <li><strong>Label:</strong> The label for the input field.</li>
            <li><strong>Key:</strong> The unique identifier for the input field.</li>
            <li><strong>Options:</strong> An array of options for the list input.</li>
          </ul>
        `;
      default:
        return '';
    }
  }
  
  export const Help = (props: any) => {
    const { inputType } = props;
    const helpContent = getHelpContent(inputType);
  
    return (
      <div>
        <h2>Help</h2>
        <div dangerouslySetInnerHTML={{ __html: helpContent }} />
      </div>
    );
  }