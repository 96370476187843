import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
//import { PDFDocument, PDFName } from 'pdf-lib';

const PUT_HEALTH_PLAN_COMMUNICATION_FILE = gql`
  mutation ImportHealthPlanCommunicationFile($file: GenericFileInput) {
    importHealthPlanCommunicationFile(file: $file) {
      name
      type
      contents
    }
  }
`;

const customStyles = {
  content: {
    width: '75%',
    height: '75%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
};



const readFileAsDataURL = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event: any) => {
      resolve(event.target.result);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};


export const HealthPlanCommunicationUpload = (params: any) => {
  const { onUpload } = params;
  const [files, setFiles] = useState<any[]>([]);
  const [selectedType, setSelectedType] = useState('Assessments');
  const [statuses, setStatuses] = useState<string[]>([]);
  const [ids, setids] = useState([]) as any;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUploadEnabled, setUploadEnabled] = useState(true);

  const [putHealthPlanCommunicationFile] = useMutation(
    PUT_HEALTH_PLAN_COMMUNICATION_FILE
  );

  const handleFileInputChange = async (event: any) => {
    const newStatuses: string[] = [];
    const newFiles = [] as any[];

    const filePromises = Array.from(event.target.files).map(async (file: any) => {
      try {
        const base64DataUrl = await readFileAsDataURL(file);
        const base64Data = base64DataUrl.split(',')[1];             
        const data = {
          contents: base64Data,
          metadata: {
            type: file.name.split('.').pop(),            
          },
        };

        newStatuses.push( '');

        const newFile = {
          ...data,
          name: file.name,
          type: 'Referrals',          
        };  

        newFiles.push(newFile);
        
      } catch (error) {
        console.error(`Error processing file ${file.name}:`, error);
        toast.error(`Error processing file ${file.name}. Please try again.`);
      }
    });

    await Promise.all(filePromises);

    if (newFiles.length > 0) {
      setFiles(newFiles);     
      setIsModalOpen(true);
    }

    setStatuses(newStatuses);
  };



  const handlePopupOk = async () => {
    setUploadEnabled(false);
  
    const newStatuses: string[] = [];    
    for (const file of files) {    
  
      try {                         
        
        const result = await putHealthPlanCommunicationFile({
          variables: {
            file: {              
              type: file.type,
              contents: file.contents,
              metadata: JSON.stringify(file.metadata),
            },
          },
        });
  
        if (result.data) {
          onUpload();
          toast.success(`File ${file.name} uploaded successfully.`);
          newStatuses.push('Success');
        }
      } catch (error) {
        console.error(`Error processing file ${file.name}:`, error);
        toast.error(`Error processing file ${file.name}. Please try again.`);
        newStatuses.push('Error');
      }

      setStatuses(newStatuses);
    }

    
  };
  
  
  const handlePopupCancel = () => {
    setIsModalOpen(false);    
    setFiles([]);    
    setStatuses([]);
    setUploadEnabled(true);
  };



  const handleTypeChangeAll = (newType: string) => {
    setFiles((prevFiles) =>
      prevFiles.map((file) => ({
        ...file,
        type: newType,
      }))
    );
    setSelectedType(newType);
  };

  return (
    <div>
      <input
        type="file"
        accept=".pdf,.xls,.xlsx,.doc,.docx,.csv"
        multiple
        onChange={handleFileInputChange}
      />

      <Modal isOpen={isModalOpen} onRequestClose={handlePopupCancel} style={customStyles} >
        <div>
          <label>
            Select Type for All Files:
            <select
              onChange={(e) => handleTypeChangeAll(e.target.value)}
              value={selectedType}
            >
              {/*<option value="Assessments">Assessments</option>
              <option value="CarePlans">CarePlans</option>
              <option value="ProgramCompletion">ProgramCompletion</option>
              <option value="OptInForms">OptInForms</option>*/}
              <option value="Oversight">Oversight</option>              
              <option value="ROI">ROI</option>
              <option value="Referrals">Referrals</option>
              <option value="StaffCapacityReports">StaffCapacityReports</option>
            </select>
          </label>
        </div>
        <p>Selected Files:</p>
        <div className='columns5firstLarge'><div>Name</div><div>Type</div></div>
        <ul>
          {files.map((file, index) => (
            <div key={index} className='columns5firstLarge'>
              <div>{file.name}</div>
              <div>
              <select
                onChange={(e) => {
                  const newType = e.target.value;
                  setFiles((prevFiles) => prevFiles.map((f, i) => i === index ? { ...f, type: newType } : f ));
                }}
                value={file.type}
              >
                <option value="Assessments">Assessments</option>
                <option value="CarePlans">CarePlans</option>
                <option value="OptInForms">OptInForms</option>
                <option value="Oversight">Oversight</option>
                <option value="ProgramCompletion">ProgramCompletion</option>
                <option value="ROI">ROI</option>
                <option value="Referrals">Referrals</option>
                <option value="StaffCapacityReports">StaffCapacityReports</option>
              </select>
              </div>
              
              <div>{statuses[index]}</div>
            </div>
          ))}
        </ul>
        <button onClick={handlePopupOk} disabled={!isUploadEnabled}>OK</button>
        <button onClick={handlePopupCancel}>{isUploadEnabled ? 'Cancel' : 'Close'}</button>
      </Modal>
    </div>
  );
};
