import { useParams, useNavigate  } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { CalAIM } from './calAIM';
import { Legal } from './legal';


const GET_PARTICIPANT_BY_ODR_NUMBER = gql`
  query GetParticipantByODRNumber($odrnumber: Integer!) {
    getParticipantByODRNumber(odrnumber: $odrnumber) {
      id
      programs
      rso
      wpc
      fin
      mainNumber
      bookingNumber
      disposition
      ssn
      cin
      mrun
      gender
      dob
      documents {
        id
        published
        tags
        name
        metadata
        contents
      }
      name { 
        firstName
        lastName
      }
      nameHistory {
        firstName
        lastName
      }    
      
      
      location {
        location 
        custodyorcommunity
         locationdetail 
         contact
         dischargePlan
    }
    }
    
    
  }
`;

export const ParticipantsManager = (params: any)=>{
    const { participant: participantReactParams } = params;
    
    const { participant: participantURLParams } :any = useParams();
    const participant = participantReactParams || participantURLParams;

    const { loading, error, data } = useQuery(GET_PARTICIPANT_BY_ODR_NUMBER, {
        variables: { odrnumber: participant },
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    const participantData = data.getParticipantByODRNumber;

    //map over nameHistory and programs
    const names = participantData.nameHistory;
    const programs = participantData.programs;

    return (
        <div className='boxed'>
            
            <h3>Names</h3>
            <ul>
                {names.map((name: any) => (
                    <li key={name.firstName+name.lastName}>{name.firstName} {name.lastName}</li>
                ))}
            </ul>
            <h3>Programs</h3>
            <ul>
                {programs.map((program: any) => (
                    <li key={program}>{program}</li>
                ))}
            </ul>

            <div>
                <h3>Participant ID</h3>
                <p>{participantData.id}</p>
            </div>

            <div>
                mrun: {participantData.mrun}
                </div>

            <div>
                <CalAIM participant={participantData.id} />
                <Legal participant={participantData.id} />
                </div>
        </div>
    )
} 