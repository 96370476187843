import React from 'react';
import { StoreContext } from '../storeProvider';

export const usePrintView = () => {
	const {isPrintView, setPrintView} = React.useContext(StoreContext);

	const togglePrintView = () => {
		if (isPrintView) {
			setPrintView(false);
		} else {
			setPrintView(true);
		}
	}

	return {
		isPrintView,
		togglePrintView,
		setPrintView
	};
};
